import React, { Component } from 'react';
import { Document, Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import AuthService from "../../AuthService/AuthService.js";
import AppConfig from "../../AppConfig/AppConfig.js";
import gcLogo from "../../../assets/images/logos/glasschain-logo.png";
import moment from 'moment';
import { toCardId } from "../../../components/schemaCards/AttribCardUtils.js";


function getSchemaPdfType(schemaId) {
    var lcSchemaId = schemaId.toLowerCase();
    if (lcSchemaId.includes('truckinspection')) return "passfail";
    if (lcSchemaId.includes('storevisit')) return "passfail";
    if (lcSchemaId.includes('covid-wellness-check')) return "passfail";
    if (lcSchemaId.includes("-om")) return "usda";
    if (lcSchemaId.includes("-usda")) return "usda";
    if (lcSchemaId.includes("-ma")) return "ma";
    if (lcSchemaId.includes("testplot")) return "passfail";
    return "default";
}

function toBool(val) {
    var result = val === 'true' ? true :
        val === 'false' ? false :
            val;
    return result;
}

// Create styles here
const styles = StyleSheet.create({
    page: {
        backgroundColor: 'white'
    },
    commentstext:
    {
        fontSize: "12",
        minWidth: "31%",
        margin: "5px"
    },
    comments: {
        marginTop: 10
    },
    sectiontext: {
        fontSize: "10",
        minWidth: "31%",
        margin: "5px"
    },
    imagesection: {
        fontSize: "12"
    },
    sectionheadingtext: {
        fontSize: "18",
        fontWeight: "bolder",

    },
    header: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        margin: "10px"
    },
    headerlogo: {
        width: "15%"
    },

    summary: {
        marginTop: 10
    },
    sectionheading: {
        border: 1,
        backgroundColor: "teal",
        color: "white"
    },
    sectionbody: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        // alignItems: "baseline",
        marginLeft: "3px"
    },
    inspect: {
        marginTop: 10
    },
    defects: {
        marginTop: 10
    },
    score: {
        marginTop: 10,

    },
    scoretext: {
        fontSize: "10",
        minWidth: "30%",
        margin: "2px"

    },
    environment: {
        marginTop: 10
    },
    images: {
        marginTop: 10,
        paddingBottom: "10px"
    },
    userimage: {
        width: "4in",
        margin: "3px"
    },

    poweredby: {
        position: "absolute",
        bottom: "5px",
        display: "flex",
        flexDirection: "row",
        marginLeft: "46%",
        height: "40px"
    },
    poweredbyimage: {
        maxWidth: "100%"
    },
    poweredbytext: {
        fontSize: "10"
    },
    footer: {
        position: "absolute",
        bottom: "2px",
        width: "100%"
    },
    scoreTable: {
        fontWeight: "bold",
        maxWidth: "100%",
        minWidth: "400",
    },
    cellPassed: {
        backgroundColor: "green",
        // width: "12%",
        // border: 1
        minWidth: "200px",
        border: 1,
        padding: 3
    },
    cellFailed: {
        backgroundColor: "red",
        // width: "12%",
        // border: 1
        minWidth: "200px",
        border: 1,
        padding: 3
    },
    cellNotGraded: {
        backgroundColor: "lightgrey",
        // width: "12%",
        // border: 1
        textAlign: "center",
        minWidth: "200px",
        border: 1,
        padding: 3
    },
    cellRow: {
        fontSize: "12",
        flexDirection: "row",
        minWidth: "100%",
        justifyContent: "center"

    },
    tableHeaders: {
        fontSize: "10",
        display: "flex",
        // padding: 1,
        // width: "12%"
        minWidth: "200px",
        padding: 3,
        textAlign: "center",
        border: 1
    },
    headerRow: {
        fontSize: "12",
        flexDirection: "row",
        minWidth: "100%",
        justifyContent: "center"
    },
    pageNumber: {
        textAlign: "center",
        fontSize: "8",
    },
    datetext: {
        fontSize: "10"
    },

    gradeheader: {
        flexDirection: "row"
    },
    gradetext: {

    },

    table: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderColor: '#bfbfbf',
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    tableRow: {
        margin: "auto",
        flexDirection: "row"
    },
    tableColHeader: {
        width: "25%",
        borderStyle: "solid",
        borderColor: '#bfbfbf',
        borderBottomColor: '#000',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCol: {
        width: "25%",
        borderStyle: "solid",
        borderColor: '#bfbfbf',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCellHeader: {
        margin: "auto",
        margin: 5,
        fontSize: 12,
        fontWeight: 500,
        textAlign: "center"
    },
    tableCell: {
        margin: "auto",
        margin: 5,
        fontSize: 10
    },
    tableCellNumber: {
        margin: "auto",
        margin: 5,
        fontSize: 10,
        textAlign: "right"
    },
    gradeTitleRow: {
        margin: "auto",
        flexDirection: "row",
        borderBottom: 1
    },
    gradeTitleCol: {
        width: "50%"
    },
    gradeTitleResultCol: {
        width: "50%",
        textAlign: "right",
        borderRight: 1,
        borderColor: '#bfbfbf'
    },
    gradeTitleCellRule: {
        margin: "auto",
        margin: 5,
        fontSize: 14,
        fontWeight: 800
    },
    gradeTitleCellPassResult: {
        margin: "auto",
        margin: 5,
        fontSize: 16,
        backgroundColor: "#69E98C",
    },
    gradeTitleCellFailResult: {
        margin: "auto",
        margin: 5,
        fontSize: 16,
        backgroundColor: "#EF3131"
    },
    pctTableCellPassResult: {
        margin: "auto",
        margin: 5,
        fontSize: 10,
        backgroundColor: "#69E98C",
        textAlign: "right"
    },
    pctTableCellFailResult: {
        margin: "auto",
        margin: 5,
        fontSize: 10,
        backgroundColor: "#EF3131",
        textAlign: "right"
    },

    defectCategoryRow: {
        margin: "auto",
        flexDirection: "row",
        borderBottom: 1
    },
    defectCategoryCol: {
        width: "100%",
        borderRight: 1,
        borderColor: '#bfbfbf'
    },
    defectCol: {
        width: "16.666%",
        borderRight: 1,
        borderBottom: 1,
        borderColor: '#bfbfbf'
    },

    defectValueRow: {
        margin: "auto",
        flexDirection: "row",
        width: "100%"
    },
    defectValueCol: {
        width: "16.666%",
        borderRight: 1,
        borderBottom: 1,
        borderColor: '#bfbfbf',
        backgroundColor: "lightblue"
    },

    defectNoValueCell: {
        margin: "auto",
        margin: 5,
        fontSize: 8,
        color: "gray"
    },
    defectValueText: {
        margin: "auto",
        margin: 5,
        fontSize: 10,
        color: "black"
    },
    defectDegreeCountText: {
        margin: "auto",
        margin: 5,
        fontSize: 9,
        color: "black",
    },
    defectTotalText: {
        textAlign: "center",
        fontSize: 11,
        fontWeight: "strong"
    }


});


export default class ObservationPdfVrsn2 extends Component {
    constructor(props) {
        super(props);

        this.obsData = this.props.observation;
        this.schema = this.props.schema;
        if (!this.obsData.data.PreInspectStatus) {
            this.obsData.data.PreInspectStatus = {
                PreInspectReject: "NO",
                Tags: []
            }
        }
        this.auth = new AuthService();
        this.gcid = this.auth.getGcid();
        this.appConfig = new AppConfig();
        this.inspectDate = new Date(this.obsData.data.Raw.inspectiondate);
        this.totalDefects = "";

        this.schemaPdfType = getSchemaPdfType(this.obsData.data.schema.id);
        this.drawInspection = this.drawInspection.bind(this);
        this.drawEnvironment = this.drawEnvironment.bind(this);
        this.userImages = this.userImages.bind(this);

    }


    componentDidMount() {
    }

    gcidLogo() {
        return (
            <Image style={styles.headerlogo} src={this.appConfig.getLogoImageBase() + "/gcid/" + this.gcid + "/logos/" + this.gcid + "-logo.png"}
            />
        )
    }

    drawPassFailScore() {
        var result = [];
        result.push(<Text style={styles.scoretext}>{"Scoring Schema: " + this.obsData.score.Score.Scorer.id}</Text>);
        result.push(<Text style={styles.scoretext}>{"Scoring Schema Version: " + this.obsData.score.Score.Scorer.version}</Text>);
        result.push(<Text style={styles.scoretext}>{"Scoring Schema Date: " + new Date(this.obsData.score.Score.Scorer.lastUpdated).toLocaleDateString()}</Text>);
        result.push(<Text style={styles.scoretext}>{"Pass: " + this.obsData.score.Score.Grade.Weighted}</Text>);
        return result;
    }

    drawMultAccelScore() {
        return (<Text>Mult/Accel Scoring</Text>)
    }

    isMultAccelGradeRule(gradeRule) {
        return (gradeRule.weightedScore); // has weightedScore vs pct used in USDA.
    }

    drawCategorySeverityScore() {
        if (this.schemaPdfType === "passfail") return;
        var result = [];
        var byGrade = this.obsData.score.Score.Gradient;
        var allRules = ["grade"];
        byGrade.forEach(grade => {
            var byRules = (grade.rules) ? grade.rules : [];
            byRules.forEach(rule => {
                if (!allRules.includes(rule.title)) {
                    allRules.push(rule.title);
                }
            })
        });

        // create header
        var headerRowArray = allRules.map(rule => {
            return <Text style={styles.tableHeaders}>{rule}</Text>
        })
        //result.push(<View style={styles.headerRow}>{headerRowArray}</View>);

        // for each grade
        byGrade.forEach(grade => {
            let gradeRowArray = new Array(allRules.length - 1);

            var gradeCell = (grade.passed) ?
                <Text style={styles.cellPassed}>{grade.grade}</Text> :
                <Text style={styles.cellFailed}>{grade.grade}</Text>
            gradeRowArray[0] = gradeCell;
            for (var i = 1; i < allRules.length; i++) {
                let cell = <Text style={styles.cellNotGraded}> -- </Text>
                var gradeRules = (grade.rules) ? grade.rules : [];
                console.log("grade rules:");
                console.log(gradeRules);
                for (var j = 0; j < gradeRules.length; j++) {
                    var cellDescript = "";
                    if (gradeRules[j].title === allRules[i]) {
                        if (this.isMultAccelGradeRule(gradeRules[j])) { // TBD: multAccell uses a different property and boolean symbol. Should be defined in the schema scoring info itself! 
                            cellDescript = '>' + gradeRules[j].description.split('>')[1] + ' [' + gradeRules[j].weightedScore.toString().substr(0, 7) + '%]';
                        }
                        else {
                            cellDescript = '<=' + gradeRules[j].description.split('<=')[1] + ' [' + gradeRules[j].pct.toString().substr(0, 7) + '%]'; // must be a USDA-type
                        }
                        cell = (gradeRules[j].passed) ?
                            <Text style={styles.cellPassed}>{cellDescript}</Text> :
                            <Text style={styles.cellFailed}>{cellDescript}</Text>;
                    }
                }
                gradeRowArray[i] = cell;
            }
            result.push(<View style={styles.cellRow}>{gradeRowArray}</View>);

        });

        return result;
    }


    makeGradeHeader(gradeName, gradeResult, didPass) {
        var result =
            <View style={styles.gradeTitleRow}>
                <View style={styles.gradeTitleCol}>
                    <Text style={styles.gradeTitleCellRule}>{gradeName}</Text>
                </View>
                <View style={styles.gradeTitleResultCol}>
                    <Text style={(didPass) ? styles.gradeTitleCellPassResult : styles.gradeTitleCellFailResult}>{gradeResult}</Text>
                </View>
            </View>
        return result;
    }

    makeGradeRuleHeader() {
        var result =
            <View style={styles.tableRow}>
                <View style={styles.tableColHeader}>
                    <Text style={styles.tableCellHeader}>Rule</Text>
                </View>
                <View style={styles.tableColHeader}>
                    <Text style={styles.tableCellHeader}>Descript</Text>
                </View>
                <View style={styles.tableColHeader}>
                    <Text style={styles.tableCellHeader}>Total</Text>
                </View>
                <View style={styles.tableColHeader}>
                    <Text style={styles.tableCellHeader}>Score</Text>
                </View>
            </View>
        return result;
    }

    makeGradeRule(ruleName, ruleDescript, ruleTotal, rulePct, didRulePass) {
        var ruleTotalReduced = ruleTotal.toString().substr(0, 7);
        var rulePctReduced = rulePct.toString().substr(0, 7);
        var result =
            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{ruleName}</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{ruleDescript}</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCellNumber}>{ruleTotalReduced}</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={(didRulePass) ? styles.pctTableCellPassResult : styles.pctTableCellFailResult}>{rulePctReduced}</Text>
                </View>
            </View>

        return result;
    }

    makeGradeTable(gradeArray) {
        var result =
            <View style={styles.table}>
                {gradeArray}
            </View>
        return result;
    }

    makeGradeTableSpacer() {
        var result =
            <View><Text>   </Text></View>

        return result;
    }

    isCutoffGrade(grade, cutoffGrade) {
        var testCutoffGrade = (cutoffGrade) ? cutoffGrade.grade : "REJECT";
        return (grade.toLowerCase() === testCutoffGrade.toLowerCase());
    }

    is

    drawCategorySeverityScore2() {
        if (this.schemaPdfType === "passfail") return;
        var result = [];
        var byGrade = this.obsData.score.Score.Gradient;

        // for each grade, create a separate section
        byGrade.forEach(grade => {
            //if (grade.grade === "REJECT") 
            // we do NOT show rules for the cutoff grade because the rule IS that the
            // observation HIT the cutoff grade. Usually reject, but NOT ALWAYS.
            if (this.isCutoffGrade(grade.grade, this.schema.scoring.cutoffGrade)) { return }

            var gradeArray = [];
            // create "grade" header
            var gradePassed = (this.isMultAccelGradeRule(grade.rules[0])) ? grade.passed : toBool(grade.didGradePass);

            var gradeHeader = this.makeGradeHeader(grade.grade, (gradePassed) ? "PASS" : "FAIL", gradePassed);
            gradeArray.push(gradeHeader);
            var gradeRuleHeader = this.makeGradeRuleHeader();
            gradeArray.push(gradeRuleHeader);
            var gradeRules = (grade.rules) ? grade.rules : [];
            gradeRules.forEach(rule => {
                var rulePct = (this.isMultAccelGradeRule(rule)) ? rule.weightedScore : rule.pct;
                var rulePassed = (this.isMultAccelGradeRule(rule)) ? rule.passed : toBool(rule.didRulePass);
                var gradeRule = this.makeGradeRule(rule.title, rule.description, rule.totalDefects, rulePct, rulePassed);
                gradeArray.push(gradeRule);
            })
            // TBD - grade rule table
            var gradeTable = this.makeGradeTable(gradeArray);
            result.push(gradeTable);
            result.push(this.makeGradeTableSpacer());
        });

        return result;
    }

    normalizeCategorySeverityDefects() {
        var result = [];
        var byCategory = this.obsData.score.Score.GlobalDefects.ByCategory;
        var byDefect = this.obsData.score.Score.GlobalDefects.ByDefect;
        console.log(this.obsData.score.Score);
        for (var i = 0; i < byCategory.length; i++) {
            var cat = { "defectGroup": byCategory[i].category + '-' + byCategory[i].degree, "value": byCategory[i].value, "defects": [] };
            for (var j = 0; j < byDefect.length; j++) {
                if (byDefect[j].category === byCategory[i].category && byDefect[j].degree === byCategory[i].degree) {
                    cat.defects.push({ "defect": byDefect[j].cardTitle, "count": byDefect[j].count })
                }
            }
            result.push(cat);
        }
        return result;
    }

    normalizePassFailConditions() {
        var result = [];
        var cat = { "defectGroup": "Failed Conditions", "value": null, "defects": [] };
        var failedConditions = this.obsData.score.Score.FailedConditions;
        // if for some reason there ARE NO failedConditions, then just re-initialize empty! 
        if (!failedConditions) {
            failedConditions = [];
        }
        failedConditions.forEach(cond => {

            cat.defects.push({ "defect": cond, "count": null })
        });
        result.push(cat);
        return result;
    }



    preInspectRejectDefects() {
        console.log(this.obsData);
        var result = [];
        result.push(<Text>PreInspect Rejection</Text>);
        this.obsData.data.PreInspectStatus.Tags.forEach(tag => {
            result.push(<Text>--{tag}</Text>)
        });

        return result;
    }


    makeDefectHeader(headerName) {
        var result =
            <View style={styles.defectCategoryRow}>
                <View style={styles.defectCategoryCol}>
                    <Text style={styles.gradeTitleCellRule}>{headerName}</Text>
                </View>
            </View>
        return result;
    }

    makeDefect(defectName) {
        var result =
            <View style={styles.defectCol}>
                <Text style={styles.defectNoValueCell}>{defectName}</Text>
            </View>

        return result;
    }

    makeDefectRow(defects) {
        var result =
            <View style={styles.defectValueRow}>{defects}</View>

        return result
    }

    defectCount(defectName, variants, rawObsData) {
        var result = 0;
        if (variants) {
            variants.forEach((variant) => {
                var lookupFormat = toCardId(defectName) + variant.toLowerCase();
                var val = rawObsData[lookupFormat];
                var valCount = (val) ? parseInt(val) : 0;
                result += valCount;
            });
        }
        else {
            var lookupFormat = toCardId(defectName);
            var val = rawObsData[lookupFormat];
            var result = (val) ? parseInt(val) : 0;
        }
        return result;
    }

    hasDefectValue(defectName, variants, rawObsData) {
        var result = false;
        if (variants) {
            variants.forEach((variant) => {
                if (result) return;
                var lookupFormat = toCardId(defectName) + variant.toLowerCase();
                var val = rawObsData[lookupFormat];
                if (val) {
                    result = true;
                }
            });
        }
        else {
            var lookupFormat = toCardId(defectName);
            var val = rawObsData[lookupFormat];
            result = val;
        }
        return result;
    }

    makeValDefect(defectName, variants, rawObsData) {
        var valueTextElements = [];
        if (variants) {
            variants.forEach((variant) => {
                var lookupFormat = toCardId(defectName) + variant.toLowerCase();
                var val = rawObsData[lookupFormat];
                if (val) {
                    valueTextElements.push(<Text style={styles.defectDegreeCountText}>{variant}: {val}</Text>);
                }
            });
        }
        else {
            var lookupFormat = toCardId(defectName);
            var val = rawObsData[lookupFormat];
            valueTextElements.push(<Text style={styles.defectDegreeCountText}>{val}</Text>);
        }

        var result = <View style={styles.defectValueCol}>
            <Text style={styles.defectValueText}>{defectName}</Text>
            {valueTextElements}
        </View>

        return result;
    }

    makeDefectTotals(totalInspected, totalDefects) {
        var result =
            <View style={styles.table}>
                {this.makeDefectHeader("Totals")}
                <View style={styles.defectValueRow}>
                    <View style={styles.defectCol}>
                        <Text style={styles.defectNoValueCell}>Total Inspected</Text>
                    </View>
                    <View style={styles.defectCol}>
                        <Text style={styles.defectNoValueCell}>Total Defects</Text>
                    </View>
                </View>

                <View style={styles.defectValueRow}>
                    <View style={styles.defectCol}>
                        <Text style={styles.defectTotalText}>{totalInspected}</Text>
                    </View>
                    <View style={styles.defectCol}>
                        <Text style={styles.defectTotalText}>{totalDefects}</Text>
                    </View>
                </View>
            </View>

        return result;
    }

    drawDefects2() {
        if (this.obsData.score.Score.IsPreInspectRejected === true) {
            return this.preInspectRejectDefects();
        }
        var totalInspected = this.obsData.score.Score.TotalInspected;
        var totalDefects = 0; // we will calculate this as we go along

        var result = [];
        var normalizedDefects = (this.schemaPdfType === "passfail") ? this.normalizePassFailConditions() : this.normalizeCategorySeverityDefects();
        console.log(normalizedDefects);
        var schemaDefects = this.schema.categories.find(cat => cat.id === "defects");
        var schemaDefectGroups = schemaDefects.groups;

        schemaDefectGroups.forEach((grp) => {
            // "count"/"weight" is not actually a defect group for this report
            if ((grp.groupTitle.toLowerCase() === "count") || (grp.groupTitle.toLowerCase() === "weight")) return;
            var groupArray = [];
            var groupHeader = this.makeDefectHeader(grp.groupTitle.toUpperCase());
            groupArray.push(groupHeader);
            var rowArray = [];
            var groupDefects = grp.cards;
            var rowDefects = [];
            for (var i = 0; i < groupDefects.length; i++) {
                var hasDefectVal = this.hasDefectValue(groupDefects[i].title, groupDefects[i].variants, this.obsData.data.Raw);
                var defectCount = (hasDefectVal) ? this.defectCount(groupDefects[i].title, groupDefects[i].variants, this.obsData.data.Raw) : 0;
                totalDefects += defectCount;
                var defect = (hasDefectVal) ?
                    this.makeValDefect(groupDefects[i].title, groupDefects[i].variants, this.obsData.data.Raw) :
                    this.makeDefect(groupDefects[i].title);
                rowDefects.push(defect);
                var x = (i === 5);
                var yMod = ((i - 1) % 5);
                var y = ((((i - 1) % 5) === 0) && (i > 6));
                var z = (i === groupDefects.length - 1);
                if (x || y || z) {
                    var atEnd = (i === groupDefects.length - 1);
                    var sliceStart = (atEnd) ?
                        (i - ((i + 1) % 6)) + 1 :
                        i - 5;
                    var sliceEnd = (atEnd) ?
                        groupDefects.length :
                        i + 1;
                    var slicedRowDefects = rowDefects.slice(sliceStart, sliceEnd);

                    var defectRow = this.makeDefectRow(slicedRowDefects);
                    rowArray.push(defectRow);
                    //rowDefects.splice(0, rowDefects.length);  // empty the "working" array
                }
            }
            groupArray.push(rowArray);
            var defectTable = this.makeGradeTable(groupArray);
            result.push(defectTable);
            result.push(this.makeGradeTableSpacer());
        });
        // put in totals table
        result.push(this.makeDefectTotals(totalInspected, totalDefects));
        return result;
    }

    drawInspection() {
        //keyed in values that look too much like a date are being appended with date info lol ++++++++++++++++++++++++++++++++++++++++++++++++
        var results = [];
        if (this.obsData.data.UnderInspection) {
            var inspectData = this.obsData.data.UnderInspection;
            for (const [key, value] of Object.entries(inspectData)) {
                if (value) {
                    if (key === "InspectionDate" || key === "HarvestDate") {
                        if (moment(value).isValid()) {
                            var tempdate = moment(value).format('YYYY-MM-DD HH:MM')
                            results.push(<Text style={styles.sectiontext}>{key}: {tempdate}</Text>)
                        }
                    } else {
                        results.push(<Text style={styles.sectiontext}>{key}: {value}</Text>)
                    }
                }
            }
            return results
        } else {
            return <Text>-------</Text>
        }

    }

    drawEnvironment() {
        var results = [];
        if (this.obsData.data.Environment) {
            var envData = this.obsData.data.Environment;
            for (const [key, value] of Object.entries(envData)) {
                if (value && key !== "Comments") {

                    results.push(<Text style={styles.sectiontext}>{key}: {value}</Text>)
                }
            }
            if (results.length > 0) {
                return results;
            }
            else {
                return <Text>No Environmental Data Recorded</Text>
            }
        }
    }

    userImages() {
        // console.log(this.obsData.data.Raw.userimages);
        if (!this.obsData.data.Raw.userimages) return (<Text style={styles.commentstext}>No Inspection Images</Text>);
        return this.obsData.data.Raw.userimages.map(el => {
            // console.log(this.appConfig.getObsImageHostAddr(this.gcid, "user") + el.id + ".png");
            var tagList = (el.tags) ?
                el.tags.map((tag) => {
                    return tag.label;
                }).join(', ')
                : ""

            var imgExtension;
            if (el.ext === "png") {
                imgExtension = ".png"
            } else if (el.ext === "jpeg") {
                imgExtension = ".jpeg"
            }
            var returnEl =
                <View style={styles.imagesection}>
                    <Image style={styles.userimage} key={el.id} src={this.appConfig.getObsImageHostAddr(this.gcid, "user") + el.id + imgExtension} alt="user" />
                    <Text style={styles.sectiontext}>{"[" + tagList + "] " + el.descript}</Text>
                </View>;
            return returnEl;
        })
    }

    render() {
        var today = new Date().toDateString()


        //need to fetch this value again for differences between schemas, pull from different places +++++++++++++++++++++++++++++++++++++++++
        var currentItemName = '';
        if (this.obsData.data.Raw.itemdescript) {
            currentItemName = this.obsData.data.Raw.itemdescript
        } else if (this.obsData.data.Raw.itemname) {
            currentItemName = this.obsData.data.Raw.itemname
        } else {
            currentItemName = "No Name Entered"
        }

        //Items not entered in the app will look like blank fields in the text++++++++++++++++++++++
        return (
            <Document>
                <Page size="A4" style={styles.page} wrap={true}>

                    <View style={styles.header}>
                        {this.gcidLogo()}
                        <Text style={{ fontSize: "24" }}>QA Inspection Report</Text>
                        <Text style={styles.datetext}>{today}</Text>
                    </View>

                    <View style={styles.summary}>
                        <View style={styles.sectionheading}><Text style={styles.sectionheadingtext}>Summary: {this.obsData.score.Score.Grade.Raw}</Text></View>

                        <View style={styles.sectionbody}>
                            <Text style={styles.sectiontext}>Date: {moment(this.obsData.data.UnderInspection.InspectionDate).format('YYYY-MM-DD')}</Text>
                            <Text style={styles.sectiontext}>Commodity: {this.obsData.data.schema.name}</Text>
                            <Text style={styles.sectiontext}>Location: {this.obsData.data.UnderInspection.Location}</Text>
                            <Text style={styles.sectiontext}>Item Name: {currentItemName}</Text>
                            <Text style={styles.sectiontext}>Inspector: {this.obsData.data.UnderInspection.Inspector}</Text>
                        </View>
                    </View>

                    <View style={styles.inspect}>
                        <View style={styles.sectionheading}><Text style={styles.sectionheadingtext}>Under Inspection: {this.obsData.data.schema.name}</Text></View>
                        <View style={styles.sectionbody}>
                            {this.drawInspection()}
                        </View>

                    </View>

                    <View style={styles.defects}>
                        <View style={styles.sectionheading}>
                            <Text style={styles.sectionheadingtext}>Defects</Text>
                        </View>
                        <View style={styles.sectionbody}>
                            {this.drawDefects2()}
                        </View>
                    </View>

                    <View style={styles.score}>
                        <View style={styles.sectionheading}><Text style={styles.sectionheadingtext}>Scoring</Text></View>
                        <View style={styles.sectionbody}>
                            {this.drawCategorySeverityScore2()}
                            {this.drawPassFailScore()}
                        </View>
                    </View>
                    <View style={styles.comments} wrap={false}>
                        <View style={styles.sectionheading}><Text style={styles.sectionheadingtext}>Comments</Text></View>
                        <View style={styles.sectionbody}>
                            <Text style={styles.commentstext}>{(this.obsData.data.Raw.comments) ? this.obsData.data.Raw.comments : "No Inspector Comments"}</Text>
                        </View>
                    </View>
                    <View style={styles.environment} wrap={false}>
                        <View style={styles.sectionheading}><Text style={styles.sectionheadingtext}>Environment</Text></View>
                        <View style={styles.sectionbody}>
                            {this.drawEnvironment()}
                        </View>
                    </View>

                    <View style={styles.images} break>
                        <View style={styles.sectionheading}><Text style={styles.sectionheadingtext}>User Images</Text></View>
                        <View style={styles.sectionbody}>
                            {this.userImages()}
                        </View>
                    </View>

                    <View style={styles.poweredby}>
                        <Text style={styles.poweredbytext}>powered by {"\n"}  glassbit</Text>
                        <Image style={styles.poweredbyimage} src={gcLogo} />
                    </View>
                    <View style={styles.footer}>
                        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                            `${pageNumber} / ${totalPages}`
                        )} fixed />
                    </View>
                </Page>

            </Document >

        )
    }
}