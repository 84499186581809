
export function toCardId(cardTitle){
    return cardTitle.replace(/ /g, "").replace(/\//g, "").replace(/\\/g, "").toLowerCase();
}

export function toCardTitle(cardTitle){
    // keeps case
    return cardTitle.replace(/ /g, "").replace(/\//g, "").replace(/\\/g, "");
}

export function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }
  
export function deleteKey(obj, key) {
    delete obj[key];
  }

export function removeArrayValue(arr, value) { 
    return arr.filter(function(ele){ return ele !== value; });
  }


export function toLocalShortDate(dt) {
    var result =
    dt.getFullYear() +
    "-" +
    ("0" + (dt.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + dt.getDate()).slice(-2);
    return result;
  }

