

export default class AddressBook {
    constructor(gcid, userName){
        this.gcid = gcid;
        this.userName = userName;
        this.addresses = [];
    }

    hasAddress(address) {
        var result =  (this.addresses.filter(val => val.email===address.email).length > 0);
        return result;
    }

    addAddress(address){
        this.addresses.push(address);
    }


}