import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import queryString from "query-string";
import moment from "moment";

import ObservationsSearchCard from "../cards/ObservationsSearchCard.jsx";
import ObservationsGridCard from "../cards/ObservationsGridCard.jsx";
import LocalObsGridCard from "../cards/LocalObsGridCard.jsx"
import ObservationDetailCard from "../cards/ObservationDetailCard.jsx";
import ModalVoidDialog from "../../Modals/ModalVoidDialog.jsx";

import AuthService from "../../AuthService/AuthService.js";
import GlasschainHttpClient from "../../ApiService/GlasschainHttpClient.js";
import IDbService from "../../DataService/IDbService.js";
import IDbDocService from "../../DataService/IDbDocService.js";
import ModalSendMsgDialog from "../../Modals/ModalSendMsgDialog.jsx";

import PdfObservation from "../../lib/Pdf/PdfObservation";



export default class HistoryPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      renderChildren: true,

      selectedId: "",
      currDetailedGridRow: null,
      gridQueryString: "",
      showVoidDialog: false,
      showSendMsgDialog: false
    };

    this.auth = new AuthService();
    this.httpClient = new GlasschainHttpClient();
    this.localDbService = new IDbService();
    this.localDocService = new IDbDocService();

    this.onGridQueryStringChange = this.onGridQueryStringChange.bind(this);
    this.onDetailedGridRowChange = this.onDetailedGridRowChange.bind(this);
    this.onVoidRequest = this.onVoidRequest.bind(this);
    this.onVoidPostRequest = this.onVoidPostRequest.bind(this);
    this.onVoidCancelRequest = this.onVoidCancelRequest.bind(this);
    this.onSendMsgRequest = this.onSendMsgRequest.bind(this);
    this.onSendMsgPostRequest = this.onSendMsgPostRequest.bind(this);
    this.onSendMsgCancelRequest = this.onSendMsgCancelRequest.bind(this);
    this.onObservationPdfRequest = this.onObservationPdfRequest.bind(this);
    this.onSyncCompleteEvent = this.onSyncCompleteEvent.bind(this);
    this.onToastNotificationRequest = this.onToastNotificationRequest.bind(this);
  }

  componentDidUpdate(prevProps, prevState){
    if (prevProps.isConnected !== this.props.isConnected){
      // force a resync!
      this.onSyncCompleteEvent();
    }
  }

  onToastNotificationRequest(notifyType, msg){
    if (notifyType === 'error'){
      toast.error(msg);
    }
    else if (notifyType === 'warning'){
      toast.warning(msg);
    }
    else if (notifyType === 'success'){
      toast.success(msg);
    }
    else {
      toast.info(msg);
    }
  }

  onSyncCompleteEvent(){
    // we want to trigger a refresh on both grids. We can do this by changing the gridQueryString, which is "watched" by the grids.
    // let's just set a single flag and increment it. 
    const queryVals = queryString.parse(this.state.gridQueryString);
    var rf = queryVals.rf;
    var refVal = rf ? parseInt(queryVals.rf)+1 : 1;
    queryVals.rf= refVal;
    var newGridQueryString = queryString.stringify(queryVals);
    this.setState({gridQueryString: newGridQueryString});

  }

  async onObservationPdfRequest(id){
    //this.props.onRequestObservationPdf(id);
    console.log("Current Row: ");
    console.log(this.state.currDetailedGridRow);
    var schemaId = this.state.currDetailedGridRow.data.schema.id;
    var newSchemaData = this.auth.getLocalSchema(schemaId);
    var newFullSchema = JSON.parse(newSchemaData.fullSchema);

    var pdfObs = new PdfObservation(this.state.currDetailedGridRow, newFullSchema);
    console.log("pdfObs observation: ");
    console.log(pdfObs.observation);
    await pdfObs.writeToCloudStorage();
    window.open(
      pdfObs.getLinkFromObservation(),
      "_blank"
    );
  }

  onSendMsgRequest(){
    this.setState({showSendMsgDialog: true});
  }

  onSendMsgPostRequest(){
    // TBD - figure out the parameters. Probably an object. 
  }

  onSendMsgCancelRequest(){
    this.setState({showSendMsgDialog: false})
  }

  onVoidRequest(id) {
    // get the reason from the dialog
    this.setState({
      selectedId: id,
      showVoidDialog: true,
    });
  }

  async onVoidPostRequest(voidReason) {
    this.setState({ showVoidDialog: false });
    if (!voidReason || voidReason === "Choose Reason for Void") {
      alert("Choose a Reason!");
      return;
    }
    //console.log("Save ID " +this.state.currDetailedGridRow.identifiers.id +" with reason: " +voidReason);
    this.setState({ showVoid: false });
    if (this.state.currDetailedGridRow) {
      var voidEvent = {
        Gcid: this.props.orgConfig.gcid,
        ObservationId: this.state.currDetailedGridRow.identifiers.id,
        EventDate: moment().format(),
        EventType: "void",
        EventReason: voidReason,
        EventBy: this.auth.getUser(),
        EventLocation: this.auth.getLocation(),
        Changed: {
          OriginalStatus:
            this.state.currDetailedGridRow.identifiers.status ?? "",
          OriginalStatusReason:
            this.state.currDetailedGridRow.identifiers.statusReason ?? "",
        },
      };


      if (this.props.isConnected){
        await this.httpClient.postQaObservationVoid(voidEvent);
      }
      else {
        await this.localDbService.updateQaObservationPendingToVoid(this.gcid, voidEvent.ObservationId);
        await this.localDocService.AddDoc(voidEvent, "voidevent", voidEvent.ObservationId);
      }
      toast.info("Inspection voided!");
      // trigger requery
      var newGridQueryString = this.state.gridQueryString + "&q=" + voidEvent.ObservationId;
      this.setState({ gridQueryString: newGridQueryString });
    } else {
      alert("Select a Row!");
    }
  }

  onVoidCancelRequest() {
    this.setState({ showVoidDialog: false });
  }

  onGridQueryStringChange(newGridQueryString) {
    let newMainFilter = this.auth.getMainFilter();
    if (newMainFilter) {
      newMainFilter.schemaId = this.props.schemaId;
      this.auth.setMainFilter(newMainFilter);
    }
    this.setState({ gridQueryString: newGridQueryString });
  }

  onDetailedGridRowChange(newDetailedGridRow) {
    //console.log("currDetailedGridRow:");
    //console.log(newDetailedGridRow);
    this.setState({ currDetailedGridRow: newDetailedGridRow });
  }

  render() {

    const obsSearchRows = (this.state.renderChildren && this.props.isConnected) ?
    <span>
    <Row noGutters={true} style={{ display: "flex" }}>
    <Col>
      <ObservationsSearchCard
        orgConfig={this.props.orgConfig}
        schemaId={this.props.schemaId}
        onGridQueryStringChange={this.onGridQueryStringChange}
      />
    </Col>
  </Row>
  <Row noGutters={true}>
    <Col>
      <ObservationsGridCard
        isConnected={this.props.isConnected}
        onSystemLogoutRequest={this.props.onSystemLogoutRequest}
        schemaId={this.props.schemaId}
        orgConfig={this.props.orgConfig}
        gridQueryString={this.state.gridQueryString}
        onDetailedGridRowChange={this.onDetailedGridRowChange}
        onVoidRequest={this.onVoidRequest}
        onSendMsgRequest={this.onSendMsgRequest}
        onObservationPdfRequest={this.onObservationPdfRequest}
        onRequestUpdateObservation={this.props.onRequestUpdateObservation}
        onViewUserImagesRequest={this.props.onViewUserImagesRequest}
      />
    </Col>
  </Row>
  </span>
  : <span></span>
  



    return this.state.renderChildren ? (
      <Container fluid>
        <ModalSendMsgDialog
          show={this.state.showSendMsgDialog}
          onSendMsgRequest={this.onSendMsgRequest}
          onCloseRequest={this.onSendMsgCancelRequest}
          currentRow={this.state.currDetailedGridRow}
          onToastRequest={this.props.onToastRequest}
        />

        <ModalVoidDialog
          show={this.state.showVoidDialog}
          voidReasonList={this.props.orgConfig.voidReasons}
          onVoidPostRequest={this.onVoidPostRequest}
          onVoidCancelRequest={this.onVoidCancelRequest}
        />

        {obsSearchRows}

        <Row noGutters={true}>
          <Col>
            <LocalObsGridCard
              schemaId={this.props.schemaId}
              orgConfig={this.props.orgConfig}
              gridQueryString={this.state.gridQueryString}
              onDetailedGridRowChange={this.onDetailedGridRowChange}
              onVoidRequest={this.onVoidRequest}
              onRequestUpdateObservation={this.props.onRequestUpdateObservation}
              onSyncCompleteEvent={this.onSyncCompleteEvent}
              onToastNotificationRequest = {this.onToastNotificationRequest}
              isConnected={this.props.isConnected}
            />
          </Col>
        </Row>
        <Row noGutters={true}>
          <Col>
            <ObservationDetailCard
              currDetailedGridRow={this.state.currDetailedGridRow}
            />
          </Col>
        </Row>
      </Container>
    ) : (
      <div>"Loading History Panel..."</div>
    );
  }
}
