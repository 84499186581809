import axios from 'axios';

import AuthService from '../AuthService/AuthService.js';
import AppConfig from '../AppConfig/AppConfig.js';


export default class RequestService {
    // Initializing important variables
    constructor(domain) {
        this.appConfig = new AppConfig();
        this.domain = domain || this.appConfig.getCoreServiceHost(); 
        this.authSvc = new AuthService();
        this.fetchBearerToken = this.fetchBearerToken.bind(this);
    }

    fetchBearerToken() {
        if (this.authSvc.loggedIn()) {
            return this.authSvc.getToken();
        }
        else {
            return "";
        }
    }

    statusOk(response) {
        return (response.status >= 200 && response.status < 300);
    }


    downloadFile(url){
        let fullUrl = this.domain + url;
        window.open(fullUrl);
    }

    async fetchJson(url, withToken = true) {
        try {
            var config = {
                method: 'GET'
            }
            if (withToken) {
                let token = this.authSvc.getToken();
                config = {
                    method: 'GET',
                    headers: {
                        "Authorization": "bearer " + token
                    }
                }
            }
            let fullUrl = this.domain + url;
            //let fullUrl = 'https://jsonplaceholder.typicode.com/users';
            const response = await axios(fullUrl, config);
            //var response = await fetch(fullUrl);
            if (this.statusOk(response)) {
                //const jsonResponse = await response.json();
                return response;
            } else {
                throw new Error(response.status + response.statusText);
            }

        } catch (error) {
            console.log(error);
            throw new Error(error);
        }
    }



    async postJson(url, postObj, withToken = true) {
        try {
            var config = {
                method: 'POST'
            }
            if (withToken) {
                let token = this.authSvc.getToken();
                config = {
                    method: 'POST',
                    headers: {
                        "Authorization": "bearer " + token,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'              
                    }
                }
            }
            let fullUrl = this.domain + url;
            //let fullUrl = 'https://jsonplaceholder.typicode.com/users';
            const response = await axios.post(fullUrl, postObj, config);
            //var response = await fetch(fullUrl);
            if (this.statusOk(response)) {
                //const jsonResponse = await response.json();
                return response;
            } else {
                throw new Error(response.status + response.statusText);
            }

        } catch (error) {
            console.log(error);
            throw new Error(error);
        }
    }


    async fetchJsonFile(url, withToken = true, fName="") {
        try {
            var config = {
                method: 'GET'
            }
            if (withToken) {
                let token = this.authSvc.getToken();
                config = {
                    method: 'GET',
                    headers: {
                        "Authorization": "bearer " + token
                    },
                    responseType: 'blob'
                }
            }
            let fullUrl = this.domain + url;
            //let fullUrl = 'https://jsonplaceholder.typicode.com/users';
            const response = await axios.get(fullUrl, config);
            //var response = await fetch(fullUrl);
            if (this.statusOk(response)) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                if (fName === ""){
                    fName = "PostDownload"
                };
                link.setAttribute('download', fName + '.zip'); //or any other extension
                document.body.appendChild(link);
                link.click();
                //const jsonResponse = await response.json();
                //return response;
            } else {
                throw new Error(response.status + response.statusText);
            }

        } catch (error) {
            console.log(error);
            throw new Error(error);
        }
    }





}