import React, { Component } from "react";
import {
  FormControl,
  InputGroup,
} from "react-bootstrap";
import "../../assets/css/attrib-cards.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpandAlt} from "@fortawesome/free-solid-svg-icons";
import {toCardId} from "./AttribCardUtils.js";


import AuthService from "../AuthService/AuthService.js";




export default class AttribUserImageCard extends Component {
  constructor(props) {
    super(props);

    this.Id = toCardId(this.props.attribTitle);

    this.state = {
      isThumb: true
    }


    this.auth = new AuthService();

    this.renderState = this.renderState.bind(this);
    this.onCardClick = this.onCardClick.bind(this);
    this.onLoseFocus = this.onLoseFocus.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.toggleImageSize = this.toggleImageSize.bind(this);
  }

  toggleImageSize(){
    this.setState({isThumb: !this.state.isThumb});
  }

  onValueChange(ev) {
    console.log(ev);
    this.setState({ cardValue: ev.target.value });
    this.props.onValueChange(this.Id, ev.target.value);
  }

  onLoseFocus() {
    var newCardState = (this.state.cardValue) ? "touched" : "untouched";
    this.setState({ cardState: newCardState });
  }

  onCardClick(ev) {
    this.setState({ cardState: "touched" });
    this.props.onToggleCard(this);
  }




  renderTouchedState() {

    const tags = this.props.tags.map((tag) =>{
      if (tag){
        if (tag.value){
          return tag.value;
        }
      }
    });

    const imgWidth = (this.state.isThumb) ? "120px": "200%";


    return (
      <div
        className="nonactivecard card-template touchedcard shadow7 user-img-card"
        onClick={this.onCardClick}
        
      >

          <img src={this.props.dataUri} alt={this.props.descript} width={imgWidth}/>    
          <div >
            <div>{this.props.descript}</div>
            Tags: <pre>{JSON.stringify(tags)}</pre>&nbsp;&nbsp;
            <FontAwesomeIcon icon={faExpandAlt} onClick={this.toggleImageSize} />
          </div>

      </div>
    );
  }

  renderState() {
      return this.renderTouchedState(); // the only state we have!
    /*
    switch (this.state.cardState) {
      case "untouched":
        return this.renderUntouchedState();
      case "touched":
        return this.renderTouchedState();
      default:
        return this.renderActiveState();
    }
    */
  }

  render() {
    return this.renderState();
  }
}
