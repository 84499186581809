import React, { Component } from "react";
import { Container, InputGroup, FormControl, Row, Col, Button, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage, faPortrait, faSpellCheck, faCameraRetro, faUpload, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import CreatableSelect from "react-select/creatable";
import Camera, { FACING_MODES } from "react-html5-camera-photo";
import 'react-html5-camera-photo/build/css/index.css';
import ImagePreview from "../lib/Camera/ImagePreview.js";
import '../../assets/css/modal-dialogs.css';
import AuthService from "../AuthService/AuthService.js";
import GlasschainHttpClient from "../ApiService/GlasschainHttpClient.js";
import { v1 as uuidv1 } from 'uuid';
import "../../assets/css/modals.css";

import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import ErrorImg from "../../assets/images/ImageNotFound.png";


const components = {
  DropdownIndicator: null
};
const plateStates = [
  { label: "California", value: "CA" },
  { label: "Washington", value: "WA" },
  { label: "Oregon", value: "OR" },
  { label: "Arizona", value: "AZ" },
  { label: "Nevada", value: "NV" },
  { label: "Utah", value: "UT" },
  { label: "Idaho", value: "ID" },
  { label: "Montana", value: "MT" },
  { label: "Iowa", value: "IA" }
]

const createOption = (label) => ({
  label,
  value: label,
});


function findTruckLicensePlate(ar, ignore) {
  for (var i = 0; i < ar.length; i++) {
    let hit = /^[^+_=/*?@#$%&()'"|â„;:{}.,`~<>}{][^\\]{1,20}$/g.test(ar[i]);
    if (!hit) continue;
    if (findLicensePlateState[ar[i]]) continue;
    if ((ar[i].length < 5) || (ar[i].length > 9)) continue;
    // there may be vanity plates that don't have combination of letters and numbers but
    var hasNumber = false;
    var hasLetter = false;
    for (let j = 0; j < ar[i].length; j++) {
      let test = ar[i][j];
      let testInt = Number.parseInt(test);
      let isInteger = (!Number.isNaN(testInt));
      if (isInteger) {
        hasNumber = true;
      }
      else {
        hasLetter = true;
      }
      console.log("letter to test: " + test + " integer? " + isInteger);
      if (hasNumber && hasLetter) {
        console.log(ar[i] + " is a License Plate!");
        return ar[i];
      }
    }
  }
  return "";
}

function findLicensePlateState(ar) {
  for (var i = 0; i < ar.length; i++) {
    for (var j = 0; j < plateStates.length; j++) {
      if ((ar[i].length < 4)) continue;
      var testStr = ar[i].replace('-', '');
      if (testStr.toLowerCase() === plateStates[j].label.toLowerCase()) {
        return plateStates[j].value;
      }
    }
  }
}


export default class ModalPhotoDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: "",
      value: [],
      descript: "",
      dataUri: null,
      idealFacingMode: FACING_MODES.ENVIRONMENT,
      slides: [],
      slideAttribs: [],
      slideIdx: -1,
      defaultTag: "",
      mode: "camera"
    };

    this.imageGalleryRef = React.createRef();
    this.auth = new AuthService();
    this.apiClient = new GlasschainHttpClient();

    this.handleDescriptChange = this.handleDescriptChange.bind(this);
    this.onTakePhoto = this.onTakePhoto.bind(this);
    this.onTakePhotoAnimationDone = this.onTakePhotoAnimationDone.bind(this);
    this.onCloseDialog = this.onCloseDialog.bind(this);

    this.showCamera = this.showCamera.bind(this);
    this.toggleIdealFacingMode = this.toggleIdealFacingMode.bind(this);


    this.onSlide = this.onSlide.bind(this);
    this.onFileUpload = this.onFileUpload.bind(this);
    this.onTextRecognitionRequest = this.onTextRecognitionRequest.bind(this);
    this.onDeleteGalleryImageRequest = this.onDeleteGalleryImageRequest.bind(this);
    this.onSave = this.onSave.bind(this);

    this.onCameraStart = this.onCameraStart.bind(this);
    this.onCameraStop = this.onCameraStop.bind(this);
  }

  componentDidMount() {
    //this.setState({value: [this.props.cameraCardId]})
  }

  componentDidUpdate(prevProps) {
    //console.group("Camera PreProps");
    //console.log(prevProps);
    //console.log(this.props);
    //console.groupEnd();
    if (prevProps.cardId != this.props.cardId) {
      this.setState({
        defaultValue: [createOption(this.props.cardId)]
      })
    }

    
  }



  fileToUri = async (url) => {
    var b64 = await this.getBase64FromUrl(url);
    var result = this.b64toBlob(b64);
    return result;
  }

  getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      }
    });
  }

  b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }



onCameraStart(stream)
{
  console.log("onCameraStart:");
}

onCameraStop()
{
  console.log("onCameraStop:");
}

  showCamera() {
    this.setState({ mode: "camera" });
  }

  toggleIdealFacingMode(ev) {
    var newIdealFacingMode = (ev.target.id === "idealFacingModeUser") ? FACING_MODES.USER : FACING_MODES.ENVIRONMENT;
    this.setState({ idealFacingMode: newIdealFacingMode });
  }

  handleDescriptChange(ev) {
    if (this.state.slideIdx < 0) return;
    var newSlideAttribs = this.state.slideAttribs;
    newSlideAttribs[this.state.slideIdx].descript = ev.target.value;
    this.setState({
      descript: ev.target.value,
      slideAttribs: newSlideAttribs
    });
    //this.props.onValueChange(this.Id, ev.target.value);

  }

  handleChange = (value, actionMeta) => {
    if (this.state.slideIdx < 0) return;
    console.group("Value Changed");
    console.log(value);
    console.log(`action: ${actionMeta.action}`);
    console.groupEnd();
    var newSlideAttribs = this.state.slideAttribs;
    newSlideAttribs[this.state.slideIdx].value = value;
    this.setState(
      {
        slideAttribs: newSlideAttribs,
        value: value
      }
    );
  };

  handleInputChange = (inputValue) => {
    if (this.state.slideIdx < 0) return;
    var newSlideAttribs = this.state.slideAttribs;
    newSlideAttribs[this.state.slideIdx].inputValue = inputValue;
    this.setState(
      {
        slideAttribs: newSlideAttribs,
        inputValue: inputValue
      });
  };

  handleKeyDown = (event) => {
    const { inputValue, value } = this.state;
    if (!inputValue) return;
    if ((event.key === "Enter") || (event.key === "Tab")) {
      console.group("Value Added");
      console.log(value);

      console.groupEnd();
      if (this.state.slideAttribs.length < 0) return;
      var newSlideAttribs = this.state.slideAttribs;
      newSlideAttribs[this.state.slideIdx].inputValue = "";
      newSlideAttribs[this.state.slideIdx].value = [...value, createOption(inputValue)];
      this.setState(
        {
          slideAttribs: newSlideAttribs,
          inputValue: "",
          value: [...value, createOption(inputValue)],
        }
      );
      event.preventDefault();
    }
  };

  onDeleteGalleryImageRequest() {
    if (this.state.slideIdx === -1) return;
    var newSlides = this.state.slides;
    var newSlideAttribs = this.state.slideAttribs;
    newSlides.splice(this.state.slideIdx, 1);
    newSlideAttribs.splice(this.state.slideIdx, 1);
    var newSlideIdx = (newSlides.length === 0) ? -1 :
      (this.state.slideIdx > newSlides.length - 1) ? newSlides.length - 1 : this.state.slideIdx;
    var newMode = (newSlides.length > 0) ? "gallery" : "camera";
    this.setState({
      slides: newSlides,
      slideAttribs: newSlideAttribs,
      slideIdx: newSlideIdx,
      mode: newMode
    });
    if (newSlideIdx > -1)
    {
      // this is how you move to the proper index.
      this.imageGalleryRef.current.slideToIndex(newSlideIdx);
    }
  }

  async onTakePhoto(dataUri) {

  }

  onTakePhotoAnimationDone(dataUri) {
    //console.log("Console Slide Length: ");
    //console.log(this.state.slides.length);
    //console.info(this.state.slides);
    //var dataUrl = dataUri.toDataURL();
    if (this.state.slides.length === 0) {
      this.setState({ slideIdx: 0 });
    }
    var newSlides = this.state.slides;
    newSlides.push({ "original": dataUri, "thumbnail": dataUri });
    var newSlideIdx = newSlides.length-1;
    var newSlideAttribs = this.state.slideAttribs;
    newSlideAttribs.push({ "descript": "", "ext": "png", "inputValue": "", "value": this.state.defaultValue, "type": "dataUri" });
    //console.log("Console Slide Length: ");
    //console.log(this.state.slides.length);
    //console.info(this.state.slides);

    this.setState({
      dataUri: dataUri,
      slides: newSlides,
      slideAttribs: newSlideAttribs,
      slideIdx: newSlideIdx,
      mode: "gallery"
    });
    this.imageGalleryRef.current.slideToIndex(newSlideIdx);
  }

  async onFileUpload(e) {
    e.stopPropagation()
    console.log(e.target.files);
    var newSlides = this.state.slides;
    var newSlideAttribs = this.state.slideAttribs;
    for (var i = 0; i < e.target.files.length; i++) {
      if ((e.target) && (e.target.files[i])) {
        var imgUrl = URL.createObjectURL(e.target.files[i]);
        var ext = e.target.files[i].type.split("/")[1];
        //var dataUri = await this.getBase64FromUrl(imgUrl);

        if (this.state.slides.length === 0) {
          this.setState({ slideIdx: 0 });
        }
        newSlides.push({ "original": imgUrl, "thumbnail": imgUrl });
        newSlideAttribs.push({ "descript": "", "ext": ext, "inputValue": "", "value": this.state.defaultValue, "type": "file" });
        //newSlides.push({"original": dataUri, "thumbnail": dataUri});
        //newSlideAttribs.push({"descript": "", "inputValue": "", "value": this.state.defaultValue, "type": "dataUri"});
      }
    }
    var newSlideIdx = newSlides.length-1;
    this.setState({
      slides: newSlides,
      slideIdx: newSlideIdx,
      slideAttribs: newSlideAttribs,
      mode: "gallery"
    });
    this.imageGalleryRef.current.slideToIndex(newSlideIdx);

  }

  async onTextRecognitionRequest() {
    var sld = this.state.slides[this.state.slideIdx];
    var attrib = this.state.slideAttribs[this.state.slideIdx];
    var dataUri = (attrib.type === "file") ? await this.getBase64FromUrl(sld.original) : sld.original;
    var result = await this.apiClient.fetchTextRecognition(dataUri); //this.state.dataUri);
    if (!result) return;
    var newValue = this.state.value;
    for (var i = 1; i < result.length; i++) {
      // we start with one because the first is an aggregate. I'll need to rip through and try to identify values
      newValue.push(createOption(result[i]));
    }
    if ((this.props.cardId === 'tractorlicense') || (this.props.cardId === 'tractorstate')) {
      this.props.onValueChange('tractorstate', findLicensePlateState(result));
      this.props.onValueChange('tractorlicense', findTruckLicensePlate(result));
    }
    if ((this.props.cardId === 'trailerlicense') || (this.props.cardId === 'trailerstate')) {
      this.props.onValueChange('trailerstate', findLicensePlateState(result));
      this.props.onValueChange('trailerlicense', findTruckLicensePlate(result));
    }
    this.setState({ value: newValue })
    console.log(result);
  }



  async onSave() {
    for (var i = 0; i < this.state.slides.length; i++) {
      var sld = this.state.slides[i];
      var attrib = this.state.slideAttribs[i];
      var dataUri = (attrib.type === "file") ? await this.getBase64FromUrl(sld.original) : sld.original;
      var ky = "img-" + uuidv1();
      var val = { id: ky, ext: attrib.ext, descript: attrib.descript, tags: attrib.value, dataUri: dataUri };
      //this.auth.setKey(ky, JSON.stringify(val));
      this.props.onSaveUserImage(val);
    }
    this.setState({
      mode: "camera"
    })
    this.onCloseDialog();
  }

  onSlide(e) {
    console.log("onSlide: ");
    console.info(e);
    this.setState({ slideIdx: e });
    if (this.state.slideIdx < 0) {
      this.setState(
        { descript: "", value: [], inputValue: "" });
    }
    else {
      console.log("Slide Attribs: ");
      console.info(this.state.slideAttribs[e]);
      this.setState(
        {
          descript: this.state.slideAttribs[e].descript,
          value: this.state.slideAttribs[e].value,
          inputValue: this.state.slideAttribs[e].inputValue
        }
      );
    }


  }

  onCloseDialog() {
    this.setState({
      dataUri: null,
      descript: "",
      value: [],
      inputValue: "",
      slides: [],
      slideAttribs: [],
      slideIdx: -1,
      mode: "gallery"
    });
    this.props.onRequestCameraDialogClose();
  }

  render() {

    const { inputValue, value } = this.state;

    const isFullScreen = true;

    // const cameraOptions = (this.state.mode === "camera") ? 
    //   <span id="cam-facing-buttongroup">
    //     <Button id="idealFacingModeEnvironment" variant="secondary" onClick={this.toggleIdealFacingMode}>
    //       <FontAwesomeIcon id="IdealFacingModeEnvironment" icon={faImage} size="2x" color={(this.state.idealFacingMode===FACING_MODES.ENVIRONMENT) ? "lightgreen" : "lightgrey"}/>
    //     </Button>
    //     <Button id="idealFacingModeUser" variant="secondary" onClick={this.toggleIdealFacingMode}>
    //       <FontAwesomeIcon id="idealFacingModeUser" icon={faPortrait} color={(this.state.idealFacingMode===FACING_MODES.USER) ? "lightgreen" : "lightgrey"} size="2x"/> &nbsp;&nbsp;
    //     </Button>
    //   </span>
    // : <span/>

    const cameraOptions = <span />

    const textRecognition = ((this.state.dataUri) && (this.state.mode === "gallery")) ?
      <span>
        <Button id="textRecognitionRequest" onClick={this.onTextRecognitionRequest}>
          <FontAwesomeIcon id="textRecognitionRequest" icon={faSpellCheck} />
          ~Scan Text
        </Button>
      </span>
      : <span />;

    const removeSlide = (this.state.slideIdx !== -1) ?
      <span>
        <Button id="deleteImageRequest" onClick={this.onDeleteGalleryImageRequest}>
          <FontAwesomeIcon id="textRecognitionRequest" icon={faTrashAlt} />
          ~Remove Pic
        </Button>
      </span> : <span />


    const cameraWin = (this.state.mode === "camera") ?
      <Row>
        <Col>
          <Camera
            idealFacingMode={this.state.idealFacingMode}
            isImageMirror={false}
            isFullScreen={isFullScreen}
            onTakePhoto={(dataUri) => { this.onTakePhoto(dataUri); }}
            onTakePhotoAnimationDone={this.onTakePhotoAnimationDone}
            onCameraStart={(stream) => this.onCameraStart(stream)}
            onCameraStop={this.onCameraStop}
          />
        </Col>
      </Row> :
      <span />


    const galleryWin = (this.state.mode === "gallery") ?
      <Row>
        <ImageGallery
          ref={this.imageGalleryRef}
          lazyLoad={true}
          items={this.state.slides}
          showBullets={false}
          showIndex={false}
          showThumbnails={true}
          thumbnailPosition="left"
          showFullscreenButton={false}
          showPlayButton={false}
          
          onSlide={(e) => this.onSlide(e)}
          onErrorImageURL={ErrorImg}
        />
      </Row> :
      <span />;


    const galleryOptions = (this.state.mode === "gallery") ?
      <div>
        <Row style={{ marginTop: "5px" }}>
          <Col>
            <FormControl id="photoDescriptTextBox"
              autoFocus
              textAlign="left"
              placeholder="Comments..."
              aria-describedby="basic-addon2"
              name="imageDescription"
              value={this.state.descript}
              onChange={(e) => this.handleDescriptChange(e)}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: "5px" }}>
          <Col>
            <CreatableSelect
              components={components}
              inputValue={inputValue}
              isClearable
              isMulti
              menuIsOpen={false}
              onChange={this.handleChange}
              onInputChange={this.handleInputChange}
              onKeyDown={this.handleKeyDown}
              placeholder="Optionally type a tag and press enter..."
              value={value}
            />
          </Col>

        </Row>
        <Row style={{ marginTop: "5px", justifyContent: "space-evenly" }}>
          {textRecognition}
          {removeSlide}
        </Row>
      </div> :
      <span />

    var showCamButton = "initial" ? this.state.mode === "camera" : "none"

    return (
      <Modal
        show={this.props.show}
        onHide={this.onCloseDialog}
      >
        <Modal.Header closeButton>
          <Modal.Title>Inspection Photo - {this.props.cardId}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>

            {cameraWin}
            {galleryWin}
            {galleryOptions}

          </Container>
        </Modal.Body>
        <Modal.Footer id="cam-modal-footer">

          {/* {cameraOptions} */}
          {/* {removeSlide} */}
          {/* {textRecognition} */}

          <span>
            <Button id="cam-camera-button" onClick={this.showCamera} disabled={this.state.mode === "camera"}><FontAwesomeIcon id="cameraMode" icon={faCameraRetro} /> ~Take Pic </Button>
            <Button id="cam-upload-button">
              <input type="file" id="files" style={{ display: "none" }} accept="image/*" multiple="true" onChange={(e) => this.onFileUpload(e)}></input>
              <label classname={"icon-circle"} for="files"><FontAwesomeIcon id="fileMode" icon={faUpload} />~Upload</label>
            </Button>
          </span>

          <span id="cam-save-buttongroup">
            <Button id="cam-save-button" variant="secondary" disabled={(this.state.slides.length < 1)} onClick={(ev) => this.onSave()}>Save</Button>
            <Button id="cam-cancel-button" variant="secondary" onClick={this.onCloseDialog}>Cancel</Button>
          </span>

        </Modal.Footer>
      </Modal>
    );
  }
}
