import React, { Component } from "react";
import {
} from "react-bootstrap";
import "../../assets/css/attrib-cards.css";
import {toCardId} from "./AttribCardUtils.js";
import moment from "moment";



export default class AttribReadOnlyCard extends Component {
  constructor(props) {
    super(props);

    this.Id = toCardId(this.props.attribTitle);

    this.state = {
      cardState: (this.props.valueBag[this.Id]) ? "touched" : "untouched",
      cardValue: this.props.valueBag[this.Id]
    };


    this.renderState = this.renderState.bind(this);
    this.renderUntouchedState = this.renderUntouchedState.bind(this);
    this.onLoseFocus = this.onLoseFocus.bind(this);
    this.onCardClick = this.onCardClick.bind(this);
    this.formatValue = this.formatValue.bind(this);
    this.cardLabel= this.cardLabel.bind(this);
  }

  componentDidUpdate(prevProps, prevState){
    let oldVal = prevState.cardValue;
    let newVal = this.props.valueBag[this.Id];
    //console.log("Id: " + this.Id + " Old: " + oldVal + " New: " + newVal);
    if (oldVal !== newVal){
      this.setState({
        cardValue: newVal
      });
      if (this.state.cardState !== "active"){
        this.setState({cardState: (newVal) ? "touched" : "untouched"})
      }
    }
  }


  onLoseFocus() {
    var newCardState = (this.state.cardValue) ? "touched" : "untouched";
    this.setState({ cardState: newCardState });
  }

  onCardClick() {
    this.props.onToggleCard(this);
  }

  cardLabel(){
    return (this.props.label) ? this.props.label : this.props.attribTitle.toUpperCase()
  }

  renderUntouchedState() {

    return (
      <div className={this.props.theme + 'card-template'} onClick={this.onCardClick}>
        {this.cardLabel()}
      </div>
    );
  }
  
  renderTouchedState() {

    const classNameVal = (this.props.theme) ? 
      this.props.theme + 'card-template'
      : "nonactivecard touched-readonly shadow7";

    return (
      <div
        className= {classNameVal}
        onClick={this.onCardClick}
      >
        <div>
          {this.cardLabel()}&nbsp;
          <span style={{color: "black", fontSize: "16px"}}>
            {this.formatValue(this.state.cardValue)}
          </span>
      </div>
    </div>
    );
  }

  formatValue(val){
    if (!this.props.display) return val;
    if (this.props.display.type==="datetime"){
      return moment(val).format(this.props.display.format);
    }
  }

  renderState() {
    switch (this.state.cardState) {
      case "untouched":
        return this.renderUntouchedState();
      case "touched":
        return this.renderTouchedState();
    }
  }

  render() {
    return this.renderState();
  }
}
