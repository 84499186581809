import moment from "moment";

export default class ObsSearchParams{
    constructor(params){
        this.maxCount = "";
        this.sortDesc = "";
        this.id = "";
        this.schemaId="";
        this.inspector = "";
        this.location = "";
        this.itemNbr =  "";
        this.gtin =  "";
        this.ranchNbr =  "";
        this.blockId = "";
        this.obsDateStart = ""; // in iso date format e.g. 2019-07-30
        this.obsDateEnd = "";
        this.status =  ""; // posted, void, abandoned
        this.scoreReason = "";
        this.grade = "";

        this.queryString = "";

        this.toQueryString = this.toQueryString.bind(this);
        this.addToQueryString = this.addToQueryString.bind(this);
    }

    addToQueryString(p, name){
        if (!p) return; // if null, then ignore
        if (p==="") return;
        if (p.toUpperCase()==="ALL")return;
        if (p == null) return;
        if (p!==""){
            let qPiece = name + "=" + p;
            if (this.queryString !==""){
                this.queryString = this.queryString + "&" + qPiece;
            }
            else {
                this.queryString = qPiece;
            }
        }
    }

    toQueryString(){
        // there are definitely better ways!
        this.addToQueryString(this.maxCount, "maxcount");
        this.addToQueryString(this.sortDesc, "sortdesc");
        this.addToQueryString(this.id, "id");
        this.addToQueryString(this.schemaId, "schemaid");
        this.addToQueryString(this.inspector, "inspector");
        this.addToQueryString(this.location, "location");

        this.addToQueryString(this.itemNbr, "itemnbr");
        this.addToQueryString(this.gtin, "gtin");
        this.addToQueryString(this.ranchNbr, "ranchnbr");
        this.addToQueryString(this.blockId, "blockid");
        this.addToQueryString(this.obsDateStart, "startdate");
        this.addToQueryString(this.obsDateEnd, "enddate");

        this.addToQueryString(this.status, "status");
        this.addToQueryString(this.scoreReason, "scorereason");
        this.addToQueryString(this.grade, "grade");
        return this.queryString;
    }


    asQueryString(){
        var obj = {
            "maxcount": this.maxCount,
            "schemaid": this.schemaId,
            "id": this.id,
            "gcid": this.gcid,
            "location": this.location,
            "inspector": this.inspector,
            "startdate": moment(this.startDate).format(),
            "enddate": moment(this.endDate).format(),
            "grade": this.grade,
            "ranchnbr": this.ranch
        }
        var allKeysWithValues = [];
        Object.keys(obj).forEach(function(key) {
            if (obj[key] === undefined) return;
            if (obj[key] === "" || obj[key]==="ALL" || obj[key]==="all") return;
            allKeysWithValues.push(key + '=' +  obj[key]);
        });
          var result = allKeysWithValues.join('&');
          //console.log("searchParams Url: " + result);
          return result;
    }


    validate(){
        return true;
    }
}