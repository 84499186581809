import React, { Component } from "react";
import {Form,InputGroup,} from "react-bootstrap";
import "../../assets/css/attrib-cards.css";
import {toCardId, toCardTitle, removeArrayValue} from "./AttribCardUtils.js";
import CreatableSelect from "react-select/creatable";


// const components = {
//   // DropdownIndicator: null,
// };

const createOption = (label) => ({
  label,
  value: label,
});


export default class AttribCheckboxCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardState: "untouched",
      enabled: false,
      valid: true,
      inputValue: "",
      value: [],
      descript: ""
    };

    this.Id = toCardId(this.props.attribTitle);
    this.tags = this.props.attribTags ? this.props.attribTags : [""];

    this.renderState = this.renderState.bind(this);
    this.renderUntouchedState = this.renderUntouchedState.bind(this);
    this.renderActiveState = this.renderActiveState.bind(this);
    this.onCardClick = this.onCardClick.bind(this);
    this.onLoseFocus = this.onLoseFocus.bind(this);
    this.onToggle = this.onToggle.bind(this);
    this.onTagToggle = this.onTagToggle.bind(this);
    this.toTagId = this.toTagId.bind(this);
    this.onValidityCheck = this.onValidityCheck.bind(this);
  }

  onValidityCheck(isOn, tagsOn){
    if ((this.props.validation) && (this.props.validation.requiresSubcheck)) {
      var isValid = (((isOn) && (tagsOn.length>0)) || (!isOn));
      if (isValid !== this.state.valid){
        // validity state has changed!
        this.setState({valid: isValid});
        this.props.onValidationChange(this.Id, isValid, this.props.validation.requiredMessage);
      }
    }
  }

  toTagId(tag) {
    return this.Id + "_" + tag;
  }

  // toggle the entire pre-inspect reject
  onToggle(ev) {
    console.log(ev.target.checked);
    let isOn = ev.target.checked;
    let newTagsOn = this.state.value;
    if (!isOn){
      newTagsOn.map((tag) => this.props.onValueRemove(this.toTagId(tag.value)));
      newTagsOn = []; // clear all tags
    }
    this.onValidityCheck(isOn, newTagsOn);
    this.setState({ 
      enabled: isOn,
      value: newTagsOn,
     });
    this.props.onValueChange(this.Id, ev.target.checked);
  }

  onTagToggle(ev) {
    console.log(ev.target.name + " " + ev.target.checked);
    var newTagsOn = this.state.tagsOn;
    if (ev.target.checked){
      newTagsOn.push(ev.target.name);
      this.props.onValueChange(this.toTagId(ev.target.name), true);
    }
    else {
      newTagsOn = removeArrayValue(newTagsOn, ev.target.name);
      this.props.onValueRemove(this.toTagId(ev.target.name));
    }
    this.onValidityCheck(this.state.enabled, newTagsOn);
    this.setState({tagsOn: newTagsOn});
    
  }

  onLoseFocus() {
    var newCardState = this.state.enabled ? "touched" : "untouched";
    this.setState({ cardState: newCardState });
  }

  onCardClick(ev) {
    var newState = "untouched";
    if (this.state.cardState === "active"){
      newState = this.state.enabled ? "touched" : "untouched";
    } else {
      newState = "active";
    }
    this.setState({ cardState: newState });
    this.props.onToggleCard(this);
  }

  renderUntouchedState() {
    return (
      <div className="nonactivecard card-template shadow6 preinspect" onClick={this.onCardClick}>
        {this.props.attribTitle}
      </div>
    );
  }

  handleChange = (value, actionMeta) => {
    if (actionMeta.action==="remove-value"){
      let tagValue = actionMeta.removedValue.value;
      this.props.onValueRemove(this.toTagId(tagValue));
    }
    this.onValidityCheck(this.state.enabled, value);
    this.setState({ value });
    if(value){
      this.props.onValueChange(this.toTagId(value[value.length-1].value), true);
    };

  };

  handleInputChange = (inputValue) => {
    this.setState({ inputValue });
  };


  handleKeyDown = (event) => {
    const { inputValue, value } = this.state;
    if (!inputValue) return;
    if (!value) return;

    switch (event.key) {
      case "Enter":
      case "Tab":
          this.setState({
            inputValue: "",
            value: [...value, createOption(inputValue)],
          });
        event.preventDefault();
        this.props.onValueChange(this.toTagId(inputValue), true);
        this.onValidityCheck(this.state.enabled, [...value, createOption(inputValue)]);
    }
  };

  renderActiveState() {
    const { inputValue, value } = this.state;
    const formattedOptions = this.props.attribTags.map((opt) =>{
      return createOption(opt);
    })

    return (
      <div
        className="activecard preinspect shadow6 card-template"
      >
        <div onClick={this.onCardClick}>
          {this.props.attribTitle}
        </div>
        <div>
          <InputGroup className="preinspect-input">
            <Form.Check 
              style={{transform: "scale(1.2)", padding: "10px"}}
              type="checkbox"
              checked={this.state.enabled}
              name={this.props.attribTitle}
              onChange={(ev) => {
                this.onToggle(ev);
              }}
            />
          </InputGroup>
        </div>
        <div>
          <InputGroup className="preinspect-input">
            {/* {this.props.attribTags.map((tag) => (
              <div key={`default-${tag}`} className="mb-3">
                <Form.Check
                  type="checkbox"
                  name={`${tag}`}
                  id={`${this.toTagId(tag)}`}
                  label={`${tag}`}
                  checked={this.state.tagsOn.includes(tag)}
                  disabled={!this.state.enabled}
                  onChange={(ev) => {this.onTagToggle(ev)}}
                  style={{color: "white"}}
                />
              </div>
            ))} */}
            <CreatableSelect
                  // components={components}
                  className = "dropdown-inspect"
                  inputValue={inputValue}
                  options={formattedOptions}
                  isClearable
                  isMulti
                  isDisabled={!this.state.enabled}
                  // menuIsOpen={false}
                  onChange={this.handleChange}
                  onInputChange={this.handleInputChange}
                  onKeyDown={this.handleKeyDown}
                  placeholder="Select or optionally type a tag and press enter..."
                  value={value}
                />
          </InputGroup>
        </div>
      </div>
    );
  }

  renderTouchedState() {
    return (
      <div
        className="nonactivecard"
        onClick={this.onCardClick}
        style={{ backgroundColor: "lightgreen" }}
      >
        <div style={{ fontSize: "20px" }}>
          {this.props.attribTitle.toUpperCase()}
        </div>
        {this.state.value.map((tag, index) => (
          <div
            style={{
              textAlign: "center",
              fontSize: "20px",
              fontColor: "purple",
            }}
          >
            {tag.label}
          </div>
        ))}
      </div>
    );
  }

  renderState() {
    switch (this.state.cardState) {
      case "untouched":
        return this.renderUntouchedState();
      case "touched":
        return this.renderTouchedState();
      default:
        return this.renderActiveState();
    }
  }

  render() {
    return this.renderState();
  }
}
