import React, { Component } from "react";
import {FormControl,Button,Row,Container,InputGroup} from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBinoculars, faIcicles } from "@fortawesome/free-solid-svg-icons";

import AuthService from "../../AuthService/AuthService.js";

export default class SchemaPanel extends Component {
  constructor(props) {
    super(props);
    this.auth = new AuthService();

    this.state = {
      gcid: this.auth.getGcid(),
      inspector: this.auth.getUser(),
      location: this.auth.getLocation(),
      hasSuspendedObs: false
    };
    
    this.onSchemaChange = this.onSchemaChange.bind(this);
    this.onSuspendedInspectClick = this.onSuspendedInspectClick.bind(this);
  }

  async onSchemaChange(ev) {
    this.props.onSchemaChange(ev.target.value);
  }

  onSuspendedInspectClick(e)
  {
    this.props.onRequestRecallSuspendedObservation(this.props.schemaId);
  }

  render() {

    var hasSuspendedObs = (this.auth.hasLocalSuspendedObservation(this.state.gcid, this.props.schemaId, this.state.inspector));
    const suspendedButton = (hasSuspendedObs) ? 
      <span>
        &nbsp;&nbsp;
        <Button id="suspendedInspectButton" onClick={(e) => this.onSuspendedInspectClick(e)}>
          <FontAwesomeIcon icon={faIcicles} color="white" />
          &nbsp;&nbsp; Resume
        </Button>
        </span>
  : <span/>

    return (
      <div class="App Fade">
        <Container fluid style={{marginLeft: "5%"}}>
          <Row>
            <span style={{color:'white'}}>Inspector: {this.state.inspector}&nbsp;&nbsp; Location:{" "}{this.state.location}</span>
          </Row>
          <Row>
              <InputGroup >
                <InputGroup.Prepend>
                  <InputGroup.Text id="basic-addon1">Schema</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  id="schemalookup"
                  as="select"
                  placeholder="schema"
                  type="dropdown"
                  autoComplete="on"
                  value={this.props.schemaId}
                  onChange={(ev) => this.onSchemaChange(ev)}
                  name="schemaSelection"
                >
                  {this.props.orgConfig.schemaList.map((sch) => (
                    <option>{sch}</option>
                  ))}
                </FormControl>
                &nbsp;&nbsp;
                <Button id="newInspectButton" onClick={this.props.onRequestNewObservation}>
                  <FontAwesomeIcon icon={faBinoculars} color="white" />
                  &nbsp;&nbsp; Add
                </Button>
                {suspendedButton}
              </InputGroup>
              
          </Row>
        </Container>
      </div>
    );
  }
}
