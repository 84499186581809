import {toCardId, toCardTitle} from "../schemaCards/AttribCardUtils.js";

// this class "shapes" observation values bag into an inspection constructed like the schema
export default class DefaultObsShaper {


  shapeUnderInspectionUpdate(schema, values, updatedDate){
    var ouiCatNd = this.getCategory(schema, "oui");
    // we flatten this category
    var underInspection = this.getFlatCategoryCardValues(ouiCatNd, values);
    underInspection.UpdatedDate = updatedDate;
    return underInspection;
  }

  shape(schema, values) {
    // shape the observation data to the schema. We keep values in a flat dictionary for speed. This also allows us to
    // add metadata and scoring information.
    var result = {};
    // header
    result.schema = schema.schemaInfo;
    // underinspection
    var ouiCatNd = this.getCategory(schema, "oui");
    // we flatten this category
    result.UnderInspection = this.getFlatCategoryCardValues(ouiCatNd, values);
    var envCatNd = this.getCategory(schema, "notes");
    result.Environment = this.getFlatCategoryCardValues(envCatNd, values);
    // defects
    // preinspect
    if (values["preinspectreject"]){
        // get preinspect reject tags
        let preInspectRejectTags = this.getPreInspectRejectTags(values);
        result.PreInspectStatus = {PreInspectReject: true, Tags: preInspectRejectTags};
        result.Raw = values;
        return result;
    }
    // inspection defects
    if (this.getCategory(schema, "defects")!==""){
    var defectCatNd = this.getCategory(schema, "defects");
    // Count
    result.Count = this.getCountCardValues(defectCatNd, values);
    result.Defects = this.getFlatGroupsCardValues(defectCatNd, values);
    result.Raw = values;  // stored in case we need to do an update. 
    }
    else {
      if (this.getCategory(schema, "inspection")){
        var inspectCatNd = this.getCategory(schema, "inspection");
        result.Inspection = this.getFlatCategoryCardValues(inspectCatNd, values);
        result.Raw = values;  // stored in case we need to do an update.
      }
    }
    return result;
  }


  getCategory(schema, categoryId) {
    for (var i = 0; i < schema.categories.length; i++) {
      if (schema.categories[i].id === categoryId) {
        return schema.categories[i];
      }
    }
    return "";
  }

  getPreInspectRejectTags(values){
      const preInspectRejectPrefix = "preinspectreject_";
      var result = [];
      for(var key in values){
          if (key.startsWith(preInspectRejectPrefix)){
              let tag = key;
            result.push(tag.replace(preInspectRejectPrefix, ''));
          }
      }
      return result;
  }

  getFlatCategoryCardValues(categoryNd, values) {
    var flatResult = {};
    for (var j = 0; j < categoryNd.groups.length; j++) {
      for (var k = 0; k < categoryNd.groups[j].cards.length; k++) {
        var card = categoryNd.groups[j].cards[k];
        let cardId = toCardId(card.title);
        if (values[cardId]) {
          let cardTitle = card.saveAlias
            ? card.saveAlias
            : toCardTitle(card.title);
          flatResult[cardTitle] = values[cardId];
        }
      }
    }

    return flatResult;
  }

  getCountCardValues(categoryNd, values){
      // count is a special type of card. 
      var flatResult = {};
      for (var j = 0; j < categoryNd.groups.length; j++) {
        var group = categoryNd.groups[j];
        if (group.groupTitle==="Count") continue;
        for (var k = 0; k < group.cards.length; k++) {
          var card = group.cards[k];
            if (card.fieldType==="containerqtyproduct"){
                flatResult[toCardTitle(card.containerCountTitle)] = values[toCardId(card.containerCountTitle)];
                flatResult[toCardTitle(card.containerQtyTitle)] = values[toCardId(card.containerQtyTitle)];
                flatResult[toCardTitle(card.unitQtyTitle)] = values[toCardId(card.unitQtyTitle)];
                return flatResult;
            }
        }
    }
    return flatResult;
  }

  getFlatGroupsCardValues(categoryNd, values) {
    var flatResult = {};
    for (var j = 0; j < categoryNd.groups.length; j++) {
      var group = categoryNd.groups[j];
      var cardsResult = {};
      for (var k = 0; k < group.cards.length; k++) {
        var card = group.cards[k];
        if (card.fieldType==="containerqtyproduct") continue;
        let cardId = toCardId(card.title);
        let cardTitle = card.saveAlias? card.saveAlias: toCardTitle(card.title);
        if (card.variants) {
          var cardVariantResult = {};
          let cardTotal = 0;
          for (var x = 0; x < card.variants.length; x++) {
            let variant = card.variants[x];
            let cardVariantId = cardId + variant;
            if (values[cardVariantId]) {
              cardVariantResult[cardTitle + "-" + variant] = values[cardVariantId];
              cardTotal += values[cardVariantId];
            }
          }
          if (cardTotal > 0) {
            cardsResult[cardTitle] = cardTotal;
            cardsResult[cardTitle + "-Degrees"] = cardVariantResult;
          }
        } else {
          if (values[cardId]) {
            let cardTitle = card.saveAlias
              ? card.saveAlias
              : toCardTitle(card.title);
            cardsResult[cardTitle] = values[cardId];
          }
        }
      }
      let groupTitle = group.saveAlias ? group.saveAlias : group.groupTitle;
      flatResult[groupTitle] = cardsResult;
    }
    return flatResult;
  }
}
