import React, { Component } from "react";
import {Button, Modal} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

export default class ModalErrorsDialog extends Component {

  render() {
    return (
          <Modal show={this.props.show} onHide={this.props.onRequestErrorsDialogClose}>
            <Modal.Header closeButton>
              <Modal.Title>{this.props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ul>
              {Object.keys(this.props.errorBag).map((key, index) => ( 
                <li key={key}>
                  {key} - {this.props.errorBag[key].message}
                </li>
                ))
              }
              </ul>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.props.onRequestErrorsDialogClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

    );
  }
}
