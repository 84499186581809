import UsdaGenericScorer from "./USDA/UsdaGenericScorer.js"
import MultiplierAcceleratorScorer from "./MultAccel/MultiplierAcceleratorScorer.js";
import PassFailGenericScorer from "./PassFail/PassFailGenericScorer.js";
import GradeConditionalScorer from "./Conditional/GradeConditionalScorer.js";
import NonScorer from "./Other/NonScorer.js";

export default class ScoreManager{
    constructor(){

    }


    score(schema, values){
        var scorerId = schema.scoring.scorer;
        var scorer;
        switch(scorerId){
            case "multaccel-generic": {
                scorer = new MultiplierAcceleratorScorer();
                break;
            }
            case "passfail-generic": 
                scorer = new PassFailGenericScorer();
                break;

            case "grade-conditional": {
                scorer = new GradeConditionalScorer();
                break;
            }
            case "nonscorer" : {
                scorer = new NonScorer();
                break;
            }
            default: {
                scorer = new UsdaGenericScorer();
            }
        }
        var scoreResult = scorer.score(schema, values);
        return scoreResult;
    }








}