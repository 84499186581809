import React from "react";
import { pdf } from '@react-pdf/renderer';
import ObsNewDocument from "../Pdf/ObservationPdfVrsn2";
import BlobStorageService from "../../AzureService/BlobStorageService.js";
import AppConfig from "../../AppConfig/AppConfig.js";
import DbDocService from "../../DataService/IDbDocService.js";



export default class PdfObservation {
    constructor(obs, schema){
        console.log("constructor obs: ");
        console.log(obs);
        this.observation = obs || null;
        this.schema = schema || null;
        this.appConfig = new AppConfig();
        this.localDbDocService = new DbDocService();

    }

    toPdfFileName(schemaId, observationId)
    {
        return schemaId + "-" + observationId;
    }

    async writeToCloudStorage(){
        console.log("building blob...");
        console.log(this.observation);
        console.info(this.schema);
        console.info(this.information);
        const blob = await pdf(<ObsNewDocument observation={this.observation} schema={this.schema} />).toBlob();   
        var blobStorageService = new BlobStorageService();
        console.log("uploading blob...");
        await blobStorageService.uploadPdf(this.gcid, blob, this.toPdfFileName(this.observation.data.schema.id, this.observation.identifiers.id));      
        //await this.localDbDocService.AddDoc(blob, "obs", this.observation.identifiers.id);
        console.log("uploaded blob...");
    }

    getLinkFromObservation(){
        return this.appConfig.getObsImageHostAddr(this.observation.data.Raw.gcid, "user") + 
            this.toPdfFileName(this.observation.data.schema.id,this.observation.identifiers.id) + ".pdf";
    }

    // use this if you already have this info and don't want to initialize the entire object
    static getObservationLink(gcid, obsId){
        return this.appConfig.getObsImageHostAddr(gcid, "user") + obsId + ".pdf";
    }


}