import React, {Component} from "react";
import {Modal, Button} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

import AppConfig from "../AppConfig/AppConfig.js";

import ImageNotFound from "../../assets/images/ImageNotFound.png";






export default class ModalSlideDialog extends Component {
    constructor(props){
        super(props);

        this.appConfig = new AppConfig();
    }





    render() {

        // TBD: get Url source
        // Convert to ImageGallery format

        const baseSlideUrl = this.appConfig.getObsImageHostAddr(this.props.gcid, this.props.slideType);
        
        const slides  = this.props.slideInfo.slides.map((slide, index) => ({
            original: baseSlideUrl + slide.imgFileName,
            originalTitle: slide.title,
            originalAlt: slide.caption,
            description: slide.title + "-" + slide.caption
          }));
          //console.group("slides:");
          //console.log(this.props.slideInfo);
          //console.log(slides);
          //console.groupEnd();

        return (
            <Modal show={this.props.showSlides} onHide={this.props.onRequestCriteriaSlidesClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                {this.props.slideInfo.title}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ImageGallery
                lazyLoad={true}
                items={slides}
                showBullets={true}
                showIndex={true}
                showThumbnails={false}
                onErrorImageURL={ImageNotFound}
              //onSlide={this.onSlide.bind(this)}
              ></ImageGallery>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.props.onRequestCriteriaSlidesClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

        );
      }


}