import React, { Component } from "react";
import { Button, FormControl, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt} from "@fortawesome/free-solid-svg-icons";
import "../../assets/css/attrib-cards.css";
import AppConfig from "../AppConfig/AppConfig.js";
import AuthService from "../AuthService/AuthService.js";
import GlasschainHttpClient from "../ApiService/GlasschainHttpClient.js";
import {toCardId} from "./AttribCardUtils.js";

Object.byString = function(o, s) {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, '');           // strip a leading dot
    var a = s.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
        var k = a[i];
        if (k in o) {
            o = o[k];
        } else {
            return;
        }
    }
    return o;
}

export default class AttribApiLookupMapCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardState: "untouched",
      cardValue: null,
      lookupButtonEnabled: false,
      inLookup: false,
      lookupError: false,
      lookupErrMsg: ""
    };
    this.appConfig = new AppConfig();
    this.auth = new AuthService();
    this.apiClient = new GlasschainHttpClient();
    this.gcid = this.auth.getGcid();
    this.Id = toCardId(this.props.attribTitle);

    this.renderState = this.renderState.bind(this);
    this.renderUntouchedState = this.renderUntouchedState.bind(this);
    this.renderActiveState = this.renderActiveState.bind(this);
    this.onCardClick = this.onCardClick.bind(this);
    this.onLoseFocus = this.onLoseFocus.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.mapEntityFields = this.mapEntityFields.bind(this);
    this.onLookupButtonClick = this.onLookupButtonClick.bind(this);

    this.parseType = this.props.parseType;
    this.fieldMap = this.props.fieldMap;
  }

  componentDidUpdate(prevProps, prevState){
    let oldVal = prevState.cardValue;
    let newVal = this.props.valueBag[this.Id];
    //console.log("Id: " + this.Id + " Old: " + oldVal + " New: " + newVal);
    if (oldVal !== newVal){
      this.setState({
        cardValue: newVal
      });
      if (this.state.cardState !== "active"){
        this.setState({cardState: (newVal) ? "touched" : "untouched"})
      }
    }
  }

  async onLookupButtonClick() {
    // only works if we have a value to look up!
    if (!this.state.cardValue || this.state.cardValue === "") return;
    var val = this.state.cardValue;
    // confirm they have access to this adapter and role
    if (!this.appConfig.hasAdapterProxyRole(this.gcid, this.props.proxyClientRole)) {
      this.setState({ palletTagButtonEnabled: false });
      return;
    }
    this.setState({inLookup: true});
    let hasEntityResult = await this.apiClient.hasProxyEntity(
      this.gcid,
      this.props.entityExistsApiUrl,
      val
    );
    console.log("has entity? " + hasEntityResult);
    if (!hasEntityResult) {
      this.setState({ 
        lookupErrMsg: "Unable to locate " + val,
        lookupError: true,
        inLookup: false,
        lookupButtonEnabled: true
       });
    } else {
      var entityResult = await this.apiClient.fetchProxyEntity(
        this.gcid,
        this.props.entityLookupApiUrl,
        val
      );
      var entity = entityResult;
      // now map entity values to onValueChange for each mapped field
      this.mapEntityFields(entity);
      this.setState({
        lookupErrMsg: "",
        lookupError: false,
        lookupButtonEnabled: true,
        inLookup: false
      });
    }
  }

  isRepressedOverwrite(key, valueBag, repressedOverwrites)
  {
    if (!repressedOverwrites) return false;
    var hasKey = repressedOverwrites.includes(key);
    if (!hasKey) return false;
    var keyIsInValueBag = (key in valueBag);
    if (!keyIsInValueBag) return false;
    if ((valueBag[key].trim() === "") || (valueBag[key] == null)) return false;
    return true;
  }

  mapEntityFields(entity) {
    console.log("returned entity: ");
    console.log(entity);
    for (var key in this.props.fieldMap) {
      let entityField = this.props.fieldMap[key];
      var val = Object.byString(entity, entityField);
      console.log("attempting to get val for key " + key + " from entity field " + entityField);
      if (val) {

        if (!this.isRepressedOverwrite(key, this.props.valueBag, this.props.repressedOverwrites))
        {
          console.log("attempting to update " + key + " with " + val);
          this.props.onValueChange(key, val);
        }
      }
    }
  }

  onValueChange(ev) {
    var newLookupButtonEnabled = (ev.target.value !== "");
    this.setState({ 
        inLookup: false,
        lookupError: false,
        lookupErrMsg: "",
        cardValue: ev.target.value,
        lookupButtonEnabled: newLookupButtonEnabled
     });
    this.props.onValueChange(this.Id, ev.target.value);
  }

  onLoseFocus() {
    var newCardState = this.state.cardValue ? "touched" : "untouched";
    this.setState({ cardState: newCardState });
  }

  onCardClick(ev) {
    var newState = "untouched";
    if (this.state.cardState === "active") {
      newState = this.state.cardValue ? "touched" : "untouched";
    } else {
      newState = "active";
    }
    this.setState({ cardState: newState });
    this.props.onToggleCard(this);
  }

  renderUntouchedState() {
    return (
      <div
        className="nonactivecard card-template shadow6"
        onClick={this.onCardClick}
      >
        {this.props.attribTitle}
      </div>
    );
  }

  renderActiveState() {
    return (
      <div className="activecard shadow6 card-template textcard-input">
        <div onClick={this.onCardClick}>
          {this.props.attribTitle.toLowerCase()}
        </div>
        <InputGroup size="sm" style={{ padding: "2px" }}>
          <FormControl
            autoFocus
            plainText
            textAlign="center"
            placeholder={this.props.attribTitle}
            aria-describedby="basic-addon2"
            name={this.Id}
            value={this.state.cardValue}
            onChange={this.onValueChange}
            style={{ marginLeft: "20px" }}
          />
          <Button
            disabled={!this.state.lookupButtonEnabled}
            onClick={this.onLookupButtonClick}
            fontColor={(this.state.lookupError) ? "yellow": "white"}
          >
            {(this.state.inLookup) ? <FontAwesomeIcon icon={faSyncAlt} color="lightgreen" spin={true}/> : <span/>}
            &nbsp;Lookup
          </Button>
          <div align="center" style={{fontColor: "yellow"}}>
            {this.state.lookupErrMsg}
          </div>
        </InputGroup>
      </div>
    );
  }

  renderTouchedState() {
    return (
      <div
        className="nonactivecard card-template touchedcard shadow7"
        onClick={this.onCardClick}
        style={{ backgroundColor: "lightgreen" }}
      >
        <div style={{ fontSize: "20px" }}>
          {this.props.attribTitle.toUpperCase()}
        </div>
        <div
          style={{
            textAlign: "center",
            fontSize: "20px",
            fontColor: "purple",
          }}
        >
          {this.state.cardValue}
        </div>
      </div>
    );
  }

  renderState() {
    switch (this.state.cardState) {
      case "untouched":
        return this.renderUntouchedState();
      case "touched":
        return this.renderTouchedState();
      default:
        return this.renderActiveState();
    }
  }

  render() {
    return this.renderState();
  }
}
