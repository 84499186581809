import React, { Component } from "react";
import {
  FormControl,
  InputGroup,
} from "react-bootstrap";
import "../../assets/css/attrib-cards.css";
import { toCardId } from "./AttribCardUtils.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage, faCamera } from "@fortawesome/free-solid-svg-icons";



export default class AttribTextCard extends Component {
  constructor(props) {
    super(props);

    this.Id = toCardId(this.props.attribTitle);

    this.state = {
      cardState: (this.props.valueBag[this.Id]) ? "touched" : "untouched",
      cardValue: this.props.valueBag[this.Id],
      required: (this.props.validation) ? (this.props.validation.required) ? true : false : false
    };


    this.renderState = this.renderState.bind(this);
    this.renderUntouchedState = this.renderUntouchedState.bind(this);
    this.renderActiveState = this.renderActiveState.bind(this);
    this.onCardClick = this.onCardClick.bind(this);
    this.onLoseFocus = this.onLoseFocus.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
  }


  componentDidUpdate(prevProps, prevState) {
    let oldVal = prevState.cardValue;
    //console.log("valueBag: ");
    //console.log(this.props.valueBag);
    let newVal = this.props.valueBag[this.Id];
    //console.log("Id: " + this.Id + " Old: " + oldVal + " New: " + newVal);
    if (oldVal !== newVal) {
      this.setState({
        cardValue: newVal
      });
      if (this.state.cardState !== "active") {
        this.setState({ cardState: (newVal) ? "touched" : "untouched" })
      }
    }
  }

  onValueChange(ev) {
    //console.log(ev);
    this.setState({ cardValue: ev.target.value });
    this.props.onValueChange(this.Id, ev.target.value);
  }

  onLoseFocus() {
    var newCardState = (this.state.cardValue) ? "touched" : "untouched";
    this.setState({ cardState: newCardState });
  }

  renderCameraIcon = () => {
    return (this.props.useCamera) ?
      <FontAwesomeIcon icon={faCamera} color="yellow" size="2x" style={{ float: "left", paddingLeft: "5px" }} onClick={(ev) => this.onCameraClick(ev)} />
      : <span></span>
  }

  onCameraClick(ev) {
    ev.preventDefault();
    ev.stopPropagation();
    this.props.onRequestCameraDialogOpen(this.Id);
  }

  onCardClick(ev) {
    var newState = "untouched";
    if (this.state.cardState === "active") {
      newState = (this.state.cardValue) ? "touched" : "untouched";
    } else {
      newState = "active";
    }
    this.setState({ cardState: newState });
    this.props.onToggleCard(this);
  }

  renderUntouchedState() {
    return (
      <div className={(this.state.required) ? "nonactive-required card-template shadow8" : "nonactivecard card-template shadow6"} onClick={this.onCardClick}>
        {this.props.attribTitle}
      </div>
    );
  }


  renderActiveState() {
    let titleTheme = "";
    let theme = "activecard card-template shadow6";
    let textBoxInputType = "";
    if (this.props.theme) {
      theme = this.props.theme + "activecard " + this.props.theme + "card-template";
      titleTheme = "surveyhideelement"
    }

    if (this.props.attribTitle === "temperature") {
      textBoxInputType = "tel"
    }

    return (
      <div
        className={theme}
      >
        <div onClick={this.onCardClick}>
          <div className={titleTheme}>{this.props.attribTitle}</div>
          {this.renderCameraIcon()}
        </div>
        <InputGroup size="sm" className="textcard-input">
          <FormControl
            type = {textBoxInputType}
            autoFocus
            plainText
            textAlign="center"
            placeholder={this.props.attribTitle}
            aria-describedby="basic-addon2"
            name={this.Id}
            value={this.state.cardValue}
            onChange={this.onValueChange}
          />
        </InputGroup>
      </div>
    );
  }

  renderTouchedState() {
    return (
      <div
        className="nonactivecard card-template touchedcard shadow7"
        onClick={this.onCardClick}
        style={{ backgroundColor: "lightgreen" }}
      >
        <div style={{ fontSize: "20px" }}>
          {this.props.attribTitle.toUpperCase()}
        </div>
        <div
          style={{
            textAlign: "center",
            fontSize: "20px",
            fontColor: "purple",
            minWidth: "5em",
            maxWidth: "20em",
            overflow: "hidden",
            padding: "5px"
          }}
        >
          {this.state.cardValue}
        </div>
      </div>
    );
  }

  renderState() {
    if (!this.props.theme) {
      switch (this.state.cardState) {
        case "untouched":
          return this.renderUntouchedState();
        case "touched":
          return this.renderTouchedState();
        default:
          return this.renderActiveState();
      }
    } else {
      return this.renderActiveState();
    }
  }


  render() {
    return this.renderState();
  }
}
