import React, { Component } from "react";
import { Card, Tab, Tabs, Button } from "react-bootstrap";
import { confirm } from "../components/Confirmation.js";
import queryString from "query-string";
import moment from "moment";
import cloneDeep from 'lodash/cloneDeep';
import {compile, evaluate} from "mathjs";

import { geolocated } from "react-geolocated";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImages } from "@fortawesome/free-solid-svg-icons";

import ModalSlideDialog from "../components/Modals/ModalSlideDialog.jsx";
import ModalErrorsDialog from "../components/Modals/ModalErrorsDialog.jsx";
import ModalPhotoDialog from "../components/Modals/ModalPhotoDialog.jsx";
import ModalCalculatorDialog from "../components/Modals/Calculator/component/CalculatorModal"


import AuthService from "../components/AuthService/AuthService.js";
import GlasschainHttpClient from "../components/ApiService/GlasschainHttpClient.js";
import IDbService from "../components/DataService/IDbService.js";
import IDbImageService from "../components/DataService/IDbImageService.js";

import AttributeGroup from "../components/schemaCards/AttributeGroup.jsx";

import ScoreManager from "../components/scorers/ScoreManager.js";
import DefaultObsShaper from "../components/Shapers/DefaultObsShaper.js";
import ObsSearchParams from "../models/ObsSearchParams.js";

import {toCardId,toLocalShortDate,} from "../components/schemaCards/AttribCardUtils.js";

import {v1 as uuidv1} from 'uuid';

import "../assets/css/V2-demo-schema.css";
import NotificationManager from "../components/notifications/NotificationManager.js";




class InspectionCardLayout extends Component {
  constructor(props) {
    super(props);

    this.auth = new AuthService();
    this.httpClient = new GlasschainHttpClient();
    this.localDbService = new IDbService();
    this.localDbImageService = new IDbImageService();

    var gcid = this.auth.getGcid();
    var inspectDate = toLocalShortDate(new Date());
    //data.oui.harvestDate = this.toLocalShortDate(new Date());

    this.state = {
      fullSchema: null,
      schemaId: null,
      orgConfig: null,
      renderChildren: null,
      showDebugInfo: false,
      activeAttrib: null,
      valueBag: {
        gcid: gcid,
        inspector: this.auth.getUser(),
        location: this.auth.getLocation(),
        inspectiondate: moment().format(),
      },
      errorBag: {}, // [value.id] = {type: "required", message=""}
      onChangeEventsBag: {}, // [value.id] = onChangeEvent
      showSlides: false,
      showCamera: false,
      showCalculator: false,
      cameraCardId: "",
      calcCardId: "",
      categoryState: {}, // [category.id]=true|false
      requiredState: {}, // [value.id] = {required: true|false, (message="")}
      slideInfo: { title: "", slides: [{ url: "", caption: "" }] },
      showErrors: false,
      score: null,
      observationResult: null,
      isUpdate: false
    };

    this.gcid = gcid;
    this.fullSchema = null;

    this.onToggleAttribCard = this.onToggleAttribCard.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.onCategoryStateValueChange = this.onCategoryStateValueChange.bind(this);
    this.onRequirementStateValueChange = this.onRequirementStateValueChange.bind(this);
    this.onRecalcStateValueChange = this.onRecalcStateValueChange.bind(this);
    this.onValueRemove = this.onValueRemove.bind(this);
    this.onSetCategoryState = this.onSetCategoryState.bind(this);
    this.onSaveUserImage = this.onSaveUserImage.bind(this);
    this.insertSchemaCard = this.insertSchemaCard.bind(this);

    this.onRequestCriteriaSlidesOpen = this.onRequestCriteriaSlidesOpen.bind(this);
    this.onRequestCriteriaSlidesClose = this.onRequestCriteriaSlidesClose.bind(this);
    this.onRequestSlideCatalogOpen = this.onRequestSlideCatalogOpen.bind(this);

    this.onRequestCameraDialogOpen = this.onRequestCameraDialogOpen.bind(this);
    this.onRequestCameraDialogClose = this.onRequestCameraDialogClose.bind(this);

    this.onRequestCalculatorDialogOpen = this.onRequestCalculatorDialogOpen.bind(this);
    this.onRequestCalculatorDialogClose = this.onRequestCalculatorDialogClose.bind(this);

    this.initDefaultValues = this.initDefaultValues.bind(this);
    this.refreshLocation = this.refreshLocation.bind(this);

    this.scoreInspection = this.scoreInspection.bind(this);
    this.cancelInspection = this.cancelInspection.bind(this);
    this.suspendInspection = this.suspendInspection.bind(this);

    this.toggleDebug = this.toggleDebug.bind(this);
    this.renderDebugInfo = this.renderDebugInfo.bind(this);

    this.initCategoryState = this.initCategoryState.bind(this);
    this.initRequiredState = this.initRequiredState.bind(this);
    this.registerOnChangeEvents = this.registerOnChangeEvents.bind(this);
    this.onValidationChange = this.onValidationChange.bind(this);
    this.onRequestErrorsDialogClose = this.onRequestErrorsDialogClose.bind(this);
    
    this.onSaveSchema = this.onSaveSchema.bind(this);
  }

  async componentDidMount() {
    // TBD - Fetch the full schema. I'd suggest we save the schema in local storage and only fetch it if it's not there or isn't latest. We could just do a quick
    // version comparison check first.
    const queryVals = queryString.parse(this.props.location.search);
    var newIsUpdate = queryVals.id ? true : false;
    var isRestoreSuspended = queryVals.suspended ? true: false;
    if ((newIsUpdate) && (!this.auth.loggedIn())){ // MUST be online to do updates at this point, so if we're not logged in,then force login
      this.props.history.replace("/auth"); 
    }

    this.setState({isUpdate: newIsUpdate});
    var newOrgConfig = this.auth.getLocalOrgConfig(this.gcid);

    if (newIsUpdate) {
      // we will get the schema from the observation
      var searchParams = new ObsSearchParams();
      searchParams.id = queryVals.id;
      var observationDataResult = await this.httpClient.fetchObservations(this.gcid, searchParams);
      var originalObservation = observationDataResult.data[0];
      var schemaData = this.auth.getLocalSchema(originalObservation.data.schema.id);
      var fullSchema = JSON.parse(schemaData.fullSchema);
      console.group("Update Value Bag:");
      console.log(originalObservation.data.Raw);
      console.groupEnd();
      this.setState({
          observationResult: originalObservation,
          valueBag: originalObservation.data.Raw,
          schemaId: originalObservation.data.schema.id,
          fullSchema: fullSchema,
          orgConfig: newOrgConfig.data
        }); 
    }
    else {
      var newValueBag = (isRestoreSuspended) ? this.auth.getLocalSuspendedObservation(this.auth.getGcid(), queryVals.schemaid, this.auth.getUser()) :
      this.state.valueBag;
      var newSchemaData = this.auth.getLocalSchema(queryVals.schemaid);
      var newFullSchema = JSON.parse(newSchemaData.fullSchema);
      //var newValueBag = this.state.valueBag;
      if (!isRestoreSuspended)
      {
        // initialize default values
        newValueBag = this.initDefaultValues(newFullSchema, newValueBag);
        // don't restore the new location over the original location
        newValueBag = await this.refreshLocation(false, newValueBag);
      }

      this.setState({
        valueBag: newValueBag,
        schemaId: queryVals.schemaid,
        fullSchema: newFullSchema,
        orgConfig: newOrgConfig.data
      })
    }
  }

  initDefaultValues(schema, valueBag)
  {

    schema.categories.forEach((cat) =>
    {
      cat.groups.forEach((group) => 
      {
        group.cards.forEach((card) =>
        {
          if ((card.default) && (card.default.enabled))
          {
            if (card.default.calcType === "constant")
            {
              valueBag[toCardId(card.title)] = card.default.defaultValue;
            }
            else if (card.default.calcType === "derived")
            {
              if (card.default.defaultValue === "today")
              {
                valueBag[toCardId(card.title)] = moment().format(card.display.format);
              }
            }
          }
        });
      });
    });
    return valueBag;
  }



  async componentDidUpdate(prevProps, prevState) {
    // this will NOT render child components until we are sure our orgConfig has been loaded into state. See render, below.
    if (this.state.fullSchema != prevState.fullSchema) {
      this.initCategoryState();
      this.initRequiredState();
      this.registerOnChangeEvents();
      this.setState({ renderChildren: true });
    }
    if ((!this.state.isUpdate) && (prevProps.coords != this.props.coords)) { // check if we have geo coordinates. Do NOT run on an update!
      var existingValBagState = this.state.valueBag;
      await this.refreshLocation(true, existingValBagState);
    }
  }

  async refreshLocation(updateState, valueBagToUpdate){
    console.log("refresh!");
    var isGeoLocationOn = this.props.isGeolocationAvailable && this.props.isGeolocationEnabled;
    if (isGeoLocationOn) {
      if (this.props.coords) {
        valueBagToUpdate["latitude"] = this.props.coords.latitude.toFixed(4);
        valueBagToUpdate["longitude"] = this.props.coords.longitude.toFixed(4);
        // valueBagToUpdate["altitude"] = this.props.coords.altitude;
        var addressResult = await this.httpClient.fetchAddressData(this.gcid, this.props.coords.latitude, this.props.coords.longitude);
        valueBagToUpdate["address"] = addressResult.data.formatted_address;
        // valueBagToUpdate["placeid"] = addressResult.data.place_id;
        var weatherResult = await this.httpClient.fetchWeatherData(this.gcid, this.props.coords.latitude, this.props.coords.longitude);
        valueBagToUpdate["weatherobserved"] = weatherResult.data.current.observation_time;
        valueBagToUpdate["ambienttemperature"] = weatherResult.data.current.temperature;
        valueBagToUpdate["cloudcover"] = weatherResult.data.current.cloudcover;
        valueBagToUpdate["airpressure"] = weatherResult.data.current.pressure;
        valueBagToUpdate["humidity"] = weatherResult.data.current.humidity;
        valueBagToUpdate["weatherdescript"] = weatherResult.data.current.weather_descriptions[0];
        valueBagToUpdate["winddegree"] = weatherResult.data.current.wind_degree;
        valueBagToUpdate["winddirection"] = weatherResult.data.current.wind_dir;
        valueBagToUpdate["windspeed"] = weatherResult.data.current.wind_speed;
        valueBagToUpdate["humidity"] = weatherResult.data.current.humidity;
        var weather = {current: weatherResult.data.current, location: weatherResult.data.location};
        if (updateState){
          this.setState({valueBag: valueBagToUpdate})
        }
      }
    }
    return valueBagToUpdate;
  }




  onSaveSchema() {
    this.auth.setFullSchema(this.state.fullSchema);
  }

  onSaveUserImage(imgObj){
      // does valuebag already have an images node?
      var newValueBag = this.state.valueBag;
      if (this.state.cameraCardId==="completedloadphoto"){
        newValueBag["completedloadphoto"] = true;
      }
      let img = {"id": imgObj.id, "ext": imgObj.ext, "descript": imgObj.descript, "tags": imgObj.tags};
      if (!newValueBag["userimages"]){
        // don't save the actual base64 data. TBD - we are going to save the image to either local storage for
        // upload or directly to Azure. 
        newValueBag["userimages"] = [img];
      }
      else {
        newValueBag["userimages"].push(img);
      }
      this.setState({valueBag: newValueBag});
      this.auth.saveLocalSuspendedObservation(this.state.valueBag.gcid, this.state.schemaId, this.state.valueBag.inspector, newValueBag);
      // we're going to PUSH a new card into the images group
      // and hope it re-renders!
      
      this.insertSchemaCard("notes", "Images",
        {"title": imgObj.id, "fieldType": "userimage", "descript": imgObj.descript,
        "tags": imgObj.tags, "dataUri": imgObj.dataUri});  

        //if (!this.blobStorageService){
        //  this.blobStorageService = new BlobStorageService();
        //}
        //this.blobStorageService.uploadUserImage(this.gcid, imgObj.dataUri, imgObj.id, 'jpg');
        
        this.localDbImageService.addImage(imgObj.dataUri, imgObj.id);

  }


  insertSchemaCard(categoryId, groupTitle, cardSchema){
    var newFullSchema = this.state.fullSchema;
    var category = newFullSchema.categories.find(cat => cat.id===categoryId);
    var group = category.groups.find(grp => grp.groupTitle===groupTitle);
    var cards = group.cards;
    cards.push(cardSchema);
    this.setState({fullSchema: newFullSchema});
  }

  registerOnChangeEvents() {
    // pre-register any on change events so that we don't have to rip through the entire schema to find them onchange
    var newOnChangeEventsBag = {};
    let DemoSchema = this.state.fullSchema;
    for (var i = 0; i < DemoSchema.categories.length; i++) {
      for (var j = 0; j < DemoSchema.categories[i].groups.length; j++) {
        for (var k = 0; k < DemoSchema.categories[i].groups[j].cards.length;k++) {
          var card = DemoSchema.categories[i].groups[j].cards[k];
          var cardId = toCardId(card.title);
          if (card.onChange) {
            // register it! It's possible that this card ALREADY HAS an onChange, generated by being part of a
            // calcFormula for a previously processed card with a calcFormula. 
            if (!newOnChangeEventsBag[cardId]){
              newOnChangeEventsBag[cardId] = card.onChange;
            } 
            else {
              // we must INJECT the card.onChange values into the EXISTING onChange object
              for (var e=0; e<card.onChange.length; e++){
                newOnChangeEventsBag[cardId].push(card.onChange[e]);
              }
            }
          }
          if (card.calcFormula){
            // this card has a calculation formula. Parse all fields in the formula and add them because
            // a change to ANY of these values should trigger a recalc. Calc cards are a little different
            // because we don't want to put the onChange recalc on EACH card in the formula. We only want to
            // put it in the calc card. But there's a problem if the original card already has an onChange
            // on it. We need to trigger the onChangeEvent on the FORMULA value, not on the calc card value.
            // TBD - how do we trigger the recalc when a "source" value in the formula changes? We could
            // literally INJECT the onChange into the source card's onChange (or create one if it doesn't exist.)
            // parse all "source" formula cards
            const regexp = /\[[^\]]*\]/gm
            var calcFields = [...card.calcFormula.matchAll(regexp)];
            // now for EACH calcField, either ADD an onChange event to it, or INJECT it into its existing one.
            for (var c=0; c<calcFields.length; c++){
              var calcField = calcFields[c][0];
              var calcFieldId = toCardId(calcField.replace("[","").replace("]",""));
              var recalcState = {"targetCard": cardId, "calcFormula": card.calcFormula};
              if (!newOnChangeEventsBag[calcFieldId]){
                // inject a new onChange for this field
                var newOnChange =  [{"recalcState": recalcState}];
                newOnChangeEventsBag[calcFieldId] = newOnChange;
              }
              else {
                // card already HAS an onChange. Just inject the new recalcState into the OnChange
                newOnChangeEventsBag[cardId].push({"recalcState": recalcState});
              }
            }
          }
        }
      }
      this.setState({ onChangeEventsBag: newOnChangeEventsBag });
    }
  }

  initCategoryState() {
    var newCategoryState = this.state.categoryState;
    let DemoSchema = this.state.fullSchema;
    for (var i = 0; i < DemoSchema.categories.length; i++) {
      let category = DemoSchema.categories[i];
      // on updates ONLY the Under Inspection category is enabled
      var catEnabled = this.state.isUpdate
        ? toCardId(category.id) === "underinspection"
        : category.initEnabled;
      newCategoryState[category.id] = catEnabled;
    }
    this.setState({ categoryState: newCategoryState });
  }

  initRequiredState() {
    var newRequiredState = this.state.requiredState;
    let DemoSchema = this.state.fullSchema;
    for (var i = 0; i < DemoSchema.categories.length; i++) {
      // if we're updating, then don't bother setting initRequiredState
      if (
        this.state.isUpdate &&
        toCardId(DemoSchema.categories[i].id) !== "underinspection"
      )
        continue;
      for (var j = 0; j < DemoSchema.categories[i].groups.length; j++) {
        for (
          var k = 0;
          k < DemoSchema.categories[i].groups[j].cards.length;
          k++
        ) {
          var card = DemoSchema.categories[i].groups[j].cards[k];
          if (card.validation && card.validation.required) {
            // some cards have more than one "value" associated with them (e.g. total qty has unit and container qtys). if the validate HAS an id value, then use that.
            var validationId = card.validation.id
              ? card.validation.id
              : toCardId(card.title);
            newRequiredState[validationId] = {
              required: true,
              message: card.validation.requiredMessage
                ? card.validation.requiredMessage
                : card.title + " is required!",
            };
          }
        }
      }
    }
    this.setState({ requiredState: newRequiredState });
  }

  onRequestErrorsDialogClose() {
    this.setState({ showErrors: false });
  }

  async scoreInspection() {
    var newErrorBag = this.state.errorBag;
    // it's possible that we have required field errors already. Let's clear them before re-checking required state.
    var filteredErrorBag = Object.fromEntries(
      Object.entries(newErrorBag).filter(([k, v]) => v.type !== "required")
    );
    // right now we only have required fields. Check the requiredState to fill the errorbag
    Object.keys(this.state.requiredState).forEach((key) => {
      if (!this.state.valueBag[key]) {
        filteredErrorBag[key] = {
          type: "required",
          message: this.state.requiredState[key].message,
        };
      }
    });
    this.setState({ errorBag: filteredErrorBag });
    if (Object.keys(filteredErrorBag).length > 0) {
      this.setState({
        showErrors: true,
      });
    } else {

      if (this.state.isUpdate){
        // returns JUST the underInspection section
        let updatedDate = moment().format();
        let originalUnderInspection = cloneDeep(this.state.observationResult.UnderInspection);
        let updatedUnderInspection = new DefaultObsShaper().shapeUnderInspectionUpdate(this.state.fullSchema, this.state.valueBag, updatedDate);
        let updatedObservation = this.state.observationResult;
        updatedObservation.data.UnderInspection = updatedUnderInspection;
        var updateEvent =  {
          Gcid: this.gcid,
          ObservationId: this.state.observationResult.identifiers.id,
          EventDate: updatedDate,
          EventType: "update",
          EventReason: "userchange",
          EventBy: this.auth.getUser(),
          EventLocation: this.auth.getLocation(),
          Changed: {
            OriginalOui: originalUnderInspection,
            ChangedOui: updatedUnderInspection
          }
        }
        this.setState({ observationResult : updatedObservation});
        let updatedResult = await this.httpClient.postQaObservationUpdate(updatedObservation, updateEvent);
      }
      else {
        var newOfflineId = uuidv1();
        var scoreManager = new ScoreManager();
        let DemoSchema = this.state.fullSchema;
        var scoreResult = scoreManager.score(DemoSchema, this.state.valueBag);
        this.setState({ score: scoreResult });
        let inspectResult = new DefaultObsShaper().shape(DemoSchema,this.state.valueBag);
        this.setState({ observationResult: inspectResult });
        var observation = { data: inspectResult, score: scoreResult, identifiers: {"id": newOfflineId}};

        var notificationsResult = new NotificationManager().getNotificationResult(DemoSchema, observation, newOfflineId);
        console.log("notificationResult: ");
        console.log(notificationsResult);
        observation.notifications = notificationsResult;
        
        this.localDbService.addObservation(this.gcid, observation, newOfflineId);
        this.auth.removeLocalSuspendedObservation(this.state.valueBag.gcid, this.state.schemaId, this.state.valueBag.inspector);
      }

      /*
      // TBD - test for post-save events
    if ((scoreResult.Score.IsRejected)){
      if (this.appConfig.hasAdapterProxyRole(this.state.gcid, "postHoldRequest")){
        var holdRequest = {
          "PalletLicenseNbr" : inspectResult.oui.palletTag,
          "RequestedBy" : inspectResult.oui.inspector,
          "HasReference" : true,
          "HoldDirection" : "Y",
          "Reference" : {
            "RequestedByType" : inspectResult.oui.workflowStep,
            "ReferenceNbr" : "",
            "Comments" : [
              "Hold Request generated by Inspection Reject"
            ]
          }
        };
        let holdRequestResult = await this.coreClient.postPalletHold(this.state.gcid, holdRequest);
      }
    }
    */
      this.props.history.replace("/home/home-page?i=" + (this.state.isUpdate ? "updated": "saved"));
    }
  }

  onRequestCalculatorDialogOpen(cardId){
    console.group("Opening Calculator Dialog");
    console.log("calcCardId: " + cardId);
    console.groupEnd();
    this.setState({
      showCalculator: true,
      calcCardId: cardId
    });
  }

  onRequestCalculatorDialogClose(){
    this.setState({showCalculator: false});
  }

  onRequestCameraDialogOpen(cardId){
    console.group("Opening Camera Dialog");
    console.log("cardId: " + cardId);
    console.groupEnd();
    this.setState({
      showCamera: true,
      cameraCardId: cardId
    });
  }

  onRequestCameraDialogClose(){
    this.setState({showCamera: false});
  }

  onRequestSlideCatalogOpen() {
    // begin with general schema slides
    let DemoSchema = this.state.fullSchema;
    var allSlides = DemoSchema.schemaSlides.slides; //schemaSlides.slides;
    for (var i = 0; i < DemoSchema.categories.length; i++) {
      for (var j = 0; j < DemoSchema.categories[i].groups.length; j++) {
        for (var k = 0;k < DemoSchema.categories[i].groups[j].cards.length;k++) {
          if (DemoSchema.categories[i].groups[j].cards[k].criteriaSlides) {
            allSlides.push(...DemoSchema.categories[i].groups[j].cards[k].criteriaSlides.slides);
          }
        }
      }
    }
    var newSlideInfo = { title: "Criteria Catalog", slides: allSlides };
    this.setState({
      slideInfo: newSlideInfo,
      showSlides: true,
    });
  }

  onRequestCriteriaSlidesOpen(newSlideInfo) {
    this.setState({
      slideInfo: newSlideInfo,
      showSlides: true,
    });
  }

  onRequestCriteriaSlidesClose() {
    this.setState({ showSlides: false });
  }

  toggleDebug() {
    this.setState({
      showDebugInfo: !this.state.showDebugInfo,
    });
  }

  async cancelInspection() {
    this.setState({ cancelButtonEnabled: false });
    confirm(
      "Are you sure? If Yes then your inspection will NOT be saved."
    ).then(
      () => {
        this.auth.removeLocalSuspendedObservation(this.state.valueBag.gcid, this.state.schemaId, this.state.valueBag.inspector);
        this.props.history.replace("/home");
      },
      () => {
        this.setState({ cancelButtonEnabled: true });
      }
    );
  }

  async suspendInspection() {
    this.setState({ suspendButtonEnabled: false });
    confirm(
      "Are you sure? If Yes then your inspection will be suspended."
    ).then(
      () => {
        this.props.history.replace("/home");
      },
      () => {
        this.setState({ suspendButtonEnabled: true });
      }
    );
  }

  onSetCategoryState(categoryId, enable) {
    var newCategoryState = this.state.categoryState;
    newCategoryState[categoryId] = enable;
    this.setState({ categoryState: newCategoryState });
  }

  onToggleAttribCard(attribCard) {
    if (this.state.activeAttrib === null) {
      this.setState({ activeAttrib: attribCard });
      return;
    }
    this.state.activeAttrib.onLoseFocus();
    var newActiveAttrib =
      this.state.activeAttrib.Id === attribCard.Id ? null : attribCard;
    this.setState({ 
      activeAttrib: newActiveAttrib,
      cameraCardId: (attribCard.Id) ? attribCard.Id : ""
    });
  }

  onValidationChange(id, isValid, message) {
    var newErrorBag = this.state.errorBag;
    if (isValid) {
      // remove the error if it's there
      delete newErrorBag[id];
    } else {
      newErrorBag[id] = { required: true, message: message };
    }
    this.setState({ errorBag: newErrorBag });
  }

  onCategoryStateValueChange(id, value, categoryStateChange) {
    // we are changing the state of a category (tab)
    // are we enabling the category state or disabling it? Or maybe we're not changing it at all?
    let newEnabledState = -1; // do nothing.
    if (categoryStateChange.enableOnValue === value) {
      newEnabledState = 1; // turn it ON
    }
    if (categoryStateChange.disableOnValue === value) {
      newEnabledState = 0; // turn it OFF
    }
    if (newEnabledState !== -1) {
      // change it!
      let newCategoryState = this.state.categoryState;
      newCategoryState[categoryStateChange.categoryId] =
        newEnabledState === 1 ? true : false;
      this.setState({ categoryState: newCategoryState });
    }
  }

  onRecalcStateValueChange(id, value, recalcStateChange, newValueBag){
    const regexp = /\[[^\]]*\]/gm
    const calcFields = [...recalcStateChange.calcFormula.matchAll(regexp)];
    // tbd - test each value to confirm it HAS a value
    // replace each field with its value
    var newCalcFormula = recalcStateChange.calcFormula;
    for (var i=0; i<calcFields.length; i++){
      var calcFieldId = toCardId(calcFields[i][0]).replace("[","").replace("]","");
      // is this the field we just changed? 
      var calcFieldValue = 0;
      if (toCardId(id) === calcFieldId){
        calcFieldValue = value;
      } else {
        calcFieldValue = (newValueBag[calcFieldId]) ? newValueBag[calcFieldId] : 0;
      }
      newCalcFormula = newCalcFormula.replace(calcFields[i][0], calcFieldValue); 
    }
    // now we have the formula
    const compiledExpression = compile(newCalcFormula);
    var result = compiledExpression.evaluate();
    this.onValueChange(toCardId(recalcStateChange.targetCard),result);
    //newValueBag[toCardId(recalcStateChange.targetCard)] = result;

  }



  onRequirementStateValueChange(id, value, requirementStateChange) {
    let newRequiredState = -1; // do nothing.
    if (requirementStateChange.requiredOnValue === value) {
      newRequiredState = 1; // turn it ON
    }
    if (requirementStateChange.notRequiredOnValue === value) {
      newRequiredState = 0; // turn it OFF
    }
    if (newRequiredState !== -1) {
      // change it!
      let newRequiredStateBag = this.state.requiredState;
      if (newRequiredState === 0) {
        delete newRequiredStateBag[requirementStateChange.targetId];
      }
      if (newRequiredState === 1) {
        newRequiredStateBag[requirementStateChange.targetId] = {
          required: true,
          message: requirementStateChange.message,
        };
      }
      this.setState({ requiredState: newRequiredStateBag });
    }
  }

  onValueChange(id, value) {
    console.log("New Value: " + value);
    var newValueBag = this.state.valueBag;
    newValueBag[id] = value;
    console.log("On Value Change: " + id + "=" + value);
    // this value change may have triggered other changes on other cards.
    if (id in this.state.onChangeEventsBag) {
      let onChangeEvents = this.state.onChangeEventsBag[id];
      for (var i = 0; i < onChangeEvents.length; i++) {
        // what kind of onchange event type are we dealing with here? Now we have three kinds: 
        // category (tab) state,  required state and recalc state which uses a calc formula
        if (onChangeEvents[i].categoryState) {
          this.onCategoryStateValueChange(
            id,
            value,
            onChangeEvents[i].categoryState
          );
        }
        if (onChangeEvents[i].requiredState) {
          this.onRequirementStateValueChange(
            id,
            value,
            onChangeEvents[i].requiredState
          );
        }
        if (onChangeEvents[i].recalcState){
          this.onRecalcStateValueChange(id, value, onChangeEvents[i].recalcState, newValueBag);
        }
      }
    }

    this.setState({ valueBag: newValueBag });
    // save for suspension
    this.auth.saveLocalSuspendedObservation(this.state.valueBag.gcid, this.state.schemaId, this.state.valueBag.inspector, newValueBag);
  }

  onValueRemove(id) {
    var newValueBag = this.state.valueBag;
    delete newValueBag[id];
    this.setState({ valueBag: newValueBag });
    this.auth.saveLocalSuspendedObservation(this.state.valueBag.gcid, this.state.schemaId, this.state.valueBag.inspector, newValueBag);
  }

  renderDebugInfo() {
    return this.state.showDebugInfo ? (
      <div style={{color:'white'}}>
        Value
        <pre style={{color:'lightgrey'}}>{JSON.stringify(this.state.valueBag, null, 2)}</pre>
        Error
        <pre style={{color:'lightgrey'}}>{JSON.stringify(this.state.errorBag, null, 2)}</pre>
        Required
        <pre style={{color:'lightgrey'}}>{JSON.stringify(this.state.requiredState, null, 2)}</pre>
        Inspection Result
        <pre style={{color:'lightgrey'}}>{JSON.stringify(this.state.observationResult, null, 2)}</pre>
        Score
        <pre style={{color:'lightgrey'}}>{JSON.stringify(this.state.score, null, 2)}</pre>
        OnChangeEvents
        <pre style={{color:'lightgrey'}}>{JSON.stringify(this.state.onChangeEventsBag, null, 2)}</pre>
      </div>
    ) : (
      <span></span>
    );
  }

  render() {

    const postButtonTitle = (this.state.isUpdate) ? "Update" : "Score";



    return this.state.renderChildren ? (
      <div>

      <ModalCalculatorDialog
          show = {this.state.showCalculator}
          cardId = {this.state.calcCardId}
          onRequestCalculatorDialogClose = {this.onRequestCalculatorDialogClose}
          onHide = {this.onRequestCalculatorDialogClose}
          onValueChange={this.onValueChange}
      />


        <ModalPhotoDialog
          show = {this.state.showCamera}
          cardId = {this.state.cameraCardId}
          onRequestCameraDialogClose = {this.onRequestCameraDialogClose}
          onSaveUserImage = {this.onSaveUserImage}
          onValueChange={this.onValueChange}
        />


        <ModalSlideDialog
          gcid={this.gcid}
          slideType={"criteria"}
          showSlides={this.state.showSlides}
          slideInfo={this.state.slideInfo}
          onRequestCriteriaSlidesClose={this.onRequestCriteriaSlidesClose}
        />

        <ModalErrorsDialog
          title={"Cannot Save"}
          show={this.state.showErrors}
          errorBag={this.state.errorBag}
          onRequestErrorsDialogClose={this.onRequestErrorsDialogClose}
        />

        <Card className="text-center">
          <Card.Body>
            <Tabs id="schema-categories" className="tab-group">
              {this.state.fullSchema.categories.map((category, index) => (
                <Tab
                  eventKey={category.id}
                  title={category.title}
                  disabled={!this.state.categoryState[category.id]}
                >
                  <div className="main-content">
                    {category.groups.map((cardGroup, cardIndex) => (
                      <AttributeGroup
                        title={cardGroup.groupTitle}
                        cards={cardGroup.cards}
                        columns={(cardGroup.columns) ? cardGroup.columns: {"xs":1, "sm":2, "md":3, "lg":4}}
                        onToggleCard={this.onToggleAttribCard}
                        onValueChange={this.onValueChange}
                        onValueRemove={this.onValueRemove}
                        onRequestCriteriaSlidesOpen={this.onRequestCriteriaSlidesOpen}
                        onRequestCameraDialogOpen={this.onRequestCameraDialogOpen}
                        onRequestCalculatorDialogOpen={this.onRequestCalculatorDialogOpen}
                        onSetCategoryState={this.onSetCategoryState}
                        onValidationChange={this.onValidationChange}
                        valueBag={this.state.valueBag}
                      />
                    ))}
                  </div>
                </Tab>
              ))}
            </Tabs>
          </Card.Body>
          <Card.Footer>
            <Button
              disabled={false}
              variant="primary"
              size="lg"
              onClick={this.scoreInspection}
            >
              {postButtonTitle}
            </Button>
            &nbsp;
            <Button
              variant="secondary"
              size="lg"
              onClick={this.cancelInspection}
            >
              Cancel
            </Button>
            &nbsp;
            <Button
              variant="secondary"
              size="lg"
              onClick={this.suspendInspection}
            >
              Suspend
            </Button>
              &nbsp;&nbsp;&nbsp;
            <Button
              disabled = {this.state.isUpdate}
              variant="secondary"
              size="lg"
              onClick={this.onRequestSlideCatalogOpen}
              onContextMenu={this.toggleDebug}
            >
              <FontAwesomeIcon icon={faImages} color="white" />
              &nbsp;Image Catalog
            </Button>
          </Card.Footer>
        </Card>

        <div>{this.renderDebugInfo()}</div>
      </div>
    ) : (
      <div>"Loading..."</div>
    );
  }
}

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
})(InspectionCardLayout);
