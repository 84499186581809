import React, { Component } from "react";
import {
  Card,
  InputGroup,
  Button,
  Container,
  Row,
  Col,
  ProgressBar,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
//import "ag-grid-community/dist/styles/ag-theme-material.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSyncAlt,
  faEdit,
  faBan,
  faExclamationTriangle
} from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import UIfx from "uifx";
import syncAudio from "../../../assets/sounds/Inchworm.mp3";
import OrgConfiguration from "../../../models/OrgConfiguration.js";
import GridLayout from "../../../models/GridLayout.js";
//import ObsDocument from "../../lib/Pdf/ObservationPdfGenerator.js";

import ObsDocument from "../../lib/Pdf/ObservationPdf.jsx";
import { pdf } from "@react-pdf/renderer";

import ModalAlertDialog from "../../Modals/ModalAlertDialog.jsx";

import moment from "moment";

import AuthService from "../../AuthService/AuthService.js";
import IDbImageService from "../../DataService/IDbImageService.js";
import IDbDocService from "../../DataService/IDbDocService.js";
import IDbService from "../../DataService/IDbService.js";
import HttpClient from "../../ApiService/GlasschainHttpClient.js";
import BlobStorageService from "../../AzureService/BlobStorageService.js";

const GRIDCOLSTATE = "gridcolstate";
const GRIDSORTMODE = "gridsortmode";
const GRIDFILTERMODE = "gridfiltermode";

const syncBell = new UIfx(syncAudio, {
  volume: 1,
  throttleMs: 100,
});

export default class LocalObsGridCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gridSchema: [],
      schema: null,
      gridData: [],
      isLoading: true,
      selectedRow: null,
      showAlert: false,
      alertTitle: "",
      alertMessage: "",
      alertType: "info", // error/warning/info
      progressBarPct: 0,
      syncing: false,
      otherUserLocalStoredObs: false,
      otherLocalStoredObsUsers: []
    };

    this.auth = new AuthService();
    this.localDb = new IDbService();
    this.localImgDb = new IDbImageService();
    this.localDocDb = new IDbDocService();
    this.blobStorageService = new BlobStorageService();
    this.gcid = this.auth.getGcid();
    this.userId = this.auth.getUser().toLowerCase().trim();
    this.httpClient = new HttpClient();
    this.syncTimer = null;

    this.refreshObservationGridSchema = this.refreshObservationGridSchema.bind(
      this
    );
    this.refreshGridData = this.refreshGridData.bind(this);
    this.onBtnExportDataAsCsv = this.onBtnExportDataAsCsv.bind(this);
    this.onBtnSaveLayout = this.onBtnSaveLayout.bind(this);
    this.onBtnResetLayout = this.onBtnResetLayout.bind(this);
    this.onBtnRestoreLayout = this.onBtnRestoreLayout.bind(this);
    this.onUpdateRequest = this.onUpdateRequest.bind(this);
    this.onVoidRequest = this.onVoidRequest.bind(this);
    this.onPdfRequest = this.onPdfRequest.bind(this);

    this.showAlertDialog = this.showAlertDialog.bind(this);
    this.onCloseAlert = this.onCloseAlert.bind(this);
    this.syncBatch = this.syncBatch.bind(this);
  }

  async componentDidMount() {
    // we may have trimed out while the user was sitting on the inspection screen
    await this.refreshObservationGridSchema();
    await this.refreshGridData();
    if (this.props.isConnected) {
      this.syncTimer = setInterval(() => this.syncBatch(), 10000);
    }
  }

  componentWillUnmount() {
    // clear our sync check
    clearInterval(this.syncTimer);
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.props.schemaId != prevProps.schemaId) {
      await this.refreshObservationGridSchema();
    }
    if (this.props.gridQueryString !== prevProps.gridQueryString) {
      await this.refreshGridData();
    }
    if (this.props.isConnected !== prevProps.isConnected) {
      if (prevProps.isConnected) {
        clearInterval(this.syncTimer);
      } else {
        this.syncTimer = setInterval(() => this.syncBatch(), 10000);
      }
    }
  }

  async refreshEnvironmentInfo(observation) {
    // we have to have latitude and longitude in order to update
    //console.group("Pre-environment observation");
    //console.log(observation);
    //console.log(observation.data);
    var obs = observation.data;
    var lat = obs.Environment
      ? obs.Environment.Latitude
        ? obs.Environment.Latitude
        : "unknown"
      : "unknown";
    var lng = obs.Environment
      ? obs.Environment.Longitude
        ? obs.Environment.Longitude
        : "unknown"
      : "unknown";

    if (lat === "unknown" || lng === "unknown") {
      return observation;
    }

    if (!obs.Environment.Address) {
      var addressResult = await this.httpClient.fetchAddressData(
        this.gcid,
        lat,
        lng
      );
      //console.log("Address Data: ");
      //console.log(addressResult.data);
      obs.Environment.Address = addressResult.data.formatted_address;
      obs.Environment.PlaceId = addressResult.data.place_id;
    }
    if (!obs.Environment.AmbientTemperature) {
      var weatherResult = await this.httpClient.fetchHistoricalWeatherData(
        this.gcid,
        lat,
        lng,
        obs.UnderInspection.InspectionDate
      );
      //console.log("Weather Result: ");
      //console.log(weatherResult.data);
      // what hour are we actually looking for?
      let inspectDate = obs.UnderInspection.InspectionDate.split("T")[0];
      let inspectHour = obs.UnderInspection.InspectionDate.split("T")[1].split(
        ":"
      )[0];
      let inspectHourNum = parseInt(inspectHour);
      var obsTime = inspectHourNum === 0 ? "0" : inspectHourNum + "00";
      //console.log("inspectDate: " + inspectDate + " inspectHour: " + inspectHour + " obsTime: " + obsTime);

      var hourWeatherResult = {};
      for (
        let i = 0;
        i < weatherResult.data.historical[inspectDate].hourly.length;
        i++
      ) {
        var hourRes = weatherResult.data.historical[inspectDate].hourly[i];
        if (hourRes.time === obsTime) {
          hourWeatherResult = hourRes;
          break;
        }
      }
      //console.log("Historical Weather");
      //console.log("Inspect Hour: " + obsTime);
      //console.log(hourWeatherResult);
      obs.Environment.WeatherObservedTime = inspectHour;
      obs.Environment.AmbientTemperature = hourWeatherResult.temperature;
      obs.Environment.AirPressure = hourWeatherResult.pressure;
      obs.Environment.WeatherDescript =
        hourWeatherResult.weather_descriptions[0];
      obs.Environment.WindDegree = hourWeatherResult.wind_degree;
      obs.Environment.WindDirection = hourWeatherResult.wind_dir;
      obs.Environment.WindSpeed = hourWeatherResult.wind_speed;
      obs.Environment.Humidity = hourWeatherResult.humidity;
    }
    observation.data = obs;

    //console.group("post-environment observation: ");
    //console.log(observation);
    //console.groupEnd()
    return observation;
  }

  async syncBatch() {
    if (this.state.syncing){
      // don't kick off again!
      return;
    }
    console.log("syncing...");
    this.setState({ syncing: true });
    var batch = this.state.gridData.filter((obs) => {
      return (obs.identifiers.status.endsWith("pending") && 
      (obs.data.UnderInspection.Inspector === this.userId));
    });
    var batchTotal = batch.length;
    if (batchTotal === 0) {
      this.setState({ syncing: false });
      return;
    }
    var countProcessed = 0;
    let pct = 0;
    this.setState({ progressBarPct: pct });
    for (var i = 0; i < batchTotal; i++) {
      //console.log("Trying to Sync: ");
      //console.log(batch[i]);
      var obsToPost = batch[i];
      var obsToPostId = batch[i].identifiers.id;
      try {
        // update weather/location info
        obsToPost = await this.refreshEnvironmentInfo(obsToPost);
        await this.httpClient.postV2QaObservation(
          this.gcid,
          obsToPost,
          obsToPostId
        );
        console.log("Posted to Core Service");
      } catch (err){
        console.log("Error posting observation " + obsToPostId);
        console.log(err);
        this.props.onToastNotificationRequest('error', "Error syncing local observation " + obsToPostId);
        // TBD: create toast to inform user of problem.
        // skip this guy and return to next observation
        continue;
      }
      // does this observation happen to have a void associated with it that we need post?!
      var voidEvent = await this.localDocDb.fetchDoc("voidevent", obsToPostId);
      if (voidEvent) {
        try{
          await this.httpClient.postQaObservationVoid(voidEvent);
          await this.localDocDb.deleteDoc("voidevent", obsToPostId);
        } catch(voidErr){
          console.log("Problem posting or deleting local Void Event for " + obsToPostId);
          console.log(voidErr);
          this.props.onToastNotificationRequest('warning', "Error posting or deleting local void event for observation " + obsToPostId);
          // keep going...
        }
      }
      // are there valid notifications rules that got tripped so that we need to send a notificationRequest?
      if (
        obsToPost.notifications &&
        obsToPost.notifications.rules.some((r) => {
          return r.triggered;
        })
      ) {
        try{
        var notificationResult = await this.httpClient.postNotificationRequest(
          obsToPost.notifications,
          obsToPostId
        );
        console.log("Notification Result: ");
        console.log(notificationResult);
        } catch(notifyErr){
          console.log("There was an error notifying user of result for observation " + obsToPostId);
          console.log(notifyErr);
          this.props.onToastNotificationRequest('warning', "Error notifying recipient of result of observation " + obsToPostId);
          // keep going...
        }
      }

      // are there any images stored locally we need to update from this inspection?
      if (obsToPost.data.Raw.userimages) {
        var images = obsToPost.data.Raw.userimages;
        for (var j = 0; j < images.length; j++) {
          // does it exist locally or have we already uploaded it?
          try{
            var base64Img = await this.localImgDb.fetchImage(images[j].id);
            await this.blobStorageService.uploadUserImage(this.gcid, base64Img, images[j].id);
            await this.localImgDb.deleteImage(images[j].id);
          } catch(imgErr){
            console.log("Error fetching, uploading or deleting image for observation " + obsToPostId);
            console.log(imgErr);
            this.props.onToastNotificationRequest('warning', "Error uploading or deleting image for observation " + obsToPostId);
            // keep going!
            continue;
          }
        }
      }

      // post the PDF
      try{
        console.log("building blob...");
        const blob = await pdf(<ObsDocument observation={obsToPost} />).toBlob();
        console.log("uploading blob...");
        await this.blobStorageService.uploadPdf(this.gcid, blob, obsToPostId);
      } catch(pdfErr){
        console.log("Error creating or uploading PDF document for observation " + obsToPostId);
        console.log(pdfErr);
        this.props.onToastNotificationRequest('warning', "Error creating or uploading PDF doc for observation " + obsToPostId);
      }

      // finally, delete the local observation
      try{
      this.localDb.deleteObservation(obsToPostId);
      } catch(obsDeleteErr){
        console.log("Error deleting locally-stored observation " + obsToPostId);
        console.log(obsDeleteErr);
        this.props.onToastNotificationRequest('error', "Error deleting local observation " + obsToPostId + " after successful sync");
      }

      countProcessed++;
      pct = 100 - (countProcessed / batchTotal) * 100;
      this.setState({ progressBarPct: pct });
    }
    this.props.onSyncCompleteEvent();
    this.setState({ syncing: false });
    syncBell.play();
  }

  showAlertDialog(title, message, type) {
    //error warning info
    this.setState({
      alertTitle: title,
      alertMessage: message,
      alertType: type,
      showAlert: true,
    });
  }

  onCloseAlert() {
    this.setState({ showAlert: false });
  }

  onPdfRequest() {
    if (!this.state.selectedRow) {
      this.showAlertDialog(
        "Select an Inspection",
        "Please select an observation first!",
        "error"
      );
      return;
    }
    this.props.onObservationPdfRequest(this.state.selectedRow.identifiers.id);
  }

  async onUpdateRequest() {
    if (!this.state.selectedRow) {
      this.showAlertDialog(
        "Select an Inspection",
        "Please select an observation to update first!",
        "error"
      );
      return;
    }
    var rule = OrgConfiguration.RuleBySchemaId(
      this.props.orgConfig,
      this.props.schemaId
    );
    if (!rule.allowUpdates) {
      this.showAlertDialog(
        "Updates not Allowed",
        "Your organization does not allow Updates",
        "error"
      );
      return;
    }
    if (rule.hasUpdateExpiration) {
      var observationDateTime = moment(
        this.state.selectedRow.data.UnderInspection.InspectionDate
      );
      var boundaryDateTime = moment(observationDateTime).add(
        rule.allowUpdatesAfterXHours,
        "hours"
      );
      if (moment() > moment(boundaryDateTime)) {
        this.showAlertDialog(
          "Void",
          "Sorry. This inspection cannot be updated more than " +
            rule.allowUpdatesAfterXHours +
            " hours after it was created.",
          "error"
        );
        return;
      }
      this.props.onRequestUpdateObservation(
        this.state.selectedRow.identifiers.id
      );
    }
  }

  async onVoidRequest() {
    if (!this.state.selectedRow) {
      this.showAlertDialog(
        "Select an Inspection",
        "Please select an observation to void first!",
        "error"
      );
      return;
    }
    var rule = OrgConfiguration.RuleBySchemaId(
      this.props.orgConfig,
      this.props.schemaId
    );
    if (!rule.allowVoids) {
      this.showAlertDialog(
        "Voids not Allowed",
        "Your organization does not allow Voids",
        "error"
      );
      return;
    }
    if (rule.hasVoidExpiration) {
      var observationDateTime = moment(
        this.state.selectedRow.data.UnderInspection.InspectionDate
      );
      var boundaryDateTime = moment(observationDateTime).add(
        rule.allowVoidsAfterXHours,
        "hours"
      );
      if (moment() > moment(boundaryDateTime)) {
        this.showAlertDialog(
          "Void",
          "Sorry. This inspection cannot be voided more than " +
            rule.allowVoidsAfterXHours +
            " hours after it was created.",
          "error"
        );
        return;
      }
      this.props.onVoidRequest(this.state.selectedRow.identifiers.id);
    }
  }

  async refreshObservationGridSchema() {
    //console.group("Current Schema Id (from props)");
    //console.log(this.props.schemaId);
    var schemaData = this.auth.getLocalSchema(this.props.schemaId);
    var fullSchema = JSON.parse(schemaData.fullSchema);
    // now we have to BUILD the grid layout.
    var newCleanGridSchema = GridLayout.toAgGridLayout(fullSchema.gridLayout);
    this.setState({ gridSchema: newCleanGridSchema });
  }

  filterUniqueUserIds(localObsData, excludeId){
    var result = [];
    for (var i=0; i<localObsData.length; i++){
      var id = localObsData[i].data.UnderInspection.Inspector;
      if (excludeId){
        if (id === excludeId) continue;
      }
      var testId = (id) ? id: "unknown";
      if (!result.includes(testId)){
        result.push(testId);
      }
    }
    return result;
  }

  async refreshGridData() {
    this.setState({ isLoading: true });
    var dataResponse = await this.localDb.fetchAllObservations();
    // normalize any legacy user Ids that somehow got in the system
    dataResponse.forEach((obs) => {
        console.log(obs);
          obs.data.UnderInspection.Inspector = obs.data.UnderInspection.Inspector.toLowerCase().trim();
          obs.data.Raw.inspector = obs.data.Raw.inspector.toLowerCase().trim();
        })

    var newOtherLocalStoredObsUsers = this.filterUniqueUserIds(dataResponse, this.userId);
    // filter all locally-stored observations to ONLY display those for the
    // currently logged-in user
    var gridDataResponse = dataResponse.filter((obs) => {
      return (obs.data.UnderInspection.Inspector === this.userId);
    });
    if (dataResponse.length > gridDataResponse.length){
      // we must have locally-stored observations for a DIFFERENT user
      // TBD: inform THIS user of that.
      this.setState({
        "otherUserLocalStoredObs": true,
        "otherLocalStoredObsUsers": newOtherLocalStoredObsUsers
    });
    console.log("other users: ");
    console.log(newOtherLocalStoredObsUsers);
    }
    //console.log("Raw Local gridDataResponse");
    //console.log(gridDataResponse);
    //var gridDataResponse = await this.httpClient.fetchObservationList(this.props.gridQueryString);
    var newGridData = gridDataResponse;
    //console.log("Local Grid Data:");
    //console.log(newGridData);
    this.props.onDetailedGridRowChange(null);
    this.setState({
      gridData: newGridData,
      isLoading: false,
    });
  }

  onEditGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    var savedColState = JSON.parse(this.auth.getUserProp(GRIDCOLSTATE));
    if (savedColState) {
      this.gridColumnApi.setColumnState(savedColState);
    }
  };

  onSelectionChanged() {
    var selectedNodes = this.gridApi.getSelectedNodes();
    var rowData = selectedNodes[0].data;
    this.setState({ selectedRow: rowData });
    this.props.onDetailedGridRowChange(rowData);
  }

  // Controls
  onBtnExportDataAsCsv() {
    const saveParams = {
      allColumns: true,
      onlySelected: false,
      onlySelectedAllPages: false,
      skipHeader: false,
    };
    this.gridApi.exportDataAsCsv(saveParams);
  }

  onBtnSaveLayout() {
    var newGridColState = this.gridColumnApi.getColumnState();
    this.auth.setUserProp(
      this.state.schemaId + "::" + GRIDCOLSTATE,
      JSON.stringify(newGridColState)
    );
  }

  onBtnResetLayout() {
    this.gridColumnApi.resetColumnState();
    this.gridColumnApi.resetColumnGroupState();
    this.gridApi.setSortModel(null);
    this.gridApi.setFilterModel(null);
  }

  onBtnRestoreLayout() {
    var savedColState = JSON.parse(
      this.auth.getUserProp(this.state.schemaId + "::" + GRIDCOLSTATE)
    );
    if (savedColState) {
      this.gridColumnApi.setColumnState(savedColState);
    }
  }

  render() {
    const loadingIcon = this.state.isLoading ? (
      <FontAwesomeIcon icon={faSyncAlt} color="green" spin />
    ) : (
      ""
    );

    const syncingIcon = this.state.isSyncing ? (
      <FontAwesomeIcon icon={faSyncAlt} color="green" spin />
    ) : (
      ""
    );

    const divStyle = {
      width: "100%",
      height: "calc(45vh)",

      margin: "5px",
      //border: '5px solid black'
    };

    const columnDefs = this.state.gridSchema ? this.state.gridSchema : [];

    const gridData = this.state.gridData ? this.state.gridData : [{}];

    const updateButton = !this.props.isConnected ? (
      <p data-tip="UPDATE non-scoring inspection information (if within allowable timeframe)">
        <Button size="sm" variant="outline-info" onClick={this.onUpdateRequest}>
          <FontAwesomeIcon icon={faEdit} />
          &nbsp;update
        </Button>
      </p>
    ) : (
      <span></span>
    );

    const voidButton = !this.props.isConnected ? (
      <p data-tip="VOID the currently selected observation (if within allowable timeframe)">
        <Button size="sm" variant="outline-info" onClick={this.onVoidRequest}>
          <FontAwesomeIcon icon={faBan} />
          &nbsp;void
        </Button>
      </p>
    ) : (
      <span></span>
    );

    const manualSyncButton = this.props.isConnected ? (
      <p data-tip="Sync Local Observations Immediately">
        <Button
          size="sm"
          variant="outline-info"
          onClick={this.syncBatch}
          disabled={this.state.gridData.length < 1}
        >
          &nbsp;sync
        </Button>
      </p>
    ) : (
      <span></span>
    );

    const otherUserLocalRecsLabel =  (this.state.otherUserLocalStoredObs) ? 
      <span style={{color: "#696969", marginLeft: "60%", fontSize: "smaller"}}>
        <FontAwesomeIcon icon={faExclamationTriangle} color="black"/>&nbsp;
        Additional Offline Observations exist for other users:&nbsp;
        {this.state.otherLocalStoredObsUsers.join(',')}
      </span>
      : "";
     


    return (
      <span>
        <ModalAlertDialog
          show={this.state.showAlert}
          title={this.state.alertTitle}
          iconType={this.state.alertType} // error|warning|info
          message={this.state.alertMessage}
          onClose={this.onCloseAlert}
        />
        <Card id="historyPanelCard">
          <Card.Header>
            <span style={{ float: "left" }}>
              Local Observations&nbsp;&nbsp;&nbsp;
            </span>
            <span style={{ float: "right" }}>{loadingIcon}</span>
            <span>
              <ProgressBar
                striped
                animated
                variant="info"
                now={this.state.progressBarPct}
              />
            </span>
            <span>{syncingIcon}</span>
          </Card.Header>
          <ReactTooltip />
          <Card.Body>
            <div style={{ width: "100%", height: "100%" }}>
              <Container fluid>
                <InputGroup size="sm">
                  {voidButton}
                  {manualSyncButton}
                  {otherUserLocalRecsLabel}
                </InputGroup>

                <div className="ag-theme-balham" style={divStyle}>
                  <AgGridReact
                    rowSelection="single"
                    enableRangeSelection={true}
                    pagination={true}
                    columnDefs={columnDefs}
                    rowData={gridData}
                    animateRows={true}
                    onGridReady={this.onGridReady}
                    onSelectionChanged={this.onSelectionChanged.bind(this)}
                  />
                </div>
              </Container>
            </div>
          </Card.Body>
        </Card>
      </span>
    );
  }
}
