import React, { Component } from "react";
import {deviceType, isDesktop, isMobile, isTablet, engineName, engineVersion, browserName, browserVersion, fullBrowserVersion, mobileModel, mobileVersion, mobileVendor, isSamsungBrowser} from "react-device-detect";


function isBraveBrowser() 
{
    if (window.navigator.brave != undefined) {
      if (window.navigator.brave.isBrave.name == "isBrave") {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
}

export default class DeviceInfo extends Component 
{
    constructor(props) 
    {
        super(props); 

        this.getDeviceInfo = this.getDeviceInfo.bind(this);
    }



    getDeviceInfo()
    {
        var ua = (navigator.userAgent) ? navigator.userAgent: "unknown";
        var isBrave = isBraveBrowser();
        var browserSubs = [];
        if (isSamsungBrowser) browserSubs.push("Samsung");
        if (isBrave) browserSubs.push("Brave");

        var result = {
            "device":
              {
                "deviceType" : deviceType,
                "isDesktop": isDesktop,
                "isMobile": isMobile,
                "isTablet": isTablet
              },
            "engine":
            {
              "engineName": engineName,
              "engineVersion": engineVersion
            },
            "browser":
            {
              "browserName": browserName,
              "browserVersion": browserVersion,
              "fullBrowserVersion": fullBrowserVersion,
              "userAgent": ua,
              "isSamsungBrowser": isSamsungBrowser,
              "isBraveBrowser" : isBraveBrowser(),
              "browserSubTypes": browserSubs
            },
            "mobile":
            {
              "mobileModel": mobileModel,
              "mobileVersion": mobileVersion,
              "mobileVendor": mobileVendor
            }
      
          };
          return result;
    }
}
