import React, { Component } from "react";
import {
  FormControl,
  InputGroup,
} from "react-bootstrap";
import "../../assets/css/attrib-cards.css";
import {toCardId} from "./AttribCardUtils.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQrcode, faCamera} from "@fortawesome/free-solid-svg-icons";
/*
import ScanditBarcodeScanner from "scandit-sdk-react";
import {
    Barcode,
    BarcodePicker,
    CameraAccess,
    CameraSettings,
    ScanSettings
  } from "scandit-sdk";
  */



export default class AttribScanditCard extends Component {
  constructor(props) {
    super(props);

    this.Id = toCardId(this.props.attribTitle);
    this.licenseKey = 'AfZPjlmNRvlIKSgzrzoAZw0OHeoICxgieXCdBm5kK+yJWLYpamg1pe9BQ9U0BOfHiVAi2/x9yENLf1lY0kE2UjF24IlPL/2i6EKa8402vR48QdNdiE7TLQZnNqzQa6CIB1E4m5VT/njpNjCJdgN2Aq4lji+tAA1haKIx2RRuhHXRahLCcgZ39bdtS4ED3ezPdH4fpo4FiY4nFvZ1lyn3TgkWc9qz9OXfUvlnbbcB6/rSgdORVS1WrEPHC+/OzU47qlWYlsu4Q3Do0jNDtkrnNMVn1cVWt/uxR9esmlYHLmMQr1eHyFV0wVnouhVeFqoihLqo7sOSdUlBrsa+9XTZeksnsu+QpVUvelQOhFZA4ns8u1IkyrBVqeDB0fnDwLE8/FgmvBZLJemI+24jFTXDgtvlTDkl1CJXAW33GYURpF7GQKcMCkwY03Bt/vweGuVeJ9YLoH23PLN4R4vnppsINzCz6VxpjjUKbhepgGCP5cvpffFClY9Yd62ErVr2PlVlPRqA2DgSY6BvBcruI4fzsCbAzDQE1sFI1O4R4IxIudqLGnmmkRNHpofr2MFLfvZvj366TmtUjR8GLc9O3EimDhk7xDrihFwE1EynTtTUpT92M5iM+xqC+9EFuJhybTg2lhovrqlAkhoqkdSCgXlESNB66h7NKP54yziZKJ8dZisZSi0Qp8swrfYpVn0vZUA+xVOxPqD0YjltqKKjhx4okOf0Dvyzq8mgvw0i21MRT8w9EXmTME9CXV2yW3pssdv+wfntW4MjN4rUhc7A9liNFsT3YA7qyfUliLt8gD4hDa1p9YmUjC/SrTSbKp6LaPj5ZB1H60sLvys=';
    this.state = {
      cardState: (this.props.valueBag[this.Id]) ? "touched" : "untouched",
      cardValue: this.props.valueBag[this.Id],
      required: (this.props.validation) ? (this.props.validation.required) ? true: false : false
    };


    this.renderState = this.renderState.bind(this);
    this.renderUntouchedState = this.renderUntouchedState.bind(this);
    this.renderActiveState = this.renderActiveState.bind(this);
    this.onCardClick = this.onCardClick.bind(this);
    this.onLoseFocus = this.onLoseFocus.bind(this);
    this.onValueChange = this.onValueChange.bind(this);

    this.onBarCodeScan = this.onBarcodeScan.bind(this);
  }


  componentDidUpdate(prevProps, prevState){
    let oldVal = prevState.cardValue;
    //console.log("valueBag: ");
    //console.log(this.props.valueBag);
    let newVal = this.props.valueBag[this.Id];
    //console.log("Id: " + this.Id + " Old: " + oldVal + " New: " + newVal);
    if (oldVal !== newVal){
      this.setState({
        cardValue: newVal
      });
      if (this.state.cardState !== "active"){
        this.setState({cardState: (newVal) ? "touched" : "untouched"})
      }
    }
  }

  onValueChange(ev) {
    //console.log(ev);
    this.setState({ cardValue: ev.target.value });
    this.props.onValueChange(this.Id, ev.target.value);
  }

  onLoseFocus() {
    var newCardState = (this.state.cardValue) ? "touched" : "untouched";
    this.setState({ cardState: newCardState });
  }

  renderBarcodeIcon =() =>{
    return (this.props.useCamera) ? 
      <FontAwesomeIcon icon={faQrcode} color="white" size="2x" style={{float: "left", paddingLeft: "5px"}} onClick={(ev) => this.onBarcodeClick(ev)}/> 
      : <span></span>
  }

  onBarcodeClick(ev){
    ev.preventDefault();
    ev.stopPropagation();
   // this.props.onRequestCameraDialogOpen(this.Id);
  }

  onBarcodeScan(ev){

    console.log("barcode event");
    console.log(ev);
    var scanVal = ev.barcodes[0].data; // SINGLE scan val!
    var newCardValue = this.state.cardValue;
    if ( (!newCardValue) || (!Array.isArray(newCardValue)) ){
        // initialize it!
        newCardValue = [];
    }
    if (!newCardValue.includes(scanVal)){
        newCardValue.push(scanVal);
        this.setState({cardValue: newCardValue});
        this.props.onValueChange(this.Id, newCardValue);
    }
  }


/*
  getScanSettings = () => {
    return new ScanSettings({ enabledSymbologies: [Barcode.Symbology.CODE128, Barcode.Symbology.DATA_MATRIX] });
  };
*/

  onCardClick(ev) {
    var newState = "untouched";
    if (this.state.cardState === "active") {
      newState = (this.state.cardValue) ? "touched" : "untouched";
    } else {
      newState = "active";
    }
    this.setState({ cardState: newState });
    this.props.onToggleCard(this);
  }

  renderUntouchedState() {
    return (
      <div className={(this.state.required) ? "nonactive-required card-template shadow8" : "nonactivecard card-template shadow6"} onClick={this.onCardClick}>
        {this.props.attribTitle}
      </div>
    );
  }
  

  renderActiveState() {
    return (
      <div
        className="activecard card-template shadow6"
      >
        <div onClick={this.onCardClick}>
          {this.props.attribTitle}
          {this.renderBarcodeIcon()}
        </div>


      </div>
    );
  }

  renderTouchedState() {
    return (
      <div
        className="nonactivecard card-template touchedcard shadow7"
        onClick={this.onCardClick}
        style={{ backgroundColor: "lightgreen" }}
      >
        <div style={{ fontSize: "20px" }}>
          {this.props.attribTitle.toUpperCase()}
        </div>
          <div
            style={{
              textAlign: "center",
              fontSize: "20px",
              fontColor: "purple",
              minWidth: "5em",
              maxWidth: "20em",
              overflow: "hidden",
              padding: "5px"
            }}
          >
            {this.state.cardValue.join(", ")}
          </div>
      </div>
    );
  }

  renderState() {
    switch (this.state.cardState) {
      case "untouched":
        return this.renderUntouchedState();
      case "touched":
        return this.renderTouchedState();
      default:
        return this.renderActiveState();
    }
  }

  render() {
    return this.renderState();
  }
}
