import React, { Component } from "react";
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import DeviceInfo from "../lib/Device/DeviceInfo";
import gcObsLogo from "../../assets/images/logos/glasschain-logo.png";

import AppConfig from "../AppConfig/AppConfig.js";

export default class ModalAboutDialog extends Component 
{



  render() {

    const version = new AppConfig().getVersion();
    var deviceInfo = new DeviceInfo().getDeviceInfo();
    var browserString = <div style={{color: "grey", fontSize: "8pt"}}>Browser: {deviceInfo.browser.browserName} Version: {deviceInfo.browser.fullBrowserVersion}&nbsp;&nbsp;{(deviceInfo.browser.browserSubTypes.length>0) ? deviceInfo.browser.browserSubTypes.join('-'): ""}</div>





    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onRequestAboutDialogClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>About Glasschain QA</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                <img
                  src={gcObsLogo}
                  alt="glasschain logo"
                />
              </Col>
              <Col>
                <div center="true">Glasschain QA</div>
                <div center="true">Powered by Glassbit</div>
                <div center="true">Version: {version} </div>
                <div center="true">{browserString} </div>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={this.props.onRequestAboutDialogClose}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
