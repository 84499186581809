import React from "react";
import { Button, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import Display from "./Display";
import ButtonPanel from "./ButtonPanel";
import calculate from "../logic/calculate";
import "./CalculatorModal.css";

export default class CalculatorModal extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            total: null,
            next: null,
            operation: null,
        };
    }


  handleClick = (buttonName) => {
    this.setState(calculate(this.state, buttonName));
  };

  handleSave = () => {
    var currValue = this.state.next || this.state.total || "0";
    console.log("CardID: " + this.props.cardId);
    console.log("Total Value: " + currValue);
    this.props.onValueChange(this.props.cardId, currValue);
    this.handleClose();
  }

  handleClose = () => {
    this.setState({
      total: null,
      next: null,
      operation: null
    })
    this.props.onHide();
  }


  render() {
    return (
      <Modal show={this.props.show} onHide={this.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Calculator</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="component-calculator">
            <Display value={this.state.next || this.state.total || "0"} />
            <ButtonPanel clickHandler={this.handleClick} />
          </div>
        </Modal.Body>
        <Modal.Footer>
        <Button variant="secondary" onClick={this.handleSave}>
            Use Result
          </Button>
          <Button variant="secondary" onClick={this.handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
