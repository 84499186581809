// "https://gc-qa2-auth-svc.azurewebsites.net"
// "https://gc-qa2-core-svc.azurewebsites.net"
// "https://gc-notification-svc.azurewebsites.net"
// "https://gc-adapter-proxy-cg.azurewebsites.net"
// "http://localhost:6001"
// "http://localhost:7001"
// "http://localhost:8001"

var appsettings = [
    
    
    //{key: "glasschain.auth.host.addr", value: "http://localhost:6001"},
    //{key: "glasschain.core.host.addr", value: "http://localhost:7001"},
    //{key: "glasschain.notification.host.addr", value: "http://localhost:9001"},
    

    
    {key: "glasschain.auth.host.addr", value: "https://gc-qa2-auth-svc.azurewebsites.net"},
    {key: "glasschain.core.host.addr", value: "https://gc-qa2-core-svc.azurewebsites.net"},
    {key: "glasschain.notification.host.addr", value: "https://gc-notification-svc.azurewebsites.net"},
    

    {key: "glasschain.proxy.addr", value: 
        [
            {"companyGcid": "cg", "hostaddr": "https://gc-adapter-proxy-cg.azurewebsites.net"},
            {"companyGcid": "gcdemo", "hostaddr": "https://gc-adapter-proxy-cg.azurewebsites.net"},
            {"companyGcid": "om", "hostaddr": "https://gc-adapter-proxy-om.azurewebsites.net"},
        ]
    },
    {key: "glasschain.proxy.roles", value: 
        [
            {"companyGcid": "cg", "roles": ["fetchByPallet"]},
            {"companyGcid": "gcdemo", "hostaddr": "https://gc-adapter-proxy-cg.azurewebsites.net"},
            {"companyGcid": "om", "roles": ["fetchByPallet"]} //, "postHoldRequest"]}
        ]
    },
    {key: "glasschain.image.store.addr", value: "https://gcblobstoredurable1.blob.core.windows.net"},
    {key: "glasschain.obs.image.store.addr", value: "https://gcblobstoredurable1.blob.core.windows.net/gcid"},
    {key: 'google.vision.api.key', value: "AIzaSyCjQzBfEZveXkYcCVaoEzKhkaKJCVIfXMw"},
    {key: "version", value: "2.23.20"}



]


module.exports = {
    appsettings
}