
export default class OrgConfiguration {

    static RuleBySchemaId(orgConfig, schemaId){
        var updateRules = orgConfig.updateRules;
        var rule = updateRules.find(rule => rule.schemaId === schemaId.toUpperCase());
        if (!rule){
          rule = updateRules.find(rule=> rule.schemaId === "default");
        }
        return rule;
    }
    
}