import React, { Component } from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faImage,
  faCamera,
  faCalculator,
} from "@fortawesome/free-solid-svg-icons";
import {
  toCardId,
  toCardTitle,
  isEmpty,
  deleteKey,
} from "./AttribCardUtils.js";
import "../../assets/css/attrib-cards.css";

export default class AttribCountCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardState: "untouched",
      cardValue: {},
      currentValue: "",
      accumulatedValue: "",
    };
    this.Id = toCardId(this.props.attribTitle);
    this.variants = this.props.attribVariants
      ? this.props.attribVariants
      : [""];

    this.renderState = this.renderState.bind(this);
    this.renderUntouchedState = this.renderUntouchedState.bind(this);
    this.renderActiveState = this.renderActiveState.bind(this);
    this.onCardClick = this.onCardClick.bind(this);
    this.onLoseFocus = this.onLoseFocus.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.onInputValueChange = this.onInputValueChange.bind(this);
    this.onValueClick = this.onValueClick.bind(this);
    this.onTouchEnd = this.onTouchEnd.bind(this);
    this.renderCriteriaIcon = this.renderCriteriaIcon.bind(this);
    this.onCriteriaClick = this.onCriteriaClick.bind(this);
    this.renderCalculatorIcon = this.renderCalculatorIcon.bind(this);
    this.onCalculatorClick = this.onCalculatorClick.bind(this);
    
    this.setFromValueBag = this.setFromValueBag.bind(this);

  }


  componentDidUpdate(prevProps, prevState)
  {
    if (prevProps !== this.props)
    {
      this.setFromValueBag();
    }
  }

  setFromValueBag()
  {
    this.variants.forEach((variant) => 
    {
      var cardKey = this.Id + variant;
      var newCardState = this.state.cardState;
      if (this.props.valueBag[cardKey] !== this.state.cardValue[cardKey])
      {
        console.log("this card valuebag value changed!");
        var newCardValue = this.state.cardValue;
        newCardValue[cardKey] = this.props.valueBag[cardKey];
        console.log("New Value should be: " + newCardValue[cardKey]);
        if ((newCardValue[cardKey]) && (newCardValue[cardKey]>0))
        {
          newCardState = "touched";
        }
        if (newCardState !== this.state.cardState)
        {
          this.setState({cardState: newCardState});
        }
        this.setState({cardValue: newCardValue});
      };

    });
  }

  onTouchEnd(ev) {
    ev.preventDefault();
  }

  renderCalculatorIcon = () => {
    if ((this.props.calculatorEnabled) && (this.variants) && (this.variants.length === 1)){
    return (
      <FontAwesomeIcon
        icon={faCalculator}
        color="yellow"
        size="2x"
        style={{ float: "left", paddingLeft: "5px" }}
        onClick={(ev) => this.onCalculatorClick(ev)}
      />
    );
    }
    else {
      return <span/>
    }
  };

  onCalculatorClick(ev) {
    ev.preventDefault();
    ev.stopPropagation();
    this.props.onRequestCalculatorDialogOpen(this.Id);
  }



  renderCameraIcon = () => {
    return (
      <FontAwesomeIcon
        icon={faCamera}
        color="yellow"
        size="2x"
        style={{ float: "left", paddingLeft: "5px" }}
        onClick={(ev) => this.onCameraClick(ev)}
      />
    );
  };

  onCameraClick(ev) {
    ev.preventDefault();
    ev.stopPropagation();
    this.props.onRequestCameraDialogOpen(this.Id);
  }

  renderCriteriaIcon = () => {
    if (this.props.criteriaSlides) {
      return (
        <FontAwesomeIcon
          icon={faImage}
          color="yellow"
          size="2x"
          style={{ float: "right", paddingRight: "5px" }}
          onClick={(ev) => this.onCriteriaClick(ev)}
        />
      );
    } else {
      return <span />;
    }
  };

  onCriteriaClick(ev) {
    ev.preventDefault();
    ev.stopPropagation(); // stop bubbling up to the card itself
    this.props.onRequestCriteriaSlidesOpen(this.props.criteriaSlides);
  }

  onValueClick(id, incrementType) {
    console.log("ev: " + id + ":" + incrementType);
    var currentValue = this.state.cardValue[id];
    var newValue = 0;
    if (!currentValue || currentValue === "") {
      newValue = incrementType === "+" ? 1 : 0;
    } else {
      let incrementValue = incrementType === "+" ? 1 : -1;
      newValue = parseInt(currentValue) + incrementValue;
    }
    var newCardValue = this.state.cardValue;
    if (newValue === 0) {
      // remove this key
      deleteKey(newCardValue, id);
    } else {
      newCardValue[id] = newValue;
    }
    this.setState({ cardValue: newCardValue });

    //this.setState({
    //    ...this.state,
    //    cardValue: {
    //     ...this.state.cardValue,
    //      [id]: newValue
    //    }
    //})
    this.props.onValueChange(id, newValue);
  }

  onInputValueChange(id, ev) {
    console.log("value before processing: " + this.state.cardValue);
    console.log(
      "target incoming value before processing: " +
        ev.target.value +
        "type of " +
        typeof ev.target.value
    );
    var newCardValue;
    newCardValue = this.state.cardValue;
    newCardValue[id] = ev.target.value;
    if (
      ev.target.value !== 0 ||
      ev.target.value !== undefined ||
      ev.key !== "Backspace"
    ) {
      this.setState({
        cardValue: newCardValue,
      });
    }
    this.props.onValueChange(id, newCardValue[id]);
  }

  onValueChange(ev) {
    console.log(ev);
    console.log(ev.target.value);
    this.setState({ cardValue: ev.target.value });
    this.setState({ currentValue: ev.target.value });
    this.props.onValueChange(this.Id, ev.target.value);
  }

  onLoseFocus() {
    var newCardState = !isEmpty(this.state.cardValue) ? "touched" : "untouched";
    this.setState({ cardState: newCardState });
  }

  onCardClick(ev) {
    var newState = "untouched";
    if (this.state.cardState === "active") {
      newState = !isEmpty(this.state.cardValue) ? "touched" : "untouched";
    } else {
      newState = "active";
    }
    this.setState({ cardState: newState });
    this.props.onToggleCard(this);
  }

  renderUntouchedState() {
    return (
      <div
        className="nonactivecard nonactivecard-title shadow6 card-template"
        onClick={this.onCardClick}
      >
        {this.props.attribTitle}
        {this.renderCriteriaIcon()}
      </div>
    );
  }

  renderActiveState() {
    return (
      <div className="activecard shadow6 card-template">
        <div onClick={this.onCardClick} className="activecard-title">
          {this.props.attribTitle}

          {this.renderCriteriaIcon()}
          {this.renderCameraIcon()}
          {this.renderCalculatorIcon()}
        </div>
        <div className="inputContainer">
          {this.variants.map((variant, index) => (
            <InputGroup size="sm" className="activecard-input">
              <div
                className="paddle-left"
                onClick={() => this.onValueClick(this.Id + variant, "-")}
              >
                <FontAwesomeIcon icon={faAngleLeft} />
              </div>
              <FormControl
                plaintext
                inputMode="numeric"
                pattern="[0-9]*"
                //onTouchEnd={(ev) => this.onTouchEnd(ev)}
                textAlign="center"
                placeholder={variant ? variant : "enter value"}
                aria-describedby="basic-addon2"
                name={this.id + variant}
                value={
                  this.state.cardValue[this.Id + variant]
                    ? this.state.cardValue[this.Id + variant]
                    : ""
                }
                onChange={(ev) =>
                  this.onInputValueChange(this.Id + variant, ev)
                }
              />
              <div
                className="paddle-right"
                onClick={() => this.onValueClick(this.Id + variant, "+")}
              >
                <FontAwesomeIcon icon={faAngleRight} />
              </div>
            </InputGroup>
          ))}
        </div>
      </div>
    );
  }

  renderTouchedState() {
    return (
      <div
        className="nonactivecard touchedcard shadow7 card-template"
        onClick={this.onCardClick}
      >
        <div className="nonactivecard-title">
          {this.props.attribTitle}
          {this.renderCriteriaIcon()}
        </div>
        <div className="variant-wrapper">
          {this.variants.map((variant, index) => (
            <div style={{ padding: "20px" }}>
              {variant}{" "}
              {this.state.cardValue[this.Id + variant]
                ? this.state.cardValue[this.Id + variant]
                : "--"}
            </div>
          ))}
        </div>
      </div>
    );
  }

  renderState() 
  {
    if ((this.state.cardState === "untouched") || (this.state.cardState==="touched"))
    {
      this.setFromValueBag();
    }
    

    switch (this.state.cardState) {
      case "untouched":
        return this.renderUntouchedState();
      case "touched":
        return this.renderTouchedState();
      default:
        return this.renderActiveState();
    }
  }

  render() {
    return this.renderState();
  }
}
