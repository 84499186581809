import React, { Component } from "react";
import {
  InputGroup,
} from "react-bootstrap";
import "../../assets/css/attrib-cards.css";
import "react-dates/initialize";
import { SingleDatePicker } from "react-dates";
import moment from "moment";
import "react-dates/lib/css/_datepicker.css";
import {toCardId, toLocalShortDate} from "./AttribCardUtils.js";






export default class AttribDateCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardState: (this.props.valueBag[this.Id]) ? "touched" : "untouched",
      cardValue: this.props.valueBag[this.Id],
      valueAsDate: null,
      focused: true
    };
    this.Id = toCardId(this.props.attribTitle);

    this.renderState = this.renderState.bind(this);
    this.renderUntouchedState = this.renderUntouchedState.bind(this);
    this.renderActiveState = this.renderActiveState.bind(this);
    this.onCardClick = this.onCardClick.bind(this);
    this.onLoseFocus = this.onLoseFocus.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.formatValue = this.formatValue.bind(this);
  }


  componentDidUpdate(prevProps, prevState){
    let oldVal = prevState.cardValue;
    let newVal = this.props.valueBag[this.Id];
    //console.log("Id: " + this.Id + " Old: " + oldVal + " New: " + newVal);
    if (oldVal !== newVal){
      this.setState({
        cardValue: newVal,
        cardState: (newVal) ? "touched" : "untouched"
      });
    }
  }


  onValueChange(ev) {
    console.log(ev);
    var newDate = toLocalShortDate(moment(ev.target.value))
    this.setState({ cardValue: newDate });
    this.props.onValueChange(this.Id, newDate);
  }

  onLoseFocus() {
    var newCardState = (this.state.cardValue) ? "touched" : "untouched";
    this.setState({ cardState: newCardState });
  }

  onCardClick(ev) {
    var newState = "untouched";
    if (this.state.cardState === "active") {
      newState = (this.state.cardValue) ? "touched" : "untouched";
    } else {
      newState = "active";
    }
    this.setState({ cardState: newState });
    this.props.onToggleCard(this);
  }

  renderUntouchedState() {
    return (
      <div className="nonactivecard card-template shadow6" onClick={this.onCardClick}>
        {this.props.attribTitle}
      </div>
    );
  }


  onDateChange(newDate){
      // this should be a "moment" date
      var formattedDate = newDate.date.format("YYYY-MM-DD");
      this.setState({
        cardValue: formattedDate,
        cardState: "touched"
      });
      this.props.onValueChange(this.Id, formattedDate);
  }

  renderActiveState() {
    return (
      <div
        className="activecard shadow6 card-template"

      >
        <div onClick={this.onCardClick}>
          {this.props.attribTitle.toLowerCase()}
        </div>
          <InputGroup size="sm" className="active-calender-input">
          <SingleDatePicker
              small
              date={this.state.valueAsDate} // moment date
              showClearDate={false}
              isOutsideRange={() => false}
              minDate={moment().subtract(30, "days")}
              focused={this.state.focused}
              onFocusChange={({focused}) => this.setState({focused})}
              onDateChange={date => this.onDateChange({date})}
            />
          </InputGroup>
      </div>
    );
  }

  renderTouchedState() {
    return (
      <div
        className="nonactivecard touchedcard shadow7"
        onClick={this.onCardClick}
        style={{ backgroundColor: "lightgreen" }}
      >
        <div style={{ fontSize: "20px", padding: "5px" }}>
          {this.props.attribTitle.toUpperCase()}
        </div>
          <div
            style={{
              textAlign: "center",
              fontSize: "20px",
              fontColor: "purple",
              padding: "5px"
            }}
          >

            {(moment(this.state.cardValue).isValid()) ? this.formatValue(this.state.cardValue) : this.state.cardValue}
          </div>
      </div>
    );
  }

  formatValue(val){
    if (!this.props.display) return val;
    if (this.props.display.type==="datetime"){
      return moment(val).format(this.props.display.format);
    }
  }

  renderState() {
    switch (this.state.cardState) {
      case "untouched":
        return this.renderUntouchedState();
      case "touched":
        return this.renderTouchedState();
      default:
        return this.renderActiveState();
    }
  }

  render() {
    return this.renderState();
  }
}
