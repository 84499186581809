import React, { Component } from "react";
import { Modal, Row, Col, Container } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "../components/Buttons/CustomButton.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faSyncAlt} from "@fortawesome/free-solid-svg-icons";
import DeviceInfo from "../components/lib/Device/DeviceInfo.jsx";

import CacheBuster from "../CacheBuster.js";

import AuthService from "../components/AuthService/AuthService.js";
import GlasschainHttpClient from "../components/ApiService/GlasschainHttpClient.js";
import OfflineChef from "../components/DataService/OfflineChef.js";

export default class AuthLayout extends Component {
  constructor(props) {
    super(props);
    this.auth = new AuthService();
    this.coreClient = new GlasschainHttpClient();

    //if (performance.navigation.type === 1) {
    //  console.log("page REloaded");
    //  //alert( "This page is reloaded" );
    //  console.log(window.Pace);
      //window.Pace.stop();
    //} else {
    //  console.log("page FRESHLY loaded");
      //alert( "This page is not reloaded");
    //}

    this.state = {
      show: false,
      loadingMsg: "",
      cardHidden: true,
      gcid: this.auth.getGcid(),
      gcidError: true,
      gcidLogin: "",
      gcidErrorLogin: "",
      uid: this.auth.getUser(),
      uidError: true,
      uidLogin: "",
      uidErrorLogin: "",
      password: "",
      passwordError: true,
      passwordLogin: "",
      passwordErrorLogin: "",
      passwordType: "password",
      passwordShowLabel: "show",
      gcidLocations: [],
      location: this.auth.getLocation(),
      locationError: true,
      locationLogin: "",
      locationErrorLogin: "",
      isSyncingBuild: false,
      syncBuildMsg: "",
      cachedBuild: null,
      metaBuild: null,
      deviceInfo: new DeviceInfo().getDeviceInfo()
    };

    this.setShow = this.setShow.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleLoginSubmit = this.handleLoginSubmit.bind(this);
    this.handleUidChange = this.handleUidChange.bind(this);
    this.handleGcidChange = this.handleGcidChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleLocationChange = this.handleLocationChange.bind(this);

    this.togglePasswordState = this.togglePasswordState.bind(this);

    this.setGcidLocations = this.setGcidLocations.bind(this);

    this.rebuild = this.rebuild.bind(this);
    this.isSupportedBrowser = this.isSupportedBrowser.bind(this);
  }


  isSupportedBrowser()
  {
    return (
      (this.state.deviceInfo.browser.browserName.toLowerCase()==="chrome") 
      && (!this.state.deviceInfo.browser.isSamsungBrowser)
    );
  }

  togglePasswordState() {
    let lbl = this.state.passwordShowLabel;
    //let pType = this.state.passwordType;
    if (lbl === "show") {
      this.setState({
        passwordShowLabel: "hide",
        passwordType: "text",
      });
    } else {
      this.setState({
        passwordShowLabel: "show",
        passwordType: "password",
      });
    }
  }

  async rebuild(){
    this.setState({
      isSyncingBuild: true,
      syncBuildMsg: "Fetching latest build..."
    })
    var cacheBuster = new CacheBuster();
    await cacheBuster.isLatestBuild();
    cacheBuster.refreshCacheAndReload();
    //setTimeout(() => {  console.log("pausing while rebuilding"); }, 4000);
    this.setState({
      isSyncingBuild: false,
      syncBuildMsg: ""
    })
  }


  async componentDidMount() {
    this.setState({
      isSyncingBuild: true,
      syncBuildMsg: "Checking for latest build..."
    })

    console.log("Device info:");
    console.info(this.state.deviceInfo);
    var cacheBuster = new CacheBuster();
    var hasLatestBuild = await cacheBuster.isLatestBuild();
    this.setState({
      cachedBuild: cacheBuster.cachedVer,
      metaBuild: cacheBuster.buildVer
    })
    if (!hasLatestBuild){
      this.setState({
        isSyncingBuild: true,
        syncBuildMsg: "Updating from Cached Build " + cacheBuster.cachedVer + " to Latest Build (" + cacheBuster.buildVer + ")"
      })
      await cacheBuster.refreshCacheAndReload();
    }
    else {
      this.setState({
        isSyncingBuild: false,
        syncBuildMsg: "Current Build is " + cacheBuster.buildVer + " and Current Cached Version is " + cacheBuster.cachedVer
      });
    }
    
  


    if (navigator.platform.indexOf("Win") > -1) {
      //ps = new PerfectScrollbar(this.refs.mainPanel);
    }
    if (this.auth.loggedIn()) {
      this.props.history.replace("/auth");
    }
  }

  componentDidUpdate(e) {
    if (navigator.platform.indexOf("Win") > -1) {
      setTimeout(() => {
        this.handleShow();
      }, 5);
    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      //ps.destroy();
    }
    clearInterval(this.interval);
  }

  handleShow() {
    this.setShow(true);
  }

  handleClose() {
    //this.setShow(true);
  }

  setShow(shouldShow) {
    this.setState({
      show: shouldShow,
    });
  }

  async setGcidLocations(gcid, uid, pwd){
    // if we don't have gcid, uid AND password, then we can't fetch the entire list
    if (!this.isValidInputFormats(gcid, uid, pwd)){
      this.setState({gcidLocations: []});
      return;
    }
    // fetch valid locations for this gcid. This will also test if the user is valid. 
    // we do NOT allow fetching locations without a valid login. You can't fish for locations.
    this.auth.logout();
    var loginResponse = await this.auth.nonLocationLogin(gcid, uid, pwd);
    // re-establish the location in local storage because we idiotically REMOVE it during the previous line! Yes, terrible
    // use of changing state. YES I KNOW!
    this.auth.setLocation(this.state.location);
    if (loginResponse==='ok'){
      var orgConfig = await this.coreClient.fetchOrgConfiguration(gcid);
      var enabledLocations = orgConfig.data.locations.filter(loc=> {return loc.active});

      this.setState({
        gcidLocations: enabledLocations
      });
    }
    else {
      this.setState({gcidLocations: []});

    }
  }

  isValidInputFormats(gcid, uid, password){
    if (!gcid) return false;
    if (!uid) return false;
    if (!password) return false;
    if (gcid.length < 2) return false;
    if (uid.length < 2) return false;
    if (password.length < 6) return false;
    return true;
  }

  async handleLoginSubmit() {
    if (this.state.uid.length < 2) {
      this.setState({
        gcidErrorLogin: (
          <small className="text-danger">
            your Glasschain user id must be filled in. e.g. <i>dmo</i>.
          </small>
        ),
      });
      this.setState({ uidError: false });
    } else {
      this.setState({ uidErrorLogin: null });
      this.setState({ uidError: false });
    }

    if (this.state.gcid.length < 2) {
      this.setState({
        gcidErrorLogin: (
          <small className="text-danger">
            your glasschain company id must be filled in. e.g. <i>dmo</i>.
          </small>
        ),
      });
      this.setState({ gcidError: false });
    } else {
      this.setState({ gcidErrorLogin: null });
      this.setState({ gcidError: false });
    }
    if (this.state.password.length < 6) {
      this.setState({
        passwordErrorLogin: (
          <small className="text-danger">
            You must enter a password of at least 6 characters.
          </small>
        ),
      });
    } else {
      this.setState({ passwordErrorLogin: null });
      this.setState({ passwordError: false });
    }

    if ((!this.state.location) || (!this.state.gcidLocations) || (this.state.gcidLocations.filter(e => e.id === this.state.location).length < 1))  {
      this.setState({
        locationErrorLogin: (
          <small className="text-danger">You must Select a Location associated with your gcid.</small>
        ),
        locationError: true
      });
    } else {
      this.setState({ locationErrorLogin: null });
      this.setState({ locationError: false });
    }

    if (
      !this.state.gcidError &&
      !this.state.passwordError &&
      !this.state.uidError &&
      !this.state.locationError
    ) {
      this.setState({loadingMsg: "Logging in..."});
      this.auth.logout();
      var loginResponse = await this.auth.login2(this.state.gcid, this.state.uid, this.state.password, this.state.location);
      // confirm that we're actually logged in
      if (this.auth.loggedIn())
      {
        this.setState({loadingMsg: "Loading schemas..."}); 
        var offlineChef = new OfflineChef();
        var prepOfflineResponse = await offlineChef.prepOffline(this.state.gcid);
        // clear any previous search params
        this.auth.clearMainFilter();
        this.setState({loadingMsg: ""});
        this.props.history.replace("/home/home-page");
      } else {
        this.setState({ loadingMsg: "", gcidError: true });
        this.setState({ uidError: true });
        this.setState({ passwordError: true });
        this.setState({
          passwordErrorLogin: (
            <div>
              <small className="text-danger">
              Your gcid user id and password combination could not be
              authorized.
              </small>
            </div>
          ),
        });
      }
    }
  }



  async handleGcidChange(event) {
    var inputGcid = event.target.value.toLowerCase();
    this.setState({
      gcid: inputGcid,
    });
    if (event.target.value.length < 2) {
      this.setState({
        gcidErrorLogin: (
          <small className="text-danger">
            your glasschain company id must be filled in. e.g. <i>dmo</i>.
          </small>
        ),
      });
      this.setState({ gcidError: true });
    } else {
      this.setState({ gcidErrorLogin: null });
      this.setState({ gcidError: false });
      await this.setGcidLocations(inputGcid, this.state.uid, this.state.password);
    }
  }

  async handleUidChange(event) {
    var inputUid = event.target.value.toLowerCase().trim();

    this.setState({
      uid: inputUid,
    });
    if (event.target.value.length < 2) {
      this.setState({
        uidErrorLogin: (
          <small className="text-danger">
            your glasschain user id must be filled in. e.g. <i>dmo</i>.
          </small>
        ),
      });
      this.setState({ uidError: true });
    } else {
      this.setState({ uidErrorLogin: null });
      this.setState({ uidError: false });
      await this.setGcidLocations(this.state.gcid, inputUid, this.state.password);
    }
  }

  async handlePasswordChange(event) {
    var inputPwd = event.target.value;
    this.setState({
      password: inputPwd,
    });
    if (event.target.value.length < 6) {
      this.setState({
        passwordErrorLogin: (
          <small className="text-danger">
            You must enter a password of at least 6 characters.
          </small>
        ),
      });
      this.setState({ passwordError: true });
    } else {
      this.setState({ passwordErrorLogin: null });
      this.setState({ passwordError: false });
      await this.setGcidLocations(this.state.gcid, this.state.uid, inputPwd);
    }
  }

  handleLocationChange(event) {
    //alert(event.target.value);
    this.setState({
      location: event.target.value,
    });
    this.setState({ locationErrorLogin: null });
    this.setState({ locationError: false });
  }



  render() {
    var locations = (this.state.gcidLocations) ? 
      this.state.gcidLocations.map(loc => loc.id)
      : ["--choose company/user--"];

    if (locations.length === 0) {
      locations.push([ "--choose valid company/user--" ]);
    } else {
      if (
        !this.state.location ||
        !locations.some((el) => el === this.state.location)
      ) {
        console.log("Not able to assign default location " + this.state.location);
        this.setState({ location: locations[0] });
      }
    }


    const loadingIcon = (this.state.loadingMsg !== "") ? (
      <FontAwesomeIcon icon={faSyncAlt} color="green" spin />
    ) : (
      ""
    );

    const isSupportedBrowser = this.isSupportedBrowser();

    const modalLogin =         <Modal
    aria-labelledby="contained-modal-title-vcenter"
    show={true}
    onHide={this.handleClose}
    style={{opacity:1}}
  >
  
    <Modal.Header>
      <Modal.Title>
        <Container>
          <Row>
            <Col>Log In</Col>
            <Col>
              <img
                id="gclogo"
                src={require("../assets/images/logos/glasschain-qa-logo.png")}
                alt="glasschain logo"
              />
            </Col>
          </Row>
        </Container>
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Container>
        <Row>
          <Col>
            <form>
              <Form.Group>
                <Form.Label>Company</Form.Label>
                <Form.Control
                  placeholder={"company id"}
                  type="text"
                  value={this.state.gcid}
                  onChange={(event) => this.handleGcidChange(event)}
                />
                {this.state.gcidErrorLogin}
              </Form.Group>
              <Form.Group>
                <Form.Label>User Id</Form.Label>
                <Form.Control
                  placeholder="username"
                  type="text"
                  value={this.state.uid}
                  onChange={(event) => this.handleUidChange(event)}
                />
                {this.state.uidErrorLogin}
              </Form.Group>

              <Form.Group>
                <Form.Label>Password</Form.Label>
                <Form.Control
                  placeholder="password"
                  type={this.state.passwordType} //"password"
                  autoComplete="off"
                  onChange={(event) => this.handlePasswordChange(event)}
                />
                <span
                  //className="password-trigger"
                  onClick={this.togglePasswordState}
                >
                  {this.state.passwordShowLabel}
                </span>
                {this.state.passwordErrorLogin}
              </Form.Group>
              <Form.Group>
                <Form.Label>Location</Form.Label>
                <Form.Control
                  as="select"
                  placeholder="location"
                  type="dropdown"
                  autoComplete="on"
                  value={this.state.location}
                  onChange={(event) => this.handleLocationChange(event)}
                >
                  {locations.map((loc) => (
                    <option>{loc}</option>
                  ))}
                </Form.Control>

                {this.state.locationErrorLogin}
              </Form.Group>
            </form>
          </Col>
        </Row>
      </Container>
    </Modal.Body>
    <Modal.Footer>
      <Button
        variant="primary"
        onTouchTap={this.handleLoginSubmit}
        onMouseUp={this.handleLoginSubmit}
        onClick={this.handleLoginSubmit}
        disabled={!isSupportedBrowser}
      >
        Log In
      </Button>
      {(isSupportedBrowser) ? <span/> : 
      <span style={{color: "red", fontSize: "8pt"}}>&nbsp;&nbsp;Your browser is not supported</span>
      }

      <div> <span>{loadingIcon}</span>&nbsp;{this.state.loadingMsg}</div>
      <div style={{color: "grey", fontSize: "8pt"}}>Browser: {this.state.deviceInfo.browser.browserName} Version: {this.state.deviceInfo.browser.fullBrowserVersion}&nbsp;&nbsp;{(this.state.deviceInfo.browser.browserSubTypes.length>0) ? this.state.deviceInfo.browser.browserSubTypes.join('-'): ""}</div>
      <div style={{color: "grey", fontSize: "8pt"}}>Cached Build: {this.state.cachedBuild} Meta Build: {this.state.metaBuild}&nbsp;
        {(this.state.cachedBuild !== this.state.metaBuild) ? 
          <Button size="sm" style={{float: "right"}} onClick={this.rebuild}>reload</Button>
          : <span></span>
        }
      </div>
    </Modal.Footer>
  </Modal>


    return (
      <div>
            { (this.state.isSyncingBuild) ?
            <span style={{color: "white"}}>
              <FontAwesomeIcon icon={faSyncAlt} color="white" spin />
              &nbsp;{this.state.syncBuildMsg}
            </span>
            : 
            <div>
              {modalLogin}
            </div>

            }

        

      </div>
    );
  }
}
