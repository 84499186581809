export default class PtiParser {

 
    constructor(){
        this.fullCode = "";
        this.gtin = "";
        this.harvestDate = "";
        this.lot = "";
    }

    // returns what's left AFTER we parse this thing. 
    parsePtiElement(ptiString){ 
        // 
        let elId = ptiString.substring(0,2);
        console.log("ptiString: " + ptiString);
        console.log("elId: " + elId);
        switch(elId){
            case "01":
                // gtin
                if (ptiString.length<16) return "";
                this.gtin = ptiString.slice(2,16);
                return ptiString.slice(16).replace('(', '').replace(')', '');;
            case "13":
                // harvest date in yymmdd format
                if (ptiString.length<8) return "";
                this.harvestDate = ptiString.slice(2, 8);
                this.isoHarvestDate = "20" + this.harvestDate.slice(0,2) + "-" + this.harvestDate.slice(2,4) + "-" + this.harvestDate.slice(4,6);
                return ptiString.slice(8).replace('(', '').replace(')', '');;
            case "10":
                // lot is a little different 
                if (ptiString.length<3) return "";
                this.lot = ptiString.slice(2).replace('(', '').replace(')', '');; // go to the end of the string. We have to assume this is the LAST element since it's not fixed length
                return "";
            default:
                // we don't know what this is. So we're done since we can't parse anythng else. 
                return "";
        }
    }

    setPtiProp(codePair)
    {
        switch(codePair.code)
        {
            case "01":
                this.gtin = codePair.value;
                break;
            case "10":
                this.lot = codePair.value;
                break;
            case "13":
                this.harvestDate = codePair.value;
                this.isoHarvestDate = "20" + this.harvestDate.slice(0,2) + "-" + this.harvestDate.slice(2,4) + "-" + this.harvestDate.slice(4,6);
                break;
            default:
                break;
        } 

    }

    static parse(ptiCode){
        var result = new PtiParser();
        result.fullCode = ptiCode;
        // remove any parens
        if (ptiCode.length < 18) return result;
        var strippedCode = ptiCode.replace('(', '').replace(')', '');
        var whatsLeft = strippedCode;
        whatsLeft = result.parsePtiElement(whatsLeft);
        while(whatsLeft.length>0){
            console.log("whatsLeft: " + whatsLeft);
            whatsLeft = result.parsePtiElement(whatsLeft);
        }
        return result;
    }

    static parseV2(ptiCode)
    {
        var result = new PtiParser();
        result.fullCode = ptiCode;
        var split = ptiCode.split('(');
        // this should leave us with the codes, a right paren and the values
        var codePairs = split.map(s => 
            {
            if (s.trim() === ''){
                return {"code": '', "value": ''};
            }
            // we should have something like 01)3242343432423432
            var pair = s.split(')');
            return {"code": pair[0], "value": pair[1]};
            }
        );
        codePairs.forEach(cd => 
            {
                result.setPtiProp(cd);
            }
        );
        return result;
    }

    toFullHarvestDate(){
        return '20' + this.harvestDate.substring(0,2) + '-' + 
            this.harvestDate.substring(2,4) + '-' +
            this.harvestDate.substring(4,6);
    }
}