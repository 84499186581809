import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";

import './index.css';




import AuthLayout from "./layouts/AuthLayout.jsx";
import HomeLayout from "./layouts/HomeLayout.jsx";
import InspectionCardLayout from "./layouts/InspectionCardLayout.jsx";
import SurveyLayout from "./layouts/SurveyLayout.jsx";


ReactDOM.render(
    <HashRouter>
      <Switch>
        <Route key="auth" path="/auth" render={props => <AuthLayout {...props} />} />
        <Route key="homelayout" path="/home" render={props => <HomeLayout {...props} />} />
        <Route key="inspectioncardlayout" path="/inspectionnew" render={props => <InspectionCardLayout {...props}/>} />
        <Route key="surveylayout" path="/surveynew" render={props => <SurveyLayout {...props}/>} />
        <Redirect from="/" to="/auth" />
      </Switch>
    </HashRouter>,
    document.getElementById("root")
  );



