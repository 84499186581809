import React, { Component } from "react";
import { InputGroup } from "react-bootstrap";
import Select from 'react-select';
import { toCardId } from "./AttribCardUtils.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage, faCamera } from "@fortawesome/free-solid-svg-icons";

import "../../assets/css/attrib-cards.css";


export default class AttribDropdownCard extends Component {
  constructor(props) {
    super(props);

    this.Id = toCardId(this.props.attribTitle);

    this.state = {
      cardState: (this.props.valueBag[this.Id]) ? "touched" : "untouched",
      cardValue: this.props.valueBag[this.Id],
      otherValue: null,
      options: this.props.options,
      required: (this.props.validation) ? (this.props.validation.required) ? true : false : false
    };


    this.renderState = this.renderState.bind(this);
    this.renderUntouchedState = this.renderUntouchedState.bind(this);
    this.renderActiveState = this.renderActiveState.bind(this);
    this.onCardClick = this.onCardClick.bind(this);
    this.onLoseFocus = this.onLoseFocus.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.toOtherId = this.toOtherId.bind(this);
    this.onOtherChange = this.onOtherChange.bind(this);
    this.shouldShowOtherState = this.shouldShowOtherState.bind(this);
    this.renderCriteriaIcon = this.renderCriteriaIcon.bind(this);
    this.onCriteriaClick = this.onCriteriaClick.bind(this);

  }


  toOtherId() {
    return this.Id + "_other";
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.cardState === "active") return;
    let oldVal = prevState.cardValue;
    let newVal = this.props.valueBag[this.Id];
    //if (this.Id==="refermodel"){
    //  console.log("Id: " + this.Id + " Old: " + oldVal + " New: " + newVal);
    //}
    if (oldVal !== newVal) {
      this.setState({
        cardValue: newVal,
        //should change cardState somewhere else, this will close card before text box can render
        cardState: (newVal) ? "touched" : "untouched"
      });
    }
  }

  onValueChange(opt) {
    this.setState({ cardValue: opt.value });
    this.props.onValueChange(this.Id, opt.value);
    if (opt.value.toLowerCase() !== "other") {
      this.setState({
        cardState: "touched",
        otherValue: null
      });
      this.props.onValueRemove(this.toOtherId());
    }
  }

  onOtherChange(ev) {
    this.setState({ otherValue: ev.target.value });
    this.props.onValueChange(this.toOtherId(), ev.target.value);
  }

  onLoseFocus() {
    var newCardState = (this.state.cardValue) ? "touched" : "untouched";
    this.setState({ cardState: newCardState });
  }

  onCardClick(ev) {
    var newState = "untouched";
    if (this.state.cardState === "active") {
      newState = (this.state.cardValue) ? "touched" : "untouched";
    } else {
      newState = "active";
    }
    this.setState({ cardState: newState });
    this.props.onToggleCard(this);
  }

  renderUntouchedState() {
    return (
      <div className={(this.state.required) ? "nonactive-required card-template shadow8" : "nonactivecard card-template shadow6"} onClick={this.onCardClick}>
        {this.props.attribTitle}
        {this.renderCriteriaIcon()}
      </div>
    );
  }

  doesHaveOtherOptions() {
    if (this.Id === 'refermodel') {
      console.log(this.props.otherOptions);
    }
    return ((this.props.otherOptions) && (this.props.otherOptions.include));
  }

  shouldShowOtherState() {
    if (!this.props.otherOptions) return false;
    if (!this.props.otherOptions.include) return false;
    if (!this.state.cardValue) return false;
    if (this.state.cardValue.toLowerCase() !== "other") return false;
    return true;
  }

  renderCameraIcon = () => {
    return <FontAwesomeIcon icon={faCamera} color="yellow" size="2x" style={{ float: "left", paddingLeft: "5px" }} onClick={(ev) => this.onCameraClick(ev)} />
  }

  onCameraClick(ev) {
    ev.preventDefault();
    ev.stopPropagation();
    this.props.onRequestCameraDialogOpen(this.Id);
  }

  renderCriteriaIcon = () => {
    if (this.props.criteriaSlides) {
      return <FontAwesomeIcon icon={faImage} color="yellow" size="2x" style={{ float: "right", paddingRight: "5px" }} onClick={(ev) => this.onCriteriaClick(ev)} />
    }
    else {
      return <span />
    }
  }

  onCriteriaClick(ev) {
    ev.preventDefault();
    ev.stopPropagation();  // stop bubbling up to the card itself
    this.props.onRequestCriteriaSlidesOpen(this.props.criteriaSlides);
  }

  renderActiveState() {

    const otherDiv = (this.shouldShowOtherState()) ?
      <InputGroup className="textcard-input">
        <textarea
          plainText
          textAlign="center"
          placeholder="Enter Value for Other..."
          onChange={ev => this.onOtherChange(ev)}
        />
      </InputGroup>
      : <span></span>

    if (this.Id === "refermodel") {
      console.log("CardValue: " + this.state.cardValue);
    }
    let theme = "activecard card-template shadow6";
    let titleTheme = "";
    if (this.props.theme){
      theme = this.props.theme + "activecard " + this.props.theme+"card-template";
      titleTheme = "surveycardtitle";
    }
    return (

      <div
        className = {theme}
      >
        <div onClick={this.onCardClick}>
          <div className={titleTheme}>{this.props.attribTitle}</div>
          {this.renderCriteriaIcon()}
          {(this.props.hideCamera) ? "" : this.renderCameraIcon()}
        </div>

        <InputGroup size="sm" className="dropdowncard-input">
          <Select
            autoFocus
            placeholder={this.state.cardValue}
            options={this.state.options}
            onChange={opt => this.onValueChange(opt)}
            isSearchable={false}
          />
        </InputGroup>
        {otherDiv}
      </div>
    );
  }

  renderTouchedState() {
    return (
      <div
        className="nonactivecard card-template touchedcard shadow7"
        onClick={this.onCardClick}
        style={{ backgroundColor: "lightgreen" }}
      >
        <div style={{ fontSize: "20px" }}>
          {this.props.attribTitle.toUpperCase()}
          {this.renderCriteriaIcon()}
        </div>
        <div
          style={{
            textAlign: "center",
            fontSize: "18px",
            fontColor: "purple",
            maxWidth: "5em"
          }}
        >
          {this.state.cardValue}
        </div>
        <div
          style={{
            textAlign: "center",
            fontSize: "14px",
            fontColor: "purple",
          }}
        >
          {this.state.otherValue}
        </div>

      </div>
    );
  }

  renderState() {

    if (!this.props.theme) {

      switch (this.state.cardState) {
        case "untouched":
          return this.renderUntouchedState();
        case "touched":
          return this.renderTouchedState();
        default:
          return this.renderActiveState();
      }
    } else {
      return this.renderActiveState();
    }
  }

  render() {
    return this.renderState();
  }
}
