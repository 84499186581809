import RequestService from "./RequestService.js";
import AppConfig from "../AppConfig/AppConfig.js";
import AuthService from "../AuthService/AuthService.js";
import axios from "axios";
import {v1 as uuidv1} from 'uuid';

import GlassbitHasher from "../Security/GlassbitHasher.js";

import { fakeFamousResultData } from "../../data/FakeFamousTagLookupResult.js";

function toCamel(o) {
  var newO, origKey, newKey, value;
  if (o instanceof Array) {
    return o.map(function (value) {
      if (typeof value === "object") {
        value = toCamel(value);
      }
      return value;
    });
  } else {
    newO = {};
    for (origKey in o) {
      if (o.hasOwnProperty(origKey)) {
        newKey = (
          origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey
        ).toString();
        value = o[origKey];
        if (
          value instanceof Array ||
          (value !== null && value.constructor === Object)
        ) {
          value = toCamel(value);
        }
        newO[newKey] = value;
      }
    }
  }
  return newO;
}

export default class GlasschainHttpClient {
  // Initializing important variables
  constructor() {
    this.appConfig = new AppConfig();
    this.domain = this.appConfig.getCoreServiceHost(); //'https://gc-core-svc.azurewebsites.net' // API server domain
    this.requestService = new RequestService(this.domain);
    this.authService = new AuthService();
  }

  async pingCore() {
    try {
      const url = "/api/dashboard/coreping";
      let result = await this.requestService.fetchJson(url);
      return result.data === "ping";
    } catch (err) {
      console.log(err);
      return false;
    }
  }

  async pingCoreDb() {
    try {
      const url = "/api/dashboard/coredbping";
      let result = await this.requestService.fetchJson(url);
      return result.data === "ping";
    } catch (err) {
      console.log(err);
      return false;
    }
  }

  async postEmailRequest(gcid, emailRequest) {
    let loggedInGcid = this.authService.getGcid();
    const path = "/api/info/gcid/" + loggedInGcid + "/observation/email";
    var url = path;
    // add host/prefixes to all links
    if (emailRequest.links) {
      var fullUrlLinks = emailRequest.links.map((link) => {
        return {
          description: link.description,
          url:
            this.appConfig.getObsImageHostAddr(loggedInGcid, "user") + link.url,
        };
      });
      emailRequest.links = fullUrlLinks;
    }
    console.log(JSON.stringify(emailRequest));
    let postResult = await this.requestService.postJson(
      url,
      JSON.stringify(emailRequest),
      true
    );
    return postResult;
  }

  async postQaObservationVoid(voidEvent) {
    try {
      //var evHash = new GlassbitHasher().hashFnv32a(voidEvent);
      voidEvent.eventSnapshot = JSON.stringify(voidEvent);
      //let loggedInGcid = this.authService.getUser();
      const path = "/api/observations/qaobservationvoid";
      var url = path;
      let postResult = await this.requestService.postJson(url, voidEvent, true);
      return postResult;
    } catch (err) {
      console.log(err);
      throw new Error(err); // stupid. why not just let it flow? We might want to change it, though.
    }
  }

  async postQaObservationUpdate(obs, updateEvent) {
    try {
      var obsHash = new GlassbitHasher().hashFnv32a(obs);
      // CONVERT into postable inspection observation object
      var toQaObservation = {
        Id: obs.identifiers.id,
        Gcid: this.authService.getGcid(),
        ObservedBy: obs.data.UnderInspection.Inspector,
        ObservationLocation: obs.data.UnderInspection.Location,
        ObservationDate: obs.data.UnderInspection.InspectionDate,
        ObservationType: "QADOCKINSPECTION",
        RecordedDate: obs.data.UnderInspection.InspectionDate,
        SchemaId: obs.data.schema.id,
        SchemaVersion: obs.data.schema.schemaVersion,
        ObservationHash: obsHash,
        ScoreReason: obs.score.Score.ScoreReason,
        ScoreGrade: (obs.score.Score.Grade.Weighted) ? obs.score.Score.Grade.Weighted: obs.score.Score.Grade.Raw,
        ScoreGradeInt: 0,
        ObservationSnapshot: JSON.stringify(obs),
      };

      //var evHash = new GlassbitHasher().hashFnv32a(updateEvent);
      updateEvent.eventSnapshot = JSON.stringify(updateEvent);
      var postData = { Observation: toQaObservation, Event: updateEvent };
      //let loggedInGcid = this.authService.getUser();
      const path = "/api/observations/qaobservationupdate";
      var url = path;
      let postResult = await this.requestService.postJson(url, postData, true);
      return postResult;
    } catch (err) {
      console.log(err);
      throw new Error(err); // stupid. why not just let it flow? We might want to change it, though.
    }
  }

  async postV2QaObservation(gcid, obs, onlineId) {
    try {
      var obsHash = new GlassbitHasher().hashFnv32a(obs);
      console.group("Pre-post after environment sync");
      console.log(obs);
      console.log(obs.data);
      console.groupEnd();
      let hasImages =
        obs.data.Raw.userimages && obs.data.Raw.userimages.length > 0;
      obs.data.hasImages = hasImages;
      // CONVERT into postable inspection observation object
      var toQaObservation = {
        Id: onlineId,
        Gcid: gcid,
        ObservedBy: obs.data.UnderInspection.Inspector,
        ObservationLocation: (obs.data.UnderInspection.Location) ? obs.data.UnderInspection.Location: "Unknown",
        ObservationDate: obs.data.UnderInspection.InspectionDate,
        ObservationType: "QADOCKINSPECTION",
        RecordedDate: obs.data.UnderInspection.InspectionDate,
        SchemaId: obs.data.schema.id,
        SchemaVersion: obs.data.schema.schemaVersion,
        ObservationHash: obsHash,
        ScoreReason: obs.score.Score.ScoreReason,
        ScoreGrade: (obs.score.Score.Grade.Weighted) ? obs.score.Score.Grade.Weighted: obs.score.Score.Grade.Raw,
        ScoreGradeInt: 0,
        HasImages: hasImages,
        ObservationSnapshot: JSON.stringify(obs),
      };
      //let loggedInGcid = this.authService.getUser();
      const path = "/api/observations/qaobservationpost";
      var url = path;
      let postResult = await this.requestService.postJson(
        url,
        toQaObservation,
        true
      );
      return postResult;
    } catch (err) {
      console.log(err);
      throw new Error(err); // stupid. why not just let it flow? We might want to change it, though.
    }
  }

  async postQaObservation(obs) {
    try {
      var obsHash = new GlassbitHasher().hashFnv32a(obs);
      // CONVERT into postable inspection observation object
      var toQaObservation = {
        Gcid: obs.data.oui.gcid,
        ObservedBy: obs.data.oui.inspector,
        ObservationLocation: obs.data.oui.location,
        ObservationDate: obs.data.oui.inspectDate,
        ObservationType: "QADOCKINSPECTION",
        RecordedDate: obs.data.oui.inspectDate,
        SchemaId: obs.data.oui.schemaId,
        SchemaVersion: obs.data.oui.schemaVersion,
        ObservationHash: obsHash,
        ScoreReason: obs.score.Score.ScoreReason,
        ScoreGrade: obs.score.Score.Grade.Weighted,
        ScoreGradeInt: 0,
        ObservationSnapshot: JSON.stringify(obs),
      };
      //let loggedInGcid = this.authService.getUser();
      const path = "/api/observations/qaobservationpost";
      var url = path;
      let postResult = await this.requestService.postJson(
        url,
        toQaObservation,
        true
      );
      return postResult;
    } catch (err) {
      console.log(err);
      throw new Error(err); // stupid. why not just let it flow? We might want to change it, though.
    }
  }

  async fetchAzureStorageSasUri(imgId, ext) {
    try {
      let gcid = this.authService.getGcid();
      const path =
        "/api/observations/gcid/" + gcid + "/id/" + imgId + "/ext/" + ext;
      let sasUriResponse = await this.requestService.fetchJson(path);
      return sasUriResponse.data;
    } catch (err) {
      console.log(err);
      throw new Error(err);
    }
  }

  // v2 schema
  async fetchObservationList(qString) {
    try {
      let gcid = this.authService.getGcid();
      //let qString = searchParams.asQueryString();
      console.log(qString);
      const path = "/api/observations/gcid/" + gcid + "/qaobservation";
      var url = path + "?" + qString;
      let observations = await this.requestService.fetchJson(url);
      return observations;
    } catch (err) {
      console.log(err);
      throw new Error(err); // stupid. why not just let it flow? We might want to change it, though.
    }
  }

  async fetchObservations(gcid, searchParams) {
    try {
      //let loggedInGcid = this.authService.getGcid();
      let qString = searchParams.toQueryString();
      const path = "/api/observations/gcid/" + gcid + "/qaobservation";
      var url = path + "?" + qString;
      let observations = await this.requestService.fetchJson(url);
      return observations;
    } catch (err) {
      console.log(err);
      throw new Error(err); // stupid. why not just let it flow? We might want to change it, though.
    }
  }



  async postDirectNotificationRequest(obsNotification){
    let gcid = this.authService.getGcid();
    obsNotification.id = uuidv1();
    /*
    var notificationPost = {"Id": uuidv1(), "Gcid": gcid, "SourceService": "glasschain-qa-app", "SourceId": obsId, 
      "SourceDescript": "Observations", "SourceMessage": "Posting Notification Request",
      "RequestedBy": obsNotification.requestedBy, "RequestDate": obsNotification.requestDate, 
    }
    */
    const url = "/api/notification/gcid/" + gcid + "/directtemplaterequest";
    let notificationDomain = this.appConfig.getNotificationServiceHost();
    let requestSvc = new RequestService(notificationDomain);
    console.log(obsNotification);
    let postResult = await requestSvc.postJson(url, obsNotification, true);
    console.log(postResult);
    return postResult;
  } catch (err) {
    console.log(err);
    throw new Error(err); // stupid. why not just let it flow? We might want to change it, though.
  }

  async postNotificationRequest(obsNotification, obsId){
    let gcid = this.authService.getGcid();
    var triggeredRules = obsNotification.rules.filter(rule => rule.triggered);
    console.log("posting the following triggered rules: ");
    console.log(triggeredRules);
    var notificationPost = {"Id": uuidv1(), "Gcid": gcid, "SourceService": "glasschain-qa-app", "SourceId": obsId, "SourceDescript": "Observations", "SourceMessage": "Posting Notification Request",
                      "RequestedBy": obsNotification.requestedBy, "RequestDate": obsNotification.requestDate, 
                      "rules": triggeredRules}
    const url = "/api/notification/gcid/" + gcid + "/templaterequest";
    let notificationDomain = this.appConfig.getNotificationServiceHost();
    let requestSvc = new RequestService(notificationDomain);
    console.log(notificationPost);
    let postResult = await requestSvc.postJson(url, notificationPost, true);
    console.log(postResult);
    return postResult;
  } catch (err) {
    console.log(err);
    throw new Error(err); // stupid. why not just let it flow? We might want to change it, though.
  }

  

  async fetchProxyLookup(gcid, urlFragment, idField, valueField) {
    try {
      //let loggedInGcid = this.authService.getGcid();
      const path = "/api/adapter/gcid/" + gcid + "/" + urlFragment;
      var url = path;
      let proxyDomain = this.appConfig.getAdapterProxyHostAddress(gcid)
        .hostaddr;
      let proxyRequestService = new RequestService(proxyDomain);
      let proxyResult = await proxyRequestService.fetchJson(url);
      // convert proxy result into id/value list
      var result = proxyResult.data.map((rec) => {
        return { id: rec[idField], value: rec[valueField] };
      });
      console.log(result);
      return result;
    } catch (err) {
      console.log(err);
      throw new Error(err); // stupid. why not just let it flow? We might want to change it, though.
    }
  }

  async hasProxyEntity(gcid, urlFragment, entityId) {
    try {
      const path = "/api/adapter/gcid/" + gcid + urlFragment + entityId;
      var url = path;
      let proxyDomain = this.appConfig.getAdapterProxyHostAddress(gcid)
        .hostaddr;
      let proxyRequestService = new RequestService(proxyDomain);
      let entityExistsResult = await proxyRequestService.fetchJson(url);
      let entityExists = entityExistsResult.data.data;

      //let entityExists = true;
      console.log(entityExists);
      return entityExists;
    } catch (err) {
      console.log(err);
      throw new Error(err); // stupid. why not just let it flow? We might want to change it, though.
    }
  }

  async fetchProxyEntity(gcid, urlFragment, entityId) {
    try {
      //let loggedInGcid = this.authService.getGcid();
      const path = "/api/adapter/gcid/" + gcid + urlFragment + entityId;
      var url = path;
      let proxyDomain = this.appConfig.getAdapterProxyHostAddress(gcid)
        .hostaddr;
      let proxyRequestService = new RequestService(proxyDomain);
      let entityResult = await proxyRequestService.fetchJson(url);
      let entity = entityResult.data.data;
      //let entity = fakeFamousResultData;
      console.log(entity);
      return entity;
    } catch (err) {
      console.log(err);
      throw new Error(err); // stupid. why not just let it flow? We might want to change it, though.
    }
  }

  async hasProxyPallet(gcid, palletTagNbr) {
    try {
      console.log("HELO");
      console.log("param gcid: " + gcid);
      console.log("param palletTagNbr: " + palletTagNbr);
      //let loggedInGcid = this.authService.getGcid();
      const path =
        "/api/adapter/gcid/" + gcid + "/palletexists/" + palletTagNbr;
      var url = path;
      let proxyDomain = this.appConfig.getAdapterProxyHostAddress(gcid)
        .hostaddr;
      let proxyRequestService = new RequestService(proxyDomain);
      console.log("proxyDomain: " + proxyDomain);
      let palletExists = await proxyRequestService.fetchJson(url);
      console.log(palletExists);
      return palletExists;
    } catch (err) {
      console.log(err);
      throw new Error(err); // stupid. why not just let it flow? We might want to change it, though.
    }
  }

  async fetchProxyPallet(gcid, palletTagNbr) {
    try {
      //let loggedInGcid = this.authService.getGcid();
      const path = "/api/adapter/gcid/" + gcid + "/pallet/" + palletTagNbr;
      var url = path;
      let proxyDomain = this.appConfig.getAdapterProxyHostAddress(gcid)
        .hostaddr;
      let proxyRequestService = new RequestService(proxyDomain);
      let pallet = await proxyRequestService.fetchJson(url);
      console.log(pallet);
      return pallet;
    } catch (err) {
      console.log(err);
      throw new Error(err); // stupid. why not just let it flow? We might want to change it, though.
    }
  }

  async canSetPalletHold(gcid, palletTagNbr) {
    try {
      //let loggedInGcid = this.authService.getGcid();
      const path =
        "gcid/" +
        gcid +
        "/api/adapter/gcid/" +
        gcid +
        "/pallet/" +
        palletTagNbr +
        "/canhold";
      var url = path;
      let proxyDomain = this.appConfig.getAdapterProxyHost(gcid);
      let proxyRequestService = new RequestService(proxyDomain);
      let canHoldSvcDataResult = await proxyRequestService.fetchJson(url);
      console.log(canHoldSvcDataResult);
      let canHold = canHoldSvcDataResult.data;
      // if not, then we should report back why
      return canHold;
    } catch (err) {
      console.log(err);
      throw new Error(err); // stupid. why not just let it flow? We might want to change it, though.
    }
  }

  async postPalletHold(gcid, palletHoldRequest) {
    try {
      //let loggedInGcid = this.authService.getGcid();
      const path =
        "gcid/" +
        gcid +
        "/api/adapter/gcid/" +
        gcid +
        "/pallet/" +
        palletHoldRequest.PalletLicenseNbr +
        "/holdRequest";
      var url = path;
      let proxyDomain = this.appConfig.getAdapterProxyHost(gcid);
      let proxyRequestService = new RequestService(proxyDomain);
      let holdRequestSvcDataResult = await proxyRequestService.postJson(
        url,
        palletHoldRequest
      );
      console.log(holdRequestSvcDataResult);
      let holdRequestResult = holdRequestSvcDataResult.data;
      // if not, then we should report back why
      return holdRequestResult;
    } catch (err) {
      console.log(err);
      throw new Error(err); // stupid. why not just let it flow? We might want to change it, though.
    }
  }

  async fetchUserList() {
    try {
      let loggedInGcid = this.authService.getGcid();
      const path = "/api/users/gcid/" + loggedInGcid + "/userlist";
      var url = path;
      let userList = await this.requestService.fetchJson(url);
      return userList;
    } catch (err) {
      console.log(err);
      throw new Error(err); // stupid. why not just let it flow? We might want to change it, though.
    }
  }

  isSuccessfulStatus(status) {
    let cleanedStatus = status.toLowerCase().replace(" ", "");
    switch (cleanedStatus) {
      case "200":
      case "tooearly":
      case "ok":
      case "created":
        return true;
      default:
        return false;
    }
  }

  async fetchPartnerInfo() {
    try {
      let loggedInGcid = this.authService.getUser();
      const path = "/api/admin/cache/gcid/" + loggedInGcid + "/partnerconfigs";
      var url = path;
      let partnerInfo = await this.requestService.fetchJson(url);
      return partnerInfo.data.partnerships;
    } catch (err) {
      console.log(err);
      throw new Error(err); // stupid. why not just let it flow? We might want to change it, though.
    }
  }

  async fetchOrgConfiguration() {
    try {
      let gcid = this.authService.getGcid();
      //let qString = "status=" + postStatus;
      const path = "/api/observations/dashboard/gcid/" + gcid + "/orgconfig";
      var url = path; // + "?" + qString;
      let result = await this.requestService.fetchJson(url);
      return result;
    } catch (err) {
      console.log(err);
      throw new Error(err); // stupid. why not just let it flow? We might want to change it, though.
    }
  }



  async fetchObservationGridSchema(schemaId) {
    try {
      let gcid = this.authService.getGcid();
      //let qString = "status=" + postStatus;
      const path =
        "/api/observations/dashboard/gcid/" + gcid + "/schema/" + schemaId;
      var url = path; // + "?" + qString;
      let result = await this.requestService.fetchJson(url);
      return result;
    } catch (err) {
      console.log(err);
      throw new Error(err); // stupid. why not just let it flow? We might want to change it, though.
    }
  }

  async fetchObservationFullSchema(schemaId) {
    try {
      let gcid = this.authService.getGcid();
      //let qString = "status=" + postStatus;
      const path =
        "/api/observations/schema/gcid/" + gcid + "/schema/full/" + schemaId;
      var url = path; // + "?" + qString;
      let result = await this.requestService.fetchJson(url);
      return result;
    } catch (err) {
      console.log(err);
      throw new Error(err); // stupid. why not just let it flow? We might want to change it, though.
    }
  }


  async translate(gcid, toLang, textToTranslate) {
    try {
      let gcid = this.authService.getGcid();
      //let qString = "status=" + postStatus;
      const path =
        "/api/observations/gcid/" +gcid +"/translation/to/" + toLang + "/" + textToTranslate
      var url = path; // + "?" + qString;
      let data = await this.requestService.fetchJson(url);
      console.log("translated data: ");
      console.log(data);
      console.log(data.data);
      console.log(data.data.data);
      console.log(data.data.data.data.translations[0])
      return data.data.data.data.translations[0].translatedText; // take the FIRST result from the result set
    } catch (err) {
        console.log(err);
        throw new Error(err); // stupid. why not just let it flow? We might want to change it, though.
    }
  }

  async fetchAddressData(gcid, lat, lng) {
    try {
      let gcid = this.authService.getGcid();
      //let qString = "status=" + postStatus;
      const path =
        "/api/observations/dashboard/gcid/" +
        gcid +
        "/address/lat/" +
        lat +
        "/lng/" +
        lng;
      var url = path; // + "?" + qString;
      let data = await this.requestService.fetchJson(url);
      return { data: data.data.results[0] }; // take the FIRST result from the result set
    } catch (err) {
      console.log(err);
      throw new Error(err); // stupid. why not just let it flow? We might want to change it, though.
    }
  }

  async fetchWeatherData(gcid, lat, lng) {
    try {
      let gcid = this.authService.getGcid();
      //let qString = "status=" + postStatus;
      const path =
        "/api/observations/dashboard/gcid/" +
        gcid +
        "/weather/current/lat/" +
        lat +
        "/lng/" +
        lng;
      var url = path; // + "?" + qString;
      let data = await this.requestService.fetchJson(url);
      // raw json will NOT return camelCase! Concern here is inefficiency
      var parsedData = JSON.parse(data.data.current);
      var result = toCamel(parsedData);
      return { data: result };
    } catch (err) {
      console.log(err);
      throw new Error(err); // stupid. why not just let it flow? We might want to change it, though.
    }
  }

  async fetchHistoricalWeatherData(gcid, lat, lng, isoInspectionDateTime) {
    try {
      let gcid = this.authService.getGcid();
      //let qString = "status=" + postStatus;
      const path =
        "/api/observations/dashboard/gcid/" +
        gcid +
        "/weather/historical/lat/" +
        lat +
        "/lng/" +
        lng +
        "/date/" +
        isoInspectionDateTime;
      var url = path; // + "?" + qString;
      let data = await this.requestService.fetchJson(url);
      // raw json will NOT return camelCase! Concern here is inefficiency
      var parsedData = JSON.parse(data.data.current);
      var result = toCamel(parsedData);
      return { data: result };
    } catch (err) {
      console.log(err);
      throw new Error(err); // stupid. why not just let it flow? We might want to change it, though.
    }
  }

  imageTypeFromMimeType(mimeType)
  {
      var tokenize1 = mimeType.split("/");
      var tokenize2 = tokenize1[1].split(";");
      var result = tokenize2[0];
      return result;
  }

  async fetchTextRecognition(dataUri) {
    //console.log("dataUri: ");
    //console.log(dataUri);
    var tokens = dataUri.split(",");
    var mimeType = tokens[0];
    var b64Payload = tokens[1];
    var imageType = this.imageTypeFromMimeType(mimeType);
    var data = {
      requests: [
        {
          image: {
            content: b64Payload,
          },
          features: [
            {
              type: "TEXT_DETECTION",
              maxResults: 5,
            },
          ],
        },
      ],
    };
    var fullUrl =
      "https://vision.googleapis.com/v1/images:annotate?key=" +
      this.appConfig.getGoogleApiKey();
    var config = { headers: { "Content-Type": "application/json" } }; //'multipart/form-data' }}
    try {
      const response = await axios.post(fullUrl, data, config);
      if (this.statusOk(response)) {
        //const jsonResponse = await response.json();
        let array = response.data.responses[0].textAnnotations;
        var annotations = array.map((ar) => {
          return ar.description;
        });
        return annotations;
      } else {
        throw new Error(response.status + response.statusText);
      }
      /*
        await axios({
            method: 'post',
            url: 'https://vision.googleapis.com/v1/images:annotate?key=' + this.appConfig.getGoogleApiKey(),
            data,
            config: { headers: {'Content-Type': 'application/json'}} //'multipart/form-data' }}
          })
          .then((r) => {
            //console.log("result")
            //console.log(r);
            let array = r.data.responses[0].textAnnotations;
            //console.log("annotations: ");
            //console.log(array);
            var annotations = array.map((ar) => {
                return ar.description;
            });
            console.log('result: ');
            console.log(annotations);
            return {annotations: annotations};
            /*
            for (let x = 1; x< array.length; x++){
               if(array[x].description.includes('-')){
                    return array[x].description;
                           //return this.props.cameraOffAndSetInput(array[x].description)
              }
            }
            */
    } catch (error) {
      //.catch((error) => {
      console.log("Google Text recognition Error");
      console.log(error);
    }
    //})
  }

  statusOk(response) {
    return response.status >= 200 && response.status < 300;
  }
}
