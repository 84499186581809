import {BlobServiceClient, BlockBlobClient} from '@azure/storage-blob';
import {TransferProgressEvent} from '@azure/core-http';
import GlasschainHttpClient from "../ApiService/GlasschainHttpClient.js";
import { result } from 'lodash';


function base64toBlob(base64Data, contentType) {
    contentType = contentType || '';
    var sliceSize = 1024;
    var byteCharacters = atob(base64Data);
    var bytesLength = byteCharacters.length;
    var slicesCount = Math.ceil(bytesLength / sliceSize);
    var byteArrays = new Array(slicesCount);

    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        var begin = sliceIndex * sliceSize;
        var end = Math.min(begin + sliceSize, bytesLength);

        var bytes = new Array(end - begin);
        for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
            bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
}

export default class BlobStorageService {
    constructor(){
        this.account = 'gcblobstoredurable1'; 
        //this.blobSasUrl = 'https://gcblobstoredurable1.blob.core.windows.net/?sv=2019-10-10&ss=b&srt=sco&sp=rwdlacx&se=2020-07-04T11:02:14Z&st=2020-07-04T03:02:14Z&spr=https,http&sig=LyLJUi%2BnWHsG1FthUYursp7ahFWhsY8DIxsiWlIkWG8%3D';
        //this.blobServiceClient = new BlobServiceClient(this.blobSasUrl);
        this.apiClient = new GlasschainHttpClient();

    }

    imageTypeFromMimeType(mimeType)
    {
        var tokenize1 = mimeType.split("/");
        var tokenize2 = tokenize1[1].split(";");
        var result = tokenize2[0];
        return result;
    }

    // png, jpeg, etc. 
    async uploadUserImage(gcid, userImageString, imageId){
            if (!userImageString) return;
            // get the mime type
            var tokens = userImageString.split(",");
            var mimeType = tokens[0];
            var b64Payload = tokens[1];
            var imageType = this.imageTypeFromMimeType(mimeType);
            // fetch the sas Uri
            let sasUri = await this.apiClient.fetchAzureStorageSasUri(imageId, imageType);
            //var blobServiceClient = new BlobServiceClient(sasUri);
            var blockBlobClient = new BlockBlobClient(sasUri);
            //var contentType = 'image/' + imageType;
            //console.log(userImageString);
            // we have to strip off the data tag at the beginning of the file
            //let cleanedImageString = userImageString.replace('data:image/' + imageType + ';base64,', '');
            var blob = base64toBlob(b64Payload);
            //console.group("blob content length:");
            //console.log(blob.size);
            const uploadBlobResponse = await blockBlobClient.upload(blob, blob.size);
            console.log('Upload block blob ${blobName} successfully', uploadBlobResponse.requestId); 

        /*
        const containerName = "gcid";
        const containerClient = this.blobServiceClient.getContainerClient(containerName);
        const content = (userImageString) ? userImageString: "fake user image string"; // userImageString
        const blobName = (imageId) ? imageId : "fakeImg.txt";
        const blockBlobClient = containerClient.getBlockBlobClient(blobName);
        const uploadBlobResponse = await blockBlobClient.upload(content, content.length);
        console.log('Upload block blob ${blobName} successfully', uploadBlobResponse.requestId);
        */
    }

    async uploadPdf(gcid, blob, imageId){
        // fetch the sas Uri
        let sasUri = await this.apiClient.fetchAzureStorageSasUri(imageId, 'pdf');
        //var blobServiceClient = new BlobServiceClient(sasUri);
        var blockBlobClient = new BlockBlobClient(sasUri);
        const uploadBlobResponse = await blockBlobClient.upload(blob, blob.size);
        console.log('Uploaded block blob ${blobName} successfully', uploadBlobResponse.requestId); 

    /*
    const containerName = "gcid";
    const containerClient = this.blobServiceClient.getContainerClient(containerName);
    const content = (userImageString) ? userImageString: "fake user image string"; // userImageString
    const blobName = (imageId) ? imageId : "fakeImg.txt";
    const blockBlobClient = containerClient.getBlockBlobClient(blobName);
    const uploadBlobResponse = await blockBlobClient.upload(content, content.length);
    console.log('Upload block blob ${blobName} successfully', uploadBlobResponse.requestId);
    */
}

}