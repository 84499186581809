import React, { Component } from "react";
import moment from "moment";

export default class IsoUtcDateTimeToLocalRenderer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      //value: props.value
    };
  }

  formatIsoDateTime(isoDtTime) {
    console.log(isoDtTime);
    var cellVal = moment(isoDtTime).format("dddd, MMMM Do YYYY, h:mm");
    console.log(cellVal);
    //var cellVal = value.slice(0, -9).replace("T", " "); // remove UTC offset and seconds
    return <span> {cellVal}</span>;
  }

  refresh() {
    return false; // tell underlying grid to reconstruct
  }

  render() {
    return <span>{this.formatIsoDateTime(this.props.value)}</span>;
  }
}
