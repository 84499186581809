/*
based on code found at:
https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript
Note that this should match the C# library GlassbitHasher to produce same results
*/


export default class GlassbitHasher{
    constructor(){
        this.FNV_OFFSET_32 = 0x811c9dc5;
    }


    hashFnv32a(input) {
        var hval = this.FNV_OFFSET_32;
    
        // Strips unicode bits, only the lower 8 bits of the values are used
        for (var i = 0; i < input.length; i++) {
            hval = hval ^ (input.charCodeAt(i) & 0xFF);
            hval += (hval << 1) + (hval << 4) + (hval << 7) + (hval << 8) + (hval << 24);
        }
    
        return hval >>> 0;
    }
    
    toHex(val) {
        return ("0000000" + (val >>> 0).toString(16)).substr(-8);
    }

}




