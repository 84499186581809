import {openDB, idb} from 'idb';
import moment from "moment"


export default class IDbDocService{
    constructor(){
        this.defaultObjectStore = "docs";
        this.iDb = {
            docDb: openDB("docDb", 1,{
                upgrade: (db, oldVersion, newVersion, transaction) => {
                    switch(oldVersion){
                        case 0:
                            
                             const store = db.createObjectStore('docs');
                             store.createIndex("filename", "filename", {unique: true});
                            // fall through so that we can upgrade.
                        case 1:
                            break;
                        default:
                            console.log("unknown db version");
                    }

                }
            })
        };
    }


    async AddDoc(blob, docType, flName){
        (await this.iDb.docDb).add('docs', blob, docType.toLowerCase() + "-" + flName);
    }

    async hasDoc(docType, flName){
        var existsResult = await (await this.iDb.docDb).count("docs", docType.toLowerCase() + "-" + flName);
        console.group("existsResult: ");
        console.log(existsResult);
        console.groupEnd();
        return (existsResult > 0);
    }

    async fetchDoc(docType, flName){
        var resultData =  await (await this.iDb.docDb).get("docs", docType.toLowerCase() + "-" + flName);
        console.group("Fetch: ");
        console.log(resultData);
        console.groupEnd();

        return resultData;
    }

    async deleteDoc(docType, flName){
        await (await this.iDb.docDb).delete("docs", docType.toLowerCase() + "-" + flName); 
    }

}



