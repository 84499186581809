
import moment from "moment";
import { isUndefined } from "lodash";

function valToColor(paramVal, cellStyle){
    var styl = cellStyle.byValue.find(v => v.value === paramVal);
    if (isUndefined(styl)){
        return {color: cellStyle.byDefault.color, backgroundColor: cellStyle.byDefault.backgroundColor};
    }
    else {
        return {color: styl.color, backgroundColor: styl.backgroundColor};
    }
}

function toCellStyle(cellStyle){
    var funcString = "";
    for(var i=0; i<cellStyle.byValue.length; i++){
        funcString = funcString + "if (params.value === " + cellStyle.byValue.value + ") return {color: " + cellStyle.byValue[i].color + " backgroundColor:" + cellStyle.byValue[i].backgroundColor + "} ";
    }
    funcString = funcString + "return {color: " + cellStyle.byDefault.color + " backgroundColor:" + cellStyle.byDefault.backgroundColor + "} ";
    //funcString = "function(params) {  " + funcString + " } ";
    //var cellStyleFunc = eval(funcString);
    return funcString;
}



export default class GridLayout {

    static toAgGridLayout(src){
        var defaults = src.defaults;

        var columns = src.columns.map(col =>{
            var newColumn = {
                headerName: col.title,
                field: col.field,
                hide: (col.hide) ? col.hide : defaults.hide,
                resizable: (col.resizable) ? col.resizable: defaults.resizable,
                sortable: (col.sortable) ? col.sortable: defaults.sortable,
                filter: (col.filter) ? col.filter: defaults.filter,
                width: (col.width) ? col.width: defaults.width
            }

            if (col.cellRenderType){
                if (col.cellRenderType ==="IsoUtcDateTimeToLocalRenderer"){
                    newColumn.cellRenderer = function(params) {return moment(params.value).format("YY-MM-DD HH:mm")};
                }
            }

            if (col.cellStyles){
                newColumn.cellStyle =  function(params) {
                    var colors = valToColor(params.value, col.cellStyles);
                    return {
                      color: colors.color,
                      backgroundColor: colors.backgroundColor
                    }
                }             
                //newColumn.cellStyle = function(params) {if (params.value === "PREMIUM") {return { color: "yellow", backgroundColor: "green"};} if (params.value === "REJECT") {return {color: "yellow", backgroundColor: "red"}} return { color: "black", backgroundColor: "white"};
            }


            //console.log("renderColumn: ");
            //console.log(newColumn);
            return newColumn;
        })
        var result = columns;
      
        return result;
    }


}