import {toCardId} from "../../schemaCards/AttribCardUtils.js";


export default class UsdaGenericScorer {


  info() {
    return {
      id: "USDA-Generic",
      version: "2.02.01",
      lastUpdated: "2020-07-17T13:06:00-07:00",
      updatedBy: "cs",
      description: "Base Scoring Algorithm for USDA Inspections",
    };
  }

  validate(schema, values, errors) {
    return true;
  }

  isPreInspectReject(schema, values) {
    if (!schema.scoring.preInspectReject.hasPreInspectReject) return false;
    var preInspectRejectFieldId = schema.scoring.preInspectReject.rejectPropId;
    return values[preInspectRejectFieldId];
  }


  initScoreResult(schema){
    return {
      Score: {
        Scorer: this.info(),
        Schema: schema.schemaInfo,
        ScoreReason: "INSPECTION",
        IsRejected: false,
        IsPreInspectRejected: false,
        TotalInspected: 0,
        Grade: {
          Raw: "Ungraded",
          Weighted: "Ungraded"
        },
        GlobalDefects: {
          ByDefect: null,
          ByCategory: null
        },
        Gradient: []
      }
    }
  }

  getAllDegrees(schema){
    var result = [];
    for (var i = 0; i < schema.categories.length; i++) {
      var category =schema.categories[i];
      console.log("Category: ");
      console.log(category);
      for (var j = 0; j < category.groups.length; j++) {
        var group = category.groups[j];
        console.log("Group: ");
        console.log(group);
        for (var x = 0; x < group.cards.length; x++) {
          var card = group.cards[x];
          console.log("Card: " + card.title);
          console.log(card);
          if (!card.variants) {
            continue;
          }

          for (var y = 0; y < card.variants.length; y++) {
            var variant = card.variants[y].toLowerCase();
          if (!result.includes(variant)){
            result.push(variant);
          }
        }
      }
    }
  }
  console.log("All Degrees:")
  console.log(result);
  return result;
}


  // if you are passing in card defect type exclusions, be sure to pass in your
  // exclusion list already converted to cardId!
  cardCategoryDegrees(schema, values, defectExclusions) {
    var exclusions = (this.hasDefectExclusions(defectExclusions)) ? this.exclusionsToCardIdFormat(defectExclusions)  : null;
    var result = [];
    var totalDefects = 0;
    var allDegrees = this.getAllDegrees(schema);
    // initialize by total + variant
    var totalDegrees = {};
    for (var v=0; v < allDegrees.length; v++){
      totalDegrees[allDegrees[v]] = 0;
    }

    // get all groups with a defectCategory
    for (var i = 0; i < schema.categories.length; i++) {
      for (var j = 0; j < schema.categories[i].groups.length; j++) {
        var group = schema.categories[i].groups[j];
        if (group.defectCategory) {
          var defectCategory = group.defectCategory;
          // get all degrees and counts
          for (var x = 0; x < group.cards.length; x++) {
            var card = group.cards[x];
            var cardId = toCardId(card.title);
            // is this card excluded? Might be excluded because it's not part of the current grade we are evaluating
            if ((exclusions) && (exclusions.includes(cardId))) continue; // do NOT include this!
            // in this schema, variants represent the degrees
            for (var y = 0; y < card.variants.length; y++) {
              var degree = card.variants[y];
              // does this card even have a score?
              if (values[cardId + degree]) {
                var degreeCount = parseInt(values[cardId + degree]);
                totalDefects += degreeCount;
                totalDegrees[card.variants[y].toLowerCase()] += degreeCount;
                // now we have the card title, id, defectCategory, the degree and the count
                var cardScore = {
                  category: defectCategory,
                  degree: degree,
                  cardTitle: card.title,
                  cardId: cardId,
                  count: degreeCount,
                };
                result.push(cardScore);
                // some cards also have special score categories e.g. decay
                if (card.scoreCategory) {
                  var extraCardScore = {
                    category: card.scoreCategory,
                    degree: degree,
                    cardTitle: card.title,
                    cardId: cardId,
                    count: degreeCount,
                  };
                  result.push(extraCardScore);
                }
              }
            }
          }
        }
      }
    }
    var total = {category: "totaldefects", degree: "all", cardTitle: "", cardId: "", count: totalDefects};
    result.push(total);
    console.log("Total Degrees: ");
    console.log(totalDegrees);
    Object.keys(totalDegrees).forEach(function(key) {
      console.log(key + " " + totalDegrees[key]);
      result.push({category: "totaldefects", degree: key, cardTitle: "", cardId: "", count: totalDegrees[key]});
   });
   /*
    totalDegrees.forEach((key, index) => {
      result.push({category: "totaldefects", degree: key, cardTitle: "", cardId: "", count: totalDegrees[key]});
    });
  */

    return result;
  }

sumCategoryDegrees(cardCatDegrees){
  var result = cardCatDegrees.reduce((acc, val) => {
    var o = acc
      .filter((obj) => {
        return obj.category === val.category && obj.degree === val.degree;
      })
      .pop() || { category: val.category, degree: val.degree, value: 0 };

    o.value += val.count;
    if (!acc.includes(o)) {
      acc.push(o);
    }
    return acc;
  }, []);
  return result;
}

  totalDefectsByRule(rule, catDegrees){
    let acc = 0;
    for (var i=0; i<catDegrees.length; i++){
      acc += (rule.catDegreesToInclude.includes(catDegrees[i].category + '-' + catDegrees[i].degree)) ?  acc + catDegrees[i].value : 0;
    }
    return acc;
  }




  testRule(rule, totalInspected, catDegrees){
    var totalDefects = this.totalDefectsByRule(rule, catDegrees);
    var rulePct = (totalDefects===0) ? 0 : (totalDefects / totalInspected)*100;
    var rulePassed = (rulePct <= rule.pctCutoff);
    var ruleResult = {title: rule.title, description: rule.description, totalInspected: totalInspected, totalDefects: totalDefects, pct: rulePct, passed: rulePassed, didRulePass: rulePassed.toString()};
    return ruleResult;
  }


  

  score(schema, values) {
    var result = this.initScoreResult(schema);
    console.log("result: ");
    console.log(result);


    // first deal with pre-inspect rejects
    if (this.isPreInspectReject(schema, values)) {
      result.Score.IsPreInspectRejected = true;
      result.Score.IsRejected = true;
      result.Score.Grade.Weighted = "REJECT";
      result.Score.Grade.Raw = "REJECT";
      return result;
    }

    // test if we need to recalculate cardCatDegrees based on exclusion or collapse rules. 
    // If so, then we have to calculate and show those rules AND the cardCatDegrees on EACH grade evaluation
    // If NOT, then we'll just calculate and show it once.
    var schemaHasGradeSpecificRules = this.schemaHasGradeSpecificRules(schema);
    // isolate all card/category/degree counts globally, in case the specific grade does NOT have exclusions/collapses
    var globalCardCatDegrees = this.cardCategoryDegrees(schema, values);
    // "reduce" to sums of JUST category/degrees
    var globalCatDegrees = this.sumCategoryDegrees(globalCardCatDegrees);
    result.Score.GlobalDefects.ByDefect = globalCardCatDegrees;
    result.Score.GlobalDefects.ByCategory = globalCatDegrees;
    result.Score.GlobalDefects.GradeSpecificRules = schemaHasGradeSpecificRules;
    if (schemaHasGradeSpecificRules){
      result.Score.GlobalDefects.Notes = "This schema has grade-specific exclusion/collapse rules! See individual grades for details";
    }
  

    // iterate through each grade in the gradient
    var totalInspected = values[schema.scoring.totalQtyPropId];
    result.Score.TotalInspected = totalInspected;


    // FOREACH GRADE
    for(var i=0; i<schema.scoring.gradeGradient.length; i++){
      // iterate through each RULE in the gradient
      var grade = schema.scoring.gradeGradient[i];
      var gradeSpecificRules = this.gradeHasSpecificRules(grade);
      // init the grade result
      var gradeResult = {grade: grade.title, description: grade.description, exclusions: grade.excludedDefects, collapses: grade.variantCollapseDefects, rules: []};
      var cardCatDegrees = (gradeSpecificRules) ? this.cardCategoryDegrees(schema, values, grade.excludedDefects, grade.variantCollapsedDefects) : globalCardCatDegrees;
      var catDegrees = (gradeSpecificRules) ? this.sumCategoryDegrees(cardCatDegrees) : globalCatDegrees;
      if (gradeSpecificRules){
        // show byDefect and byCategory again for each grade because they may be different
        gradeResult.Defects = {};
        gradeResult.Defects.ByDefect = cardCatDegrees;
        gradeResult.Defects.ByCategory = globalCatDegrees;
      }
      for(var j=0; j<grade.rules.length; j++){
         var ruleResult = this.testRule(grade.rules[j], totalInspected, catDegrees);
         gradeResult.rules.push(ruleResult);
      }
      // at this point we should now if the inspection passed this grade or not. We can't assume it's all ANDs but I'll TBD.
      var gradePassed = gradeResult.rules.every((rule) => rule.passed);
      gradeResult.passed = gradePassed;
      gradeResult.didGradePass = gradePassed.toString();
      
      result.Score.Gradient.push(gradeResult);
      if (gradeResult.passed){
        // we're done! 
        result.Score.Grade.Raw = gradeResult.grade;
        result.Score.Grade.Weighted = gradeResult.grade;
        return result;
      }

    }
    // if we didn't pass we keep going until we reach the LAST grade in the gradient and our grade is done. 
    var cutoffGradeResult = {grade: schema.scoring.cutoffGrade.title, description: schema.scoring.cutoffGrade.description};
    result.Score.Gradient.push(cutoffGradeResult);
    result.Score.Grade.Weighted = cutoffGradeResult.grade;
    result.Score.Grade.Raw = cutoffGradeResult.grade;
    if (schema.scoring.cutoffGrade.reject){
      result.Score.IsRejected = true;
    }
    return result;

  }



  schemaHasGradeSpecificRules(schema){
    for(var i=0; i<schema.scoring.gradeGradient.length; i++){
      var grade = schema.scoring.gradeGradient[i];
      if (this.hasDefectExclusions(grade.excludedDefects)){
        return true;
      }
    }
    return false;
  }
  
  gradeHasSpecificRules(grade){
    return this.hasDefectExclusions(grade.excludedDefects);
  }

  hasDefectExclusions(excludedDefects){
    return ((excludedDefects) && (excludedDefects.length>0));
  }

  exclusionsToCardIdFormat(exclusions){
    // assumes just an array of Card Ids
    return exclusions.map((excl) => {
      return toCardId(excl);
    })
  }

}
