import {toCardId} from "../../schemaCards/AttribCardUtils.js";


export default class GradeConditionalScorer {


  info() {
    return {
      id: "grade-conditional",
      version: "1.01.01",
      lastUpdated: "2020-09-17T12:08:00-07:00",
      updatedBy: "cs",
      description: "Base Scoring Algorithm for Grade-Conditional Observations",
    };
  }

  validate(schema, values, errors) {
    return true;
  }

  isPreInspectReject(schema, values) {
    if (!schema.scoring.preInspectReject.hasPreInspectReject) return false;
    var preInspectRejectFieldId = schema.scoring.preInspectReject.rejectPropId;
    return values[preInspectRejectFieldId];
  }


  initScoreResult(schema){
    return {
      Score: {
        Scorer: this.info(),
        Schema: schema.schemaInfo,
        ScoreReason: "INSPECTION",
        IsRejected: false,
        IsPreInspectRejected: false,
        TotalInspected: 0,
        FailedConditions: [],
        Grade: {
          Raw: "Ungraded",
          Weighted: "Ungraded"
        },
        Gradient: []
      }
    }
  }

  // return array of "failed rules"
  testRule2(rule, values){
    var failed = []
    for (var i=0; i<rule.ruleFields.length; i++){
      var ruleField = rule.ruleFields[i];
      // we mah have to "parse" the value to a different type
      var testValue = values[ruleField];
      if (rule.examinedFieldType){
        if (rule.examinedFieldType==="float"){
          testValue = parseFloat(values[ruleField])
        }
        else if (rule.examinedFieldType==="int"){
          testValue = parseInt(values[ruleField])
        }
      }
      var rulePassed = false;
      // now let's do our comparisons
      if (rule.evalType === "allequal"){
        rulePassed = (testValue === rule.examinedValue);
      }
      else if (rule.evalType === "allnotequal"){
        rulePassed = (testValue !== rule.examinedValue);
      }
      else if (rule.evalType === "alllessthan"){
        rulePassed = (testValue < rule.examinedValue);
      }
      else if (rule.evalType === "allmorethan"){
        rulePassed = (testValue > rule.examinedValue);
      }


      if (!rulePassed){
        failed.push({"ruleField": ruleField, "evalType": rule.evalType, "ruleValue": rule.examinedValue, "observationValue": testValue})
      }
    }
    return failed;
  }

  testRule(rule, values){
    var rulePassed = false;
    if (rule.evalType==="allequal"){
        rulePassed = rule.ruleFields.every(ruleField => {
            console.log(ruleField);
            return (values[ruleField]===rule.examinedValue);
        })
    }
    else if (rule.evalType === "allnotequal") {
        rulePassed = !rule.ruleFields.every(ruleField =>{
            return (values[ruleField] === rule.examinedValue);
        })
    }
    else if (rule.evalType==="lessthan"){
      rulePassed = !rule.ruleFields.every(ruleField =>{
        if (rule.examinedFieldType)
        return (values[ruleField] < rule.examinedValue);
    })
    }

    var ruleResult = {title: rule.title, description: rule.description,  passed: rulePassed};
    return ruleResult;
  }


  

  score(schema, values) {
    var result = this.initScoreResult(schema);
    console.log("result: ");
    console.log(result);

    // first deal with pre-inspect rejects
    if (this.isPreInspectReject(schema, values)) {
      result.Score.IsPreInspectRejected = true;
      result.Score.IsRejected = true;
      result.Score.Grade.Raw = "REJECT";
      result.Score.Grade.Weighted = "REJECT"
      return result;
    }

    // FOREACH GRADE
    for(var i=0; i<schema.scoring.gradeGradient.length; i++){
      // iterate through each RULE in the gradient
      var grade = schema.scoring.gradeGradient[i];
      var gradeResult = {grade: grade.title, description: grade.description, rules: []};
      for(var j=0; j<grade.rules.length; j++){
         var failedConditions = this.testRule2(grade.rules[j], values);
         grade.rules[j].passed =  (failedConditions.length===0);
         grade.rules[j].failedConditions = failedConditions;
         gradeResult.rules.push(grade.rules[j]);
         failedConditions.forEach(cond => result.Score.FailedConditions.push(cond.ruleField + ' ' + cond.evalType + ' ' + cond.ruleValue + ' (' + cond.observationValue + ')'));
      }
      // at this point we should now if the inspection passed this grade or not. We can't assume it's all ANDs but I'll TBD.
      var gradePassed = gradeResult.rules.every((rule) => rule.passed);
      gradeResult.passed = gradePassed;
      result.Score.Gradient.push(gradeResult);

      if (gradeResult.passed){
        // we're done! 
        result.Score.Grade.Raw = gradeResult.grade;
        result.Score.Grade.Weighted = gradeResult.grade;
        return result;
      }
    }
    
    // if we didn't pass we keep going until we reach the LAST grade in the gradient and our grade is done. 
    var cutoffGradeResult = {grade: schema.scoring.cutoffGrade.title, description: schema.scoring.cutoffGrade.description};
    result.Score.Gradient.push(cutoffGradeResult);
    result.Score.Grade.Raw = cutoffGradeResult.grade;
    result.Score.Grade.Weighted = cutoffGradeResult.grade;
    if (schema.scoring.cutoffGrade.reject){
      result.Score.IsRejected = true;
    }
    return result;

  }






}
