import React, { Component } from "react";
import { Dropdown, Navbar, Nav } from "react-bootstrap";
import ConfigService from "../../AppConfig/AppConfig.js";
import AuthService from "../../AuthService/AuthService.js";
import GlasschainHttpClient from "../../ApiService/GlasschainHttpClient.js";
import NavLoginExpireTimer from "../../NavbarLoginExpireTimer.jsx";
import gcObsLogo from "../../../assets/images/logos/glasschain-logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWifi, faPlane, faSyncAlt} from "@fortawesome/free-solid-svg-icons";



export default class MainNavbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      togglerIcon: "ellipsis-v"
    };
    this.auth = new AuthService();
    this.apiClient = new GlasschainHttpClient();
    this.gcid = this.auth.getGcid();
    this.uid = this.auth.getUser();
    this.config = new ConfigService();
    this.logoUrlBase = this.config.getLogoImageBase();

    this.openUserGuide = this.openUserGuide.bind(this);
    this.prepOffline = this.prepOffline.bind(this);
    this.onLoginAboutToExpire = this.onLoginAboutToExpire.bind(this);
  }


  onLoginAboutToExpire(){
    // TBD - either modal warning or message on navbar warning about to expire
  }


  async prepOffline(){
    this.props.onPrepOfflineRequest();
  }

  openUserGuide() {
    window.open(
      "https://gcblobstoredurable1.blob.core.windows.net/userguidesdurable/glasschain-qa-userguide.pdf",
      "_blank"
    );
  }

  render() {



    const airplaneMode = this.props.isAirplaneMode;
    const prepOfflineMenuItem = <Nav.Link onClick={this.prepOffline}>Prep Offline</Nav.Link>;

    const onlineMenuItems = (this.props.isConnected) ?
      <span>
      <Dropdown.Item onClick={this.openUserGuide}>User Guide</Dropdown.Item>
      <Dropdown.Item onClick={this.props.onRequestLogout}>Log Out</Dropdown.Item>
      </span>
    : <span></span>

    return (
      <Navbar collapseOnSelect bg="light" expand="xlg" sticky="top">
        <Navbar.Brand 
        pullleft="true"
        >
          <img
            src={gcObsLogo}
            height="40px"
            className="d-inline-block align-top"
            alt="Glasschain logo"
          />
          &nbsp;Glasschain QA Inspection History
        </Navbar.Brand>
        <span align="right">
          <img
            src={
              this.logoUrlBase +
              "/gcid/" +
              this.gcid +
              "/logos/" +
              this.gcid +
              "-logo.png"
            }
            style={{ height: 40 }}
            alt={this.gcid}
          />
        </span>

        <span align="right">
          <FontAwesomeIcon icon={faPlane} color={(airplaneMode===true) ? "green" : "grey"} onClick={this.props.onToggleAirplaneModeRequest} />&nbsp;  
          <FontAwesomeIcon icon={faWifi} color={(this.props.isOnline) ? "green" : "grey"} />&nbsp;
          {(this.props.syncing) ? <FontAwesomeIcon icon={faSyncAlt} color="green"  spin="true"/> : <span/>}&nbsp;
          {(this.props.syncing) ? "loading " + this.props.syncText + "..." : ""}
        </span>


      <Dropdown>
        <Dropdown.Toggle variant="primary" id="dropdown-basic"></Dropdown.Toggle>
        <Dropdown.Menu style={{left: "-350%"}}>
            <Dropdown.Item>
        Login Expires in
              <NavLoginExpireTimer
                beforeExpireWarningMinutes={10}
                autoLogoutMinutes={5}
                timerWarningExpiration={this.onLoginAboutToExpire}
                onLoginExpire={this.props.onLoginExpire}
              />
          </Dropdown.Item>
          <Dropdown.Item onClick={this.props.onRouteHomeRequest}>Home</Dropdown.Item>
          <Dropdown.Item onClick={this.props.onRequestAboutDialogOpen}>About..</Dropdown.Item>
          {onlineMenuItems}
        </Dropdown.Menu>
      </Dropdown>
    
      </Navbar>
    );
  }
}
