import {openDB, idb} from 'idb';
import GlassbitHasher from "../Security/GlassbitHasher.js";
import moment from "moment"


export default class IDbService{
    constructor(){
        this.defaultObjectStore = "observation";
        this.idb = {
            obsDb: openDB("obsDb", 1,{
                upgrade: (db, oldVersion, newVersion, transaction) => {
                    switch(oldVersion){
                        case 0:
                            
                             const store = db.createObjectStore('observation');
                             store.createIndex("offlineId", "offlineId", {unique: true});
                            // fall through so that we can upgrade.
                        case 1:
                            break;
                        default:
                            console.log("unknown db version");
                    }

                }
            })
        };
    }

    async updateQaObservationPendingToVoid(gcid, offlineId){
       var obs = await this.fetchObservation(gcid, offlineId); 
       console.group("Local Storage Observation: ");
       console.log(obs);
       console.groupEnd();

       obs.Offline.uploadStatus = 'void-pending';

       await this.deleteObservation(offlineId);
       (await this.idb.obsDb).add('observation', obs, offlineId); // add the complete observation back, including offline info
    }

    async addObservation(gcid, obs, offlineId){
        //var obsHash = new GlassbitHasher().hashFnv32a(obs);
        let hasImages = ((obs.data.Raw.userimages) && (obs.data.Raw.userimages.length>0));
        obs.data.hasImages = hasImages;
        var localObservation = {
            "Gcid": gcid, "ObservedBy": obs.data.UnderInspection.Inspector, "ObservationLocation": obs.data.UnderInspection.Location, "ObservationDate": obs.data.UnderInspection.InspectionDate,
            "ObservationType": "QADOCKINSPECTION", "RecordedDate": obs.data.UnderInspection.InspectionDate, "SchemaId": obs.data.schema.id, "SchemaVersion" : obs.data.schema.schemaVersion,
            "ObservationHash": null, "ScoreReason": obs.score.Score.ScoreReason,
            "ScoreGrade": obs.score.Score.Grade.Weighted, "ScoreGradeInt": 0,
            "HasImages": hasImages,
            "ObservationSnapshot" : JSON.stringify(obs),
            "offlineId": offlineId,
            "Offline": {id: offlineId, isUploaded: false, uploadStatus: 'pending', lastStatusUpdate: moment().format()}
            //offlineId: newOfflineId, observation: observation, isUploaded: false, uploadStatus: 'awaiting', lastStatusDate: moment().format()
        };
        (await this.idb.obsDb).add('observation', localObservation, offlineId);
    }

    async fetchObservation(gcid, offlineId){
        var resultData = await (await this.idb.obsDb).get("observation", offlineId);
        return resultData;

    }

    async fetchAllObservations(){
        var resultData = await (await this.idb.obsDb).getAll("observation");
        var result = resultData.map(offlineRec =>{
            console.log("offlineRec:");
            console.log(offlineRec);
            var res = JSON.parse(offlineRec.ObservationSnapshot);
            // add offline status fields
            res.identifiers = {id: offlineRec.Offline.id, gcid: offlineRec.Gcid, status: offlineRec.Offline.uploadStatus, statusReason: ""} ;
            return res;
        }) ;
        return result;
    }


    async deleteObservation(offlineId){
        var result = await (await this.idb.obsDb).delete("observation", offlineId);
    }



}



