import GlasschainHttpClient from "../ApiService/GlasschainHttpClient.js";
import AuthService from "../AuthService/AuthService.js";


export default class OfflineChef {
  constructor() {
    this.apiClient = new GlasschainHttpClient();
    this.auth = new AuthService();
  }

  async prepOffline(gcid, onSuccess, onError) {
    try {
      var orgConfig = await this.apiClient.fetchOrgConfiguration(gcid);
      this.auth.saveLocalOrgConfig(gcid, orgConfig);
      await Promise.all(
        orgConfig.data.schemaList.map(async (schemaId) => {
          const fullSchemaResponse = await this.apiClient.fetchObservationFullSchema(schemaId);
          this.auth.saveLocalSchema(schemaId, fullSchemaResponse.data);
          const schemaGridResponse = await this.apiClient.fetchObservationGridSchema(schemaId);
          this.auth.saveLocalGridSchema(schemaId, schemaGridResponse.data);
        })
      );
      if (onSuccess) {
        onSuccess();
      }
    } catch (err) {
      if (onError) {
        onError(err);
      }
    }
  }
}
