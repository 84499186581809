

export default class NonScorer {


  info() {
    return {
      id: "nonscorer",
      version: "1.01.01",
      lastUpdated: "2021-03-22T21:00:00-08:00",
      updatedBy: "cs",
      description: "Scorer for Schemas without a Score!",
    };
  }

  validate(schema, values, errors) {
    return true;
  }

  initScoreResult(schema){
    return {
      Score: {
        Scorer: this.info(),
        Schema: schema.schemaInfo,
        ScoreReason: "NO-SCORE",
        PassedInspection: true,
        UsesScoring: false, 
        Grade: {}
      }
    }
  }


  score(schema, values) {
    var result = this.initScoreResult(schema);
    result.Score.PassedInspection = true;
    result.Score.Grade.Raw = "";
    result.Score.Grade.Weighted = "";
    return result;
  }




}
