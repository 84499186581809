import React, { Component } from "react";
import {Row, Col } from "react-bootstrap";

import AttribCountCard from "./AttribCountCard.jsx";
import AttribTextCard from "./AttribTextCard.jsx";
import AttribDateCard from "./AttribDateCard.jsx";
import AttribReadOnlyCard from "./AttribReadOnlyCard.jsx";
import AttribCheckboxCard from "./AttribCheckboxCard.jsx";
import ContainerQtyProductCard from "./AttribContainerQtyProductCard.jsx";
import AttribTextParseCard from "./AttribTextParseCard.jsx";
import AttribDropdownCard from "./AttribDropdownCard.jsx";
import AttribApiLookupMapCard from "./AttribApiLookupMapCard.jsx";
import AttribTextEditorCard from "./AttribTextEditorCard.jsx";
import AttribUserImageCard from "./AttribUserImageCard.jsx";
import AttribChecklistBoxCard from "./AttribChecklistBoxCard.jsx";
import AttribYesNoCard from "./AttribYesNoCard.jsx";
import AttribScanditCard from "./AttribScanditCard.jsx";

export default class AttributeGroup extends Component {
  constructor(props) {
    super(props);

    this.renderCard = this.renderCard.bind(this);
    this.renderTextCard = this.renderTextCard.bind(this);
    this.renderCountCard = this.renderCountCard.bind(this);
    this.renderDateCard = this.renderDateCard.bind(this);
    this.renderReadOnlyCard = this.renderReadOnlyCard.bind(this);
    this.renderCheckboxCard = this.renderCheckboxCard.bind(this);
    this.renderContainerQtyProductCard = this.renderContainerQtyProductCard.bind(this);
    this.renderTextParseCard = this.renderTextParseCard.bind(this);
    this.renderApiLookupMapCard = this.renderApiLookupMapCard.bind(this);
    this.renderTextEditorCard = this.renderTextEditorCard.bind(this);
   this.renderChecklistBoxCard = this.renderChecklistBoxCard.bind(this);
   this.renderYesNoCard = this.renderYesNoCard.bind(this);
   this.renderScanditCard = this.renderScanditCard.bind(this);
  }

  renderCard(card) {
    switch (card.fieldType) {
      case "text": {
        return this.renderTextCard(card);
      }
      case "date": {
        return this.renderDateCard(card);
      }
      case "readonly": {
        return this.renderReadOnlyCard(card);
      }
      case "checkbox": {
        return this.renderCheckboxCard(card);
      }
      case "containerqtyproduct": {
        return this.renderContainerQtyProductCard(card);
      }
      case "textparser": {
        return this.renderTextParseCard(card);
      }
      case "dropdown": {
        return this.renderDropdownCard(card);
      }
      case "textapilookupmap": {
        return this.renderApiLookupMapCard(card);
      }
      case "texteditor": {
        return this.renderTextEditorCard(card);
      }
      case "userimage": {
        return this.renderUserImageCard(card);
      }
      case "checklistbox": {
        return this.renderChecklistBoxCard(card);
      }
      case "yesno": {
        return this.renderYesNoCard(card);
      }
      case "scandit": {
        return this.renderScanditCard(card);
      }
      default: {
        return this.renderCountCard(card);
      }
    }
  }

  renderTextEditorCard(card){
    return (
      <AttribTextEditorCard
        attribTitle={card.title}
        onToggleCard={this.props.onToggleCard}
        onValueChange={this.props.onValueChange}
        valueBag={this.props.valueBag}
        theme={this.props.theme}
        onRequestCameraDialogOpen={this.props.onRequestCameraDialogOpen}

      />
    );
  }

  renderApiLookupMapCard(card){
    return (
      <AttribApiLookupMapCard
        attribTitle={card.title}
        onToggleCard={this.props.onToggleCard}
        onValueChange={this.props.onValueChange}
        proxyApi={true}
        entityExistsApiUrl={card.entityExistsApiUrl}
        entityLookupApiUrl={card.entityLookupApiUrl}
        proxyClientRole={card.proxyClientRole}
        fieldMap={card.fieldMap}
        valueBag={this.props.valueBag}
        repressedOverwrites={card.repressOverwrite}
        theme={this.props.theme}
      />
    )
  }

  renderTextParseCard(card){
    return (
      <AttribTextParseCard
        attribTitle={card.title}
        onToggleCard={this.props.onToggleCard}
        onValueChange={this.props.onValueChange}
        parserType={card.parserType}
        fieldMap={card.fieldMap}
        lotParser={card.lotParser}
        repressedOverwrites={card.repressOverwrite}
        valueBag={this.props.valueBag}
        theme={this.props.theme}
        useCamera={true}
        onRequestCameraDialogOpen={this.props.onRequestCameraDialogOpen}

      />
    );
  }

  renderContainerQtyProductCard(card){
    return (
      <ContainerQtyProductCard
        attribTitle={card.title}
        containerCountTitle={card.containerCountTitle}
        containerQtyTitle={card.containerQtyTitle}
        unitQtyTitle={card.unitQtyTitle}
        onToggleCard={this.props.onToggleCard}
        onValueChange={this.props.onValueChange}
        validation={card.validation}
        onValidationChange={this.props.onValidationChange}
        valueBag={this.props.valueBag}
        theme={this.props.theme}
      />
    ); 
  }

  renderCheckboxCard(card){
    return (
      <AttribCheckboxCard
        attribTitle={card.title}
        attribTags={card.tags}
        onToggleCard={this.props.onToggleCard}
        onValueChange={this.props.onValueChange}
        onValueRemove={this.props.onValueRemove}
        validation={card.validation}
        onValidationChange={this.props.onValidationChange}
        valueBag={this.props.valueBag}
        theme={this.props.theme}
      />
    ); 
  }

  renderChecklistBoxCard(card){
    return (
      <AttribChecklistBoxCard
        attribTitle={card.title}
        caption={card.caption}
        onToggleCard={this.props.onToggleCard}
        onValueChange={this.props.onValueChange}
        onValueRemove={this.props.onValueRemove}
        validation={card.validation}
        onValidationChange={this.props.onValidationChange}
        valueBag={this.props.valueBag}
        criteriaSlides={card.criteriaSlides}
        onRequestCriteriaSlidesOpen={this.props.onRequestCriteriaSlidesOpen}
        onRequestCameraDialogOpen={this.props.onRequestCameraDialogOpen}
        theme={this.props.theme}
      />
    ); 
  }

  renderReadOnlyCard(card){
    return (
      <AttribReadOnlyCard
        theme={(this.props.theme) ? this.props.theme: ""}
        attribTitle={card.title}
        label={card.label}
        display={card.display}
        onToggleCard={this.props.onToggleCard}
        valueBag={this.props.valueBag}
        theme={this.props.theme}
      />
    )
  }

  renderDateCard(card){
    return (
      <AttribDateCard
        attribTitle={card.title}
        display={card.display}
        onToggleCard={this.props.onToggleCard}
        onValueChange={this.props.onValueChange}
        valueBag={this.props.valueBag}
        theme={this.props.theme}
      />
    )
  }

  renderCountCard(card) {
    return (
      <AttribCountCard
        attribTitle={card.title}
        attribVariants={card.variants}
        onToggleCard={this.props.onToggleCard}
        onValueChange={this.props.onValueChange}
        criteriaSlides={card.criteriaSlides}
        onRequestCriteriaSlidesOpen={this.props.onRequestCriteriaSlidesOpen}
        onRequestCameraDialogOpen={this.props.onRequestCameraDialogOpen}
        calculatorEnabled={card.calculatorEnabled}
        onRequestCalculatorDialogOpen={this.props.onRequestCalculatorDialogOpen}
        valueBag={this.props.valueBag}
        theme={this.props.theme}
      />
    );
  }


  renderYesNoCard(card) {
    return (
      <AttribYesNoCard
        attribTitle={card.title}
        caption={card.caption}
        onToggleCard={this.props.onToggleCard}
        onValueChange={this.props.onValueChange}
        validation={card.validation}
        onValidationChange={this.props.onValidationChange}
        valueBag={this.props.valueBag}
        theme={this.props.theme}
      />
    );
  }

  renderTextCard(card) {
    return (
      <AttribTextCard
        attribTitle={card.title}
        validation={card.validation}
        onToggleCard={this.props.onToggleCard}
        onValueChange={this.props.onValueChange}
        valueBag={this.props.valueBag}
        useCamera={true}
        onRequestCameraDialogOpen={this.props.onRequestCameraDialogOpen}
        theme={this.props.theme}
      />
    );
  }

  renderUserImageCard(card){
    return (
      <AttribUserImageCard
        attribTitle={card.title}
        onToggleCard={this.props.onToggleCard}
        onValueChange={this.props.onValueChange}
        valueBag={this.props.valueBag}
        descript={card.descript}
        tags={card.tags}
        dataUri={card.dataUri}
        theme={this.props.theme}
      />
    );
  }

  renderDropdownCard(card) {
    return (
      <AttribDropdownCard
        attribTitle={card.title}
        validation={card.validation}
        onToggleCard={this.props.onToggleCard}
        onValueChange={this.props.onValueChange}
        onValueRemove={this.props.onValueRemove}
        valueBag={this.props.valueBag}
        options = {card.options}
        otherOptions = {card.otherOptions}
        criteriaSlides={card.criteriaSlides}
        hideCamera={card.hideCamera}
        onRequestCriteriaSlidesOpen={this.props.onRequestCriteriaSlidesOpen}
        onRequestCameraDialogOpen={this.props.onRequestCameraDialogOpen}
        theme={this.props.theme}
      />
    );
  }

  renderProxyLookupDropdownCard(card) {
    return (
      <AttribDropdownCard
        attribTitle={card.title}
        onToggleCard={this.props.onToggleCard}
        onValueChange={this.props.onValueChange}
        valueBag={this.props.valueBag}
        options = {card.options}
        theme={this.props.theme}
      />
    );
  }


  renderScanditCard(card) {
    return (
      <AttribScanditCard
        attribTitle={card.title}
        validation={card.validation}
        onToggleCard={this.props.onToggleCard}
        onValueChange={this.props.onValueChange}
        valueBag={this.props.valueBag}
        useCamera={false}
      />
    );
  }




  render() {

    const aGroup = (this.props.useColumns) ?
    <div>
       {this.props.cards.map((card, index) => (
      <div className="h-100">{this.renderCard(card)}</div>
    ))}   
    </div>
    :
    <Row xs={this.props.columns.xs} sm={this.props.columns.sm} md={this.props.columns.md} lg={this.props.columns.lg} className={this.props.title}>
    {this.props.cards.map((card, index) => (
      <Col className="h-100">{this.renderCard(card)}</Col>
    ))}
  </Row>


    return (
      <div className={this.props.theme + 'attribgroup'}>
      <div className="group-title" style={{borderTopStyle: "solid", fontSize: "23px", borderColor: "white", borderBottomWidth: "thick"}}>{this.props.title}</div>
      {aGroup}
      </div>
    );
  }
}
