import React, { Component } from "react";
import {
  FormControl,
  InputGroup,
} from "react-bootstrap";
import Decimal from "decimal.js";
import "../../assets/css/attrib-cards.css";
import {toCardId, toCardTitle} from "./AttribCardUtils.js";



export default class AttribContainerQtyProductCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardState: "untouched",
      containerCount: null,
      containerQty: null,
      unitQty: null,
      cardValue: null,
      valid: false
    };
    this.Id = toCardId(this.props.attribTitle);

    this.renderState = this.renderState.bind(this);
    this.renderUntouchedState = this.renderUntouchedState.bind(this);
    this.renderActiveState = this.renderActiveState.bind(this);
    this.onCardClick = this.onCardClick.bind(this);
    this.onLoseFocus = this.onLoseFocus.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.onValidityCheck = this.onValidityCheck.bind(this);
    this.setFromValueBag = this.setFromValueBag.bind(this);

    this.toFieldId = this.toFieldId.bind(this);
  }

  toFieldId(fieldTitle){
    return fieldTitle.replace(" ", "").replace("/","").toLowerCase();
  }

  setFromValueBag()
  {
    let oldContainerCount = this.state.containerCount;
    let oldUnitQty = this.state.unitQty;
    let oldContainerQty = this.state.containerQty;
    let newContainerCount = this.props.valueBag[this.toFieldId(this.props.containerCountTitle)];
    let newUnitQty = this.props.valueBag[this.toFieldId(this.props.unitQtyTitle)];
    let newContainerQty = this.props.valueBag[this.toFieldId(this.props.containerQtyTitle)];
    // note we want to compare null and undefined as being the same.
    if ( (oldContainerCount != newContainerCount)  || (oldUnitQty != newUnitQty) || (oldContainerQty != newContainerQty) )
    {
      this.setState(
      {
        containerCount: newContainerCount,
        unitQty: newUnitQty,
        containerQty: newContainerQty
      });
    }
  }
  

  onValidityCheck(unitQty)
  {
    if ((this.props.validation) && (this.props.validation.required)) {
      var isValid = ((unitQty) && (unitQty>0));
      if (isValid !== this.state.valid){
        // validity state has changed!
        this.setState({valid: isValid});
        // in this case, we want the actual validation id to the unitQty value
        this.props.onValidationChange(this.toFieldId(this.props.unitQtyTitle), isValid, this.props.validation.requiredMessage);
      }

    }
  }

  onValueChange(ev) {
    let containerCount = this.state.containerCount;
    let containerQty = this.state.containerQty;
    let unitQty = this.state.unitQty;
    if (ev.target.id === "containerCount") {
      containerCount = ev.target.value;
      this.props.onValueChange(this.toFieldId(this.props.containerCountTitle), ev.target.value);
    }
    else {
      containerQty = ev.target.value;
      this.props.onValueChange(this.toFieldId(this.props.containerQtyTitle), ev.target.value);
    }
    if ((Number.isInteger(+containerCount)) && (Number.isInteger(+containerQty))) {
      unitQty = containerCount * containerQty;
      console.log("RESULT END: " + unitQty)
      this.props.onValueChange(this.toFieldId(this.props.unitQtyTitle), unitQty);
    } else {
      console.log("ATTEMPTING FLOAT MATH")
      if ( (containerCount) && (!isNaN(containerCount)) && (containerQty) && (!isNaN(containerQty)))
        {
          unitQty = Decimal(containerQty).times(containerCount)
          console.log(unitQty)
          console.log("MATH FLOAT END")

          unitQty = unitQty.toDP(2)
          unitQty = unitQty.toString()
          this.props.onValueChange(this.toFieldId(this.props.unitQtyTitle), unitQty);
        }
        else
        {
          unitQty = 0;
        }
    }
    this.onValidityCheck(unitQty);
    this.setState({
      containerCount: containerCount,
      containerQty: containerQty,
      unitQty: unitQty
    });
  }


  onLoseFocus() {
    var newCardState = (this.state.unitQty) ? "touched" : "untouched";
    this.setState({ cardState: newCardState });
  }

  onCardClick(ev) {
    var newState = "untouched";
    if (this.state.cardState === "active") {
      newState = (this.state.unitQty) ? "touched" : "untouched";
    } else {
      newState = "active";
    }
    this.setState({ cardState: newState });
    this.props.onToggleCard(this);
  }

  // TBD - if this.state.unitQty is null or 0 then change the color and report that this is a required field
  renderUntouchedState() {
    return (
      <div className="nonactive-required card-template shadow8" onClick={this.onCardClick}>
        {this.props.attribTitle}
      </div>
    );
  }
  


  renderActiveState() {
    return (
      <div
        className="activecard card-template shadow6 product-calc"
  
      >
        <div onClick={this.onCardClick}>
          {this.props.attribTitle}
        </div>
          <InputGroup style={{padding: "25px"}}>
            <FormControl
              autoFocus
              plainText
              textAlign="center"
              placeholder={this.props.containerCountTitle}
              aria-describedby="basic-addon2"
              name={this.toFieldId(this.props.containerCountTitle)}
              id="containerCount"
              value={this.state.containerCount}
              onChange={(ev) => {this.onValueChange(ev)}}
            />
            <span>&nbsp;x&nbsp;</span>
            <FormControl
              plainText
              textAlign="center"
              placeholder={this.props.containerQtyTitle}
              aria-describedby="basic-addon2"
              name={this.toFieldId(this.props.containerQtyTitle)}
              id="containerQty"
              value={this.state.containerQty}
              onChange={(ev) => {this.onValueChange(ev)}}
            />
            <span>&nbsp;=&nbsp;</span>
            <FormControl
              plainText
              disabled={true}
              textAlign="center"
              placeholder={this.props.unitQtyTitle}
              aria-describedby="basic-addon2"
              name={this.toFieldId(this.props.unitQtyTitle)}
              id="unitQty"
              value={this.state.unitQty}
            />

          </InputGroup>
      </div>
    );
  }

  renderTouchedState() {
    return (
      <div
        className="nonactivecard touchedcard shadow7 "
        onClick={this.onCardClick}
        style={{ backgroundColor: "lightgreen" }}
      >
        <div style={{ fontSize: "20px" }}>
          {this.props.attribTitle}
        </div>
          <div
            style={{
              textAlign: "center",
              fontSize: "20px",
              fontColor: "purple",
            }}
          >
            {this.state.unitQty}
          </div>
      </div>
    );
  }

  renderState() {
    switch (this.state.cardState) {
      case "untouched":
        return this.renderUntouchedState();
      case "touched":
        return this.renderTouchedState();
      default:
        return this.renderActiveState();
    }
  }

  render() {
    if ((this.state.cardState === "untouched") || (this.state.cardState==="touched"))
    {
      this.setFromValueBag();
    }
    return this.renderState();
  }
}
