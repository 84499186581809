import React, { Component } from "react";
import { Container, Row, Col, Button, Modal, FormControl } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import AppConfig from "../AppConfig/AppConfig.js";

export default class ModalVoidDialog extends Component {

    constructor(props){
      super(props);
      this.state = {
        voidReason: ""
      }

      this.onVoidReasonOk = this.onVoidReasonOk.bind(this);
    }
  

    onVoidReasonOk(){
      return this.props.onVoidPostRequest(this.state.voidReason);
    }

  render() {

    return (

        <Modal show={this.props.show} onHide={this.props.onVoidCancelRequest}>
        <Modal.Header closeButton>
          <Modal.Title>Void Inspection</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                <div center="true">
                  Why are you voiding this inspection?
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div center="true">
                  <FormControl
                    as="select"
                    placeholder="inspector"
                    type="dropdown"
                    autoComplete="on"
                    value={this.state.voidReason}
                    name="voidReason"
                    onChange={ev =>
                      this.setState({ voidReason: ev.target.value })
                    }
                  >
                    {this.props.voidReasonList.map(vReason => (
                      <option key={vReason} value={vReason}>
                        {vReason}
                      </option>
                    ))}
                  </FormControl>
                </div>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.onVoidReasonOk}>
            Ok
          </Button>
          <Button variant="secondary" onClick={this.props.onVoidCancelRequest}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

    );
  }
}



