import React, { Component } from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight, faImage, faCamera, faThumbsUp, faThumbsDown } from "@fortawesome/free-solid-svg-icons";
import { toCardId, toCardTitle, isEmpty, deleteKey } from "./AttribCardUtils.js";
import "../../assets/css/attrib-cards.css";



export default class AttribYesNoCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardState: "untouched",
      cardValue: {},
      currentValue: ""
    };
    this.Id = toCardId(this.props.attribTitle);
    this.variants = this.props.attribVariants
      ? this.props.attribVariants
      : [""];

    this.renderState = this.renderState.bind(this);
    this.renderUntouchedState = this.renderUntouchedState.bind(this);
    this.renderActiveState = this.renderActiveState.bind(this);
    this.onCardClick = this.onCardClick.bind(this);
    this.onLoseFocus = this.onLoseFocus.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.onValueClick = this.onValueClick.bind(this);
    this.onTouchEnd = this.onTouchEnd.bind(this);
  }

  onTouchEnd(ev) {
    ev.preventDefault();
  }

  renderCameraIcon = () => {
    return <FontAwesomeIcon icon={faCamera} color="yellow" size="2x" style={{ float: "left", paddingLeft: "5px" }} onClick={(ev) => this.onCameraClick(ev)} />
  }



  onValueClick(id, incrementType) {
    console.log("ev: " + id + ":" + incrementType);
    //var currentValue = this.state.cardValue[id];
    var newValue = (incrementType === "+") ? "yes" : "no";
    var newCardValue = this.state.cardValue;
    newCardValue[id] = newValue;
    this.setState({ cardValue: newCardValue });
    this.props.onValueChange(id, newValue);
  }

  onValueChange(ev) {
    console.log(ev);
    this.setState({ cardValue: ev.target.value });
    this.setState({ currentValue: ev.target.value });
    this.props.onValueChange(this.Id, ev.target.value);
  }

  onLoseFocus() {
    var newCardState = (!isEmpty(this.state.cardValue)) ? "touched" : "untouched";
    this.setState({ cardState: newCardState });
  }

  onCardClick(ev) {
    var newState = "untouched";
    if (this.state.cardState === "active") {
      newState = (!isEmpty(this.state.cardValue)) ? "touched" : "untouched";
    } else {
      newState = "active";
    }
    this.setState({ cardState: newState });
    this.props.onToggleCard(this);
  }

  renderUntouchedState() {
    return (
      <div className="nonactivecard nonactivecard-title shadow6 card-template" onClick={this.onCardClick}>
        {(this.props.label) ? this.props.label : this.props.attribTitle}
      </div>
    );
  }


  renderActiveState() {


    let titleTheme = "activecard-title";
    let theme = "activecard card-template shadow6";
    let inputTheme = "activecard-input";
    let themeRaw = "";
    if (this.props.theme) {
      theme = this.props.theme + "yesnocard " + this.props.theme + "card-template";
      titleTheme = "surveyhideelement"
      inputTheme = "surveyyesnoinput"
      themeRaw = this.props.theme;
    }
    return (
      <div
        className={theme}
      >
        <div
          onClick={this.onCardClick}
          className={titleTheme}
        >
          {(this.props.label) ? this.props.label : this.props.attribTitle}
          {(this.state.cardValue[this.Id]) ?
            <span>- {(this.props.caption) ? this.props.caption : ""}</span>
            : <span />
          }
        </div>
        <div className="inputContainer">
          {(this.props.caption) ? this.props.caption : this.props.attribTitle}
          <InputGroup size="sm" className={inputTheme}>
            <div className= {themeRaw+"paddle-left"} onClick={() => this.onValueClick(this.Id, "-")}><FontAwesomeIcon icon={faThumbsDown} /></div>
            <FormControl
              plaintext
              readOnly
              onTouchEnd={(ev) => this.onTouchEnd(ev)}
              textAlign="center"
              // placeholder={(this.props.caption) ? this.props.caption : this.props.attribTitle}
              aria-describedby="basic-addon2"
              name={this.id}
              value={(this.state.cardValue[this.Id])}
              onChange={this.onValueChange}
            />
            <div className={themeRaw+"paddle-right"} onClick={() => this.onValueClick(this.Id, "+")}><FontAwesomeIcon icon={faThumbsUp} /></div>
          </InputGroup>
        </div>
      </div>
    );
  }

  renderTouchedState(){

    return (
      <div
        className="nonactivecard touchedcard shadow7 card-template"
      >
        <div
          onClick={this.onCardClick}
          className="activecard-title"
        >
          {(this.props.label) ? this.props.label : this.props.attribTitle}
          {(this.state.cardValue[this.Id]) ?
            <span>{(this.props.caption) ? ' - ' + this.props.caption : ""}</span>
            : <span />
          }
        </div>
        <div className="inputContainer">
          <span style={{ textAlign: "center" }}>{this.state.cardValue[this.Id]}</span>
        </div>
      </div>
    );
  }

  renderState() {
    if (!this.props.theme) {
      switch (this.state.cardState) {
        case "untouched":
          return this.renderUntouchedState();
        case "touched":
          return this.renderTouchedState();
        default:
          return this.renderActiveState();
      }
    } else {
      return this.renderActiveState();
    }
  }

  render() {
    return this.renderState();
  }
}
