import packageJson from "../package.json";
import moment from "moment";


export default class CacheBuster{
    constructor(){
        this.cachedVer = "";
        this.buildVer  = "";
    }

    buildDateGreaterThan(latestDate, currentDate){
        const momLatestDateTime = moment(latestDate);
        const momCurrentDateTime = moment(currentDate);
      
        if (momLatestDateTime.isAfter(momCurrentDateTime)) {
          return true;
        } else {
          return false;
        }
    };

    async refreshCacheAndReload() {
        if (caches) {
            caches.keys().then(async function(names) {
                await Promise.all(names.map(name => caches.delete(name)));
            });
            window.location.reload(true);
        }
        //localStorage.clear();
    };


    async isLatestBuild(){
        var meta = await fetch(`/meta.json?${new Date().getTime()}`, { cache: 'no-cache' });
        var metaJson = await meta.json();
        const latestVersionDate = metaJson.buildDate;
        const currentVersionDate = packageJson.buildDate;
        this.cachedVer = currentVersionDate;
        this.buildVer = latestVersionDate;  

        const shouldForceRefresh = this.buildDateGreaterThan(
            latestVersionDate,
            currentVersionDate
        ); 
        return (!shouldForceRefresh);
    }

}