import React, { Component } from 'react';
import { Document, Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import AuthService from "../../AuthService/AuthService.js";
import AppConfig from "../../AppConfig/AppConfig.js";
import gcLogo from "../../../assets/images/logos/glasschain-logo.png";


function getSchemaPdfType(schemaId){
    var lcSchemaId = schemaId.toLowerCase();
    if (lcSchemaId.includes('truckinspection')) return "passfail";
    if (lcSchemaId.includes('storevisit')) return "passfail";
    if (lcSchemaId.includes('covid-wellness-check')) return "passfail";
    if (lcSchemaId.includes("-usda")) return "usda";
    if (lcSchemaId.includes("-ma")) return "ma";
    if (lcSchemaId.includes("testplot")) return "passfail";
    return "default";
}

// Create styles here
const styles = StyleSheet.create({
    page: {
        // flexDirection: 'row',
        backgroundColor: 'white'
    },
    logo: {
        width: "6%"
    },
    companyLogo: {
        right: "-3.5in"
    },
    section: {
        display: "flex",
        flexDirection: "row",
        flexWrap:"wrap",
        paddingHorizontal:5
    },
    subsection: {
        maxWidth: "50%",
        minWidth: "40%",
        flexWrap: "wrap",
        padding: 10,
        fontSize: 14
    },
    scoreSection: {
        width: "100%",
        flexWrap: "wrap",
        padding: 10,
        fontSize: 14
    },
    imageWrapper: {
        display: "flex",
        flexDirection: "row"
    },
    imageSection: {
        width: "40%",
        padding: 10,
        // height: "25%",
        // float: "left",

    },
    image: {
 
    },
    imageDescript: {
        fontSize: 10,
        // float: "left"
    },
    imageTags: {
        fontSize: 8,
        // float: "left"
    },
    heading: {
        fontSize: 20,
        paddingHorizontal: 2,
        // borderBottom: "5px",
        // textAlign: "center",
        fontWeight: "bold",
        borderTop: 2,
        marginTop: 5,
        padding: 5,
        // paddingHorizontal: 5,
        // display: "flex",
        // flexDirection: "row",
        // flexWrap:"wrap",
    },
    subheading: {
        fontSize: 20,
        borderBottom: 1
    },

    topHeader: {
        fontSize: "12",
        display: "flex",
        flexDirection: "row",
        textAlign: "center"
    },
    schemaHeading: {
        display: "flex",
        flexDirection: "row",
        alignSelf: "center"
    },
    scoreTable: {
        fontWeight: "bold",
        maxWidth: "100%",
        minWidth: 400,
        marginLeft: 9
    },
    tableHeaders: {
        fontSize: "12",
        display: "flex",
        // padding: 1,
        // width: "12%"
        width: "15%",
        minWidth: 80,
        padding: 3,
        textAlign: "center",
        border: 1
    },
    headerRow: {
        fontSize: "12",
        flexDirection: "row",
        // minWidth: "100%"
    },
    cellRow: {
        fontSize: "12",
        flexDirection: "row"
        // minWidth: "100%",

    },
    cellPassed: {
        backgroundColor: "green",
        // width: "12%",
        // border: 1
        width: "15%",
        minWidth: 80,
        border: 1,
        padding: 3
    },
    cellFailed: {
        backgroundColor: "red",
        // width: "12%",
        // border: 1
        width: "15%",
        minWidth: 80,
        border: 1,
        padding: 3
    },
    cellNotGraded: {
        backgroundColor: "lightgrey",
        // width: "12%",
        // border: 1
        textAlign: "center",
        width: "15%",
        minWidth: 80,
        border: 1,
        padding: 3
    }


});


export default class ObservationPdf extends Component {
    constructor(props) {
        super(props);

        this.obsData = this.props.observation;
        if (!this.obsData.data.PreInspectStatus){
            this.obsData.data.PreInspectStatus = {
                PreInspectReject: "NO",
                Tags: []  
            }
        }
        this.auth = new AuthService();
        this.gcid = this.auth.getGcid();
        this.appConfig = new AppConfig();
        this.inspectDate = new Date(this.obsData.data.Raw.inspectiondate);
        this.totalDefects = "";

        this.schemaPdfType = getSchemaPdfType(this.obsData.data.schema.id);


        this.userImages = this.userImages.bind(this);

    }


    componentDidMount() {
    }

    gcidLogo(){
        return (
        <Image style={styles.companyLogo} src={this.appConfig.getLogoImageBase() + "/gcid/" + this.gcid + "/logos/" + this.gcid + "-logo.png" }
        />
        )
    }

    drawPassFailScore(){
        var result = [];
    result.push(<Text>{"Scoring Schema: " + this.obsData.score.Score.Scorer.id}</Text>);
    result.push(<Text>{"Scoring Schema Version: " + this.obsData.score.Score.Scorer.version}</Text>);
    result.push(<Text>{"Scoring Schema Date: " + new Date(this.obsData.score.Score.Scorer.lastUpdated).toLocaleDateString()}</Text>);
    result.push(<Text>{"Pass: " + this.obsData.score.Score.Grade.Weighted}</Text>);
    return result;
    }

    drawMultAccelScore(){
        return (<Text>Mult/Accel Scoring</Text>)
    }

    isMultAccelGradeRule(gradeRule){
        return (gradeRule.weightedScore); // has weightedScore vs pct used in USDA.
    }

    drawCategorySeverityScore(){
        if (this.schemaPdfType==="passfail") return;
        var result = [];
        var byGrade = this.obsData.score.Score.Gradient;
        var allRules = ["grade"];
        byGrade.forEach(grade =>{
            var byRules = (grade.rules) ? grade.rules: [];
            byRules.forEach(rule =>{
                if  (!allRules.includes(rule.title)){
                    allRules.push(rule.title);
                } 
            })
        });

        // create header
        var headerRowArray = allRules.map(rule=>{
            return <Text style={styles.tableHeaders}>{rule}</Text>
        })
        result.push(<View style={styles.headerRow}>{headerRowArray}</View>);
        
        // for each grade
        byGrade.forEach(grade =>{
            let gradeRowArray = new Array(allRules.length-1);

            var gradeCell = (grade.passed) ? 
                <Text style={styles.cellPassed}>{grade.grade}</Text>:
                <Text style={styles.cellFailed}>{grade.grade}</Text>
            gradeRowArray[0] = gradeCell;
            for (var i=1; i<allRules.length; i++){
                let cell = <Text style={styles.cellNotGraded}> -- </Text>
                var gradeRules = (grade.rules) ? grade.rules: [];
                console.log("grade rules:");
                console.log(gradeRules);
                for (var j=0; j<gradeRules.length; j++){
                    var cellDescript = "";
                    if (gradeRules[j].title === allRules[i]){
                        if (this.isMultAccelGradeRule(gradeRules[j])){ // TBD: multAccell uses a different property and boolean symbol. Should be defined in the schema scoring info itself! 
                            cellDescript = '>' + gradeRules[j].description.split('>')[1] + ' [' + gradeRules[j].weightedScore.toString().substr(0,7) + '%]';
                        }
                        else{
                            cellDescript = '<=' + gradeRules[j].description.split('<=')[1] + ' [' + gradeRules[j].pct.toString().substr(0,7) + '%]'; // must be a USDA-type
                        }
                        cell = (gradeRules[j].passed) ? 
                            <Text style={styles.cellPassed}>{cellDescript}</Text>:
                            <Text style={styles.cellFailed}>{cellDescript}</Text>;
                    }
                }
                gradeRowArray[i] = cell ;
            }
            result.push(<View style={styles.cellRow}>{gradeRowArray}</View>);
    
        });
        
        return result;
    }

    normalizeCategorySeverityDefects(){
        var result = [];
        var byCategory = this.obsData.score.Score.GlobalDefects.ByCategory;
        var byDefect = this.obsData.score.Score.GlobalDefects.ByDefect;
        console.log(this.obsData.score.Score);
        for (var i=0; i<byCategory.length; i++){
            var cat = {"defectGroup": byCategory[i].category +  '-' + byCategory[i].degree, "value": byCategory[i].value, "defects": []};
            for (var j=0; j<byDefect.length; j++){
                if (byDefect[j].category===byCategory[i].category && byDefect[j].degree===byCategory[i].degree){
                    cat.defects.push({"defect": byDefect[j].cardTitle, "count": byDefect[j].count})
                }
            }
            result.push(cat);
        }
        return result;
    }

    normalizePassFailConditions(){
        var result = [];
        var cat = {"defectGroup": "Failed Conditions", "value": null, "defects": []};
        var failedConditions = this.obsData.score.Score.FailedConditions;
        // if for some reason there ARE NO failedConditions, then just re-initialize empty! 
        if (!failedConditions) {
            failedConditions = [];
        }
        failedConditions.forEach(cond =>{

            cat.defects.push({"defect": cond, "count": null})
        });
        result.push(cat);
        return result;
    }



    preInspectRejectDefects(){
        console.log(this.obsData);
        var result = [];
        result.push(<Text>PreInspect Rejection</Text>);
        this.obsData.data.PreInspectStatus.Tags.forEach(tag =>{
            result.push(<Text>--{tag}</Text>)
        });

        return result;
    }



    drawDefects(){
        if (this.obsData.score.Score.IsPreInspectRejected===true){
            return this.preInspectRejectDefects();
        }
        var normalizedDefects = (this.schemaPdfType==="passfail") ? this.normalizePassFailConditions(): this.normalizeCategorySeverityDefects();
        console.log(normalizedDefects);
        var result = [];
        normalizedDefects.forEach((cat) => {

            let catValue = (cat.value) ? "(" + cat.value + ")" : "";
            if (cat.defectGroup.toLowerCase() ==="totaldefects-all"){
                result.push(<Text style={{fontWeight: "heavy", borderTop: 1, borderColor: "grey", paddingTop: "3"}}>Total: {catValue}</Text>);
                result.push(<Text>Total Sampled: {this.obsData.data.Raw.totalcount}</Text>);
            }
            else {
                result.push(<Text>{cat.defectGroup + catValue }</Text>);
            }
            cat.defects.forEach((def) =>{
                if (def.defect!==''){
                    let defCount = (def.count) ? "(" + def.count + ")": "";
                    result.push(<Text>--{def.defect + defCount}</Text>)
                }
            });
            result.push(<Text> </Text>);
        }
        );

        return result;
    }



    userImages(){
        // console.log(this.obsData.data.Raw.userimages);
        if (!this.obsData.data.Raw.userimages) return(<Text>No User Images</Text>);
        return this.obsData.data.Raw.userimages.map(el => {
            console.log(this.appConfig.getObsImageHostAddr(this.gcid, "user") + el.id + ".png");
            console.log("el data from userImage generation func: ")
            console.log(el)
            var tagList = (el.tags) ?
            el.tags.map((tag) =>{
               return tag.label;
           }).join(', ')
            : ""

           var imgExtension;
           if(el.ext === "png"){
               imgExtension = ".png"
           } else if (el.ext === "jpeg"){
               imgExtension = ".jpeg"
           } 

            var returnEl =                     
                <View style={styles.imageSection}>
                    <Image style={styles.image} key={el.id} src={this.appConfig.getObsImageHostAddr(this.gcid, "user") + el.id + imgExtension} alt="user"/>
                    <Text style={styles.imageDescript}>{(el.descript) ? el.descript: "no description provided"}</Text>
                    <Text style={styles.imageTags}>{tagList}</Text>
                </View>;


            return returnEl;
        })
    }



    render() {
        return (
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={styles.heading}>
                        <View style={styles.topHeader}>
                            <Image src={gcLogo} style={styles.logo}/>
                            <View>
                                <Text>Glasschain QA Inspection Sheet</Text>
                                <Text>{this.obsData.score.Score.Schema.name}</Text>
                                <Text>{this.obsData.score.Score.Schema.id}</Text>
                            </View>
                            {this.gcidLogo()}
                        </View>
                        <View style={styles.schemaHeading}>
                            <Text style={{paddingRight: 10}}>{this.obsData.data.Raw.location}</Text>
                            <Text style={{margin: 5, color: "red"}}>{this.obsData.score.Score.Grade.Weighted}</Text>
                            <Text style={{paddingLeft: 10}}>{this.inspectDate.toLocaleDateString()}</Text>
                        </View>
                    </View>
                    <View style={styles.section}>
                        <View style={styles.subsection}>
                            <Text style={styles.subheading}><Image src={gcLogo}/>Under Inspection</Text>
                            <Text>Inspector: {this.obsData.data.UnderInspection.Inspector}</Text>
                            <Text>Schema Version: {this.obsData.score.Score.Schema.schemaVersion}</Text>
                            {/* other inspection vals */}
                        </View>
                        <View style={styles.subsection}>
                            <Text style={styles.subheading}><Image src={gcLogo}/>Comments</Text>
                            <Text>{(this.obsData.data.Raw.comments) ? this.obsData.data.Raw.comments: "No Comments"}</Text>
                        </View>
                        <View style={styles.subsection}>
                            <Text style={styles.subheading}><Image src={gcLogo}/>Environment</Text>
                            <Text>Temp: {this.obsData.data.Environment.AmbientTemperature}</Text>
                            <Text>Weather: {this.obsData.data.Environment.WeatherDescript}</Text>
                            <Text>Lat: {this.obsData.data.Environment.Latitude}</Text>
                            <Text>Lon: {this.obsData.data.Environment.Longitude}</Text>
                            <Text>Address: {this.obsData.data.Environment.Address}</Text>
                            <Text>Wind Speed: {this.obsData.data.Environment.WindSpeed}</Text>
                            <Text>Wind Direction: {this.obsData.data.Environment.WindDirection}</Text>
                            <Text>Humidity: {this.obsData.data.Environment.Humidity}</Text>
                        </View>
                        
                        <View style={styles.subsection}>
                            <Text style={styles.subheading}><Image src={gcLogo}/>Defects</Text>
                                {this.drawDefects()}
                        </View>

                        <View style={styles.scoreSection}>
                            <Text style={styles.subheading}><Image src={gcLogo}/>Score</Text>
                            {this.drawPassFailScore()}
                        </View>
                        <View style={styles.scoreTable}>
                            {this.drawCategorySeverityScore()}
                        </View>
                    </View>
                </Page>
                <Page style={styles.imageSection}>
                        <Text style={styles.subheading}><Image src={gcLogo}/>Images</Text>
                        <View style={styles.imageWrapper}>
                            {this.userImages()}
                        </View>
                </Page>

            </Document>

        )
    }
}