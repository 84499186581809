import {appsettings} from '../../variables/appSettings.js';

const coreServiceHostKey = "glasschain.core.host.addr";
const authServiceHostKey = "glasschain.auth.host.addr";
const notificationServiceHostKey = "glasschain.notification.host.addr";
const baseImageHostKey = "glasschain.image.store.addr";
const baseObsImageHostKey = "glasschain.obs.image.store.addr";
const adapterProxyHostBaseKey = "glasschain.proxy.addr"
const adapterProxyRolesKey = "glasschain.proxy.roles";
const googleVisionApiKey = "google.vision.api.key";

export default class AppConfig{
    constructor(){
        this.appsettings = appsettings;
    }

    getGoogleApiKey(){
        return this.getValue(googleVisionApiKey);
    }

    getCoreServiceHost(){
        return this.getValue(coreServiceHostKey);
    }

    getAuthServiceHost(){
        return this.getValue(authServiceHostKey);
    }

    getImageHostBaseAddr() {
        return this.getValue(baseImageHostKey);
    }

    getNotificationServiceHost(){
        return this.getValue(notificationServiceHostKey);
    }


    getObsImageHostAddr(gcid, imgType){
        // this uses a formula of base host address/[gcid]/[imgType] as convention
        let subdir = (imgType==="user") ? "/obs/" : "/obs/slides/";
        var result = this.getValue(baseObsImageHostKey) + "/" + gcid + subdir;
        return result;
    }

    getAdapterProxyHostAddress(gcid){
        var proxyAddresses = this.getValue(adapterProxyHostBaseKey);
        var results = proxyAddresses.filter(addr => addr.companyGcid === gcid);
        return results[0];
    }

    hasAdapterProxy(gcid){
        var proxyAddresses = this.getValue(adapterProxyHostBaseKey);

        return proxyAddresses.some(addr=> addr.companyGcid === gcid);
    }

    hasAdapterProxyRole(gcid, role){
        if (!this.hasAdapterProxy(gcid)) return false;
        var proxyRoles = this.getValue(adapterProxyRolesKey);
        return proxyRoles.some(prl=> prl.companyGcid === gcid && prl.roles.some( rl=> rl===role));
    }

    getLogoImageBase() {
        return this.getValue(baseImageHostKey) ;
    }

    getVersion(){
        return this.getValue("version");
    }


    hasKey(key){
        return this.appsettings.some(o => o.key === key);
    }

    getValue(key){
        let obj = this.appsettings.find(o => o.key === key);
        return obj.value;
    }



    

}