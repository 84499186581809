import {openDB, idb} from 'idb';
import moment from "moment"


export default class IDbImageService{
    constructor(){
        this.defaultObjectStore = "images";
        this.iDb = {
            imgDb: openDB("imageDb", 1,{
                upgrade: (db, oldVersion, newVersion, transaction) => {
                    switch(oldVersion){
                        case 0:
                            
                             const store = db.createObjectStore('images');
                             store.createIndex("filename", "filename", {unique: true});
                            // fall through so that we can upgrade.
                        case 1:
                            break;
                        default:
                            console.log("unknown db version");
                    }

                }
            })
        };
    }


    async addImage(base64Img, flName){
        (await this.iDb.imgDb).add('images', base64Img, flName);
    }

    async hasImage(flName){
        var existsResult = await (await this.iDb.imgDb).exists("images", flName);
        return (existsResult===undefined);
    }

    async fetchImage(flName){
        var resultData = await (await this.iDb.imgDb).get("images", flName);
        return resultData;
    }

    async deleteImage(flName){
        await (await this.iDb.imgDb).delete("images", flName); 
    }

}



