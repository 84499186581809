import React, { Component } from "react";
import AuthService from "../components/AuthService/AuthService.js";



export default class NavbarLoginExpireTimer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            timerOn: false,
            timerStart: false,
            timerTime: null,
            timerExpiration: null
        };
        this.startTimer = this.startTimer.bind(this);
        this.stopTimer = this.stopTimer.bind(this);
        this.resetTimer = this.resetTimer.bind(this);
        this.auth = new AuthService();

        //this.adjustTimer(21600000);

    
        //this.startTimer();
    }

    componentDidMount() {
        if (!this.auth.loggedIn()){
            return;
        }
        if (!this.timerOn) {
            this.startTimer();
        }
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }



    adjustTimer(newTimerTimeInSeconds) {
        this.setState({ timerTime: newTimerTimeInSeconds });
    }


    startTimer = () => {
        var expiresAt = this.auth.expirationDateTime();
        var newExpireTime = (expiresAt -  new Date().getTime()) - (1000*180);
        this.setState({
            timerExpiration: expiresAt,
            timerOn: true,
            timerTime: newExpireTime,
            timerStart: this.state.timerTime
        });
        this.timer = setInterval(() => {
            // we have to do the math here because user may have focused
            // away from the dashboard, freezing the setinterval. We can't
            // count on the timerTime to be accurate. 
            // take 3 minutes off the expiration so we don't get too close to actual
            // expiration. 
            const newTime = (this.state.timerExpiration -  new Date().getTime()) - (1000*180);
            //const newTime = this.state.timerTime - 10;
            if (newTime >= 0) {
                this.setState({
                    timerTime: newTime
                });
            } else {
                clearInterval(this.timer);
                this.setState({ timerOn: false });
                console.log("Countdown ended");
                this.props.onLoginExpire();
            }
        }, 10);
    };

    stopTimer = () => {
        clearInterval(this.timer);
        this.setState({ timerOn: false });
    };

    resetTimer = () => {
        if (this.state.timerOn === false) {
            this.setState({
                timerTime: this.state.timerStart
            });
        }
    };

    render() {
        const { timerTime, timerStart, timerOn } = this.state;
        let seconds = ("0" + (Math.floor((timerTime / 1000) % 60) % 60)).slice(-2);
        let minutes = ("0" + Math.floor((timerTime / 60000) % 60)).slice(-2);
        let hours = ("0" + Math.floor((timerTime / 3600000) % 60)).slice(-2);

        return (
            <div>
                {hours}:{minutes}:{seconds}
            </div>
        );

    }
}