import {toCardId} from "../../schemaCards/AttribCardUtils.js";


export default class MultiplierAcceleratorScorer {


  info() {
    return {
      id: "multaccel-generic",
      version: "2.03.01",
      lastUpdated: "2020-09-01T12:08:00-07:00",
      updatedBy: "cs",
      description: "Base Scoring Algorithm for Multiplier/Accelerator Inspections",
    };
  }

  validate(schema, values, errors) {
    return true;
  }

  isPreInspectReject(schema, values) {
    if (!schema.scoring.preInspectReject.hasPreInspectReject) return false;
    var preInspectRejectFieldId = schema.scoring.preInspectReject.rejectPropId;
    return values[preInspectRejectFieldId];
  }


  initScoreResult(schema){
    return {
      Score: {
        Scorer: this.info(),
        Schema: schema.schemaInfo,
        ScoreReason: "INSPECTION",
        IsRejected: false,
        IsPreInspectRejected: false,
        TotalInspected: 0,
        Grade: {
          Raw: "Ungraded",
          RawScore: 0,
          Weighted: "Ungraded",
          WeightedScore: 0
        },
        GlobalDefects: {
          ByDefect: null,
          ByCategory: null
        },
        Gradient: []
      }
    }
  }

fetchScoringGroup(schema, groupTitle){
    if (!schema.scoring.globalGroupMultipliers) return 1; 
    var ggm = schema.scoring.globalGroupMultipliers;
    var groupId = toCardId(groupTitle);
    for(var i=0; i<ggm.length; i++){
        if (groupId === toCardId(ggm[i].title)){
            return ggm[i];
        }
    }
    return null;
}

  // if you are passing in card defect type exclusions, be sure to pass in your
  // exclusion list already converted to cardId!
  cardCategoryDegrees(schema, values, totalInspected, defectExclusions) {
    var exclusions = (this.hasDefectExclusions(defectExclusions)) ? this.exclusionsToCardIdFormat(defectExclusions)  : null;
    var result = [];
    var totalDefects = 0;

    // get all groups with a defectCategory
    for (var i = 0; i < schema.categories.length; i++) {;
      for (var j = 0; j < schema.categories[i].groups.length; j++) {
        var group = schema.categories[i].groups[j];
        // is this even a scoring group?
        var scoringGroup = this.fetchScoringGroup(schema, group.groupTitle);
        if (!scoringGroup) continue;
        var multiplier = scoringGroup.multiplier;
          // get all degrees and counts
          for (var x = 0; x < group.cards.length; x++) {
            // TBD - if we ended up with card-specific multipliers, then override multiplier variable, above. 
            var card = group.cards[x];
            var cardId = toCardId(card.title);
            // is this card excluded? Might be excluded because it's not part of the current grade we are evaluating
            if ((exclusions) && (exclusions.includes(cardId))) continue; // do NOT include this!
            // in this schema, variants represent the degrees
            // it's possible we do not HAVE any variants. In that case, create an "empty" variant
            var variants = (card.variants) ? card.variants : [""];
            for (var y = 0; y < variants.length; y++) {
              var degree = variants[y];
              // does this card even have a score?
              if (values[cardId + degree]) {
                var degreeCount = parseInt(values[cardId + degree]);
                totalDefects += degreeCount;
                // now we have the card title, id, the degree and the count
                var cardScore = {
                  category: toCardId(scoringGroup.title),
                  degree: degree,
                  cardTitle: card.title,
                  cardId: cardId,
                  weightedMultiplier: multiplier,
                  rawPct: this.calcRawPct(totalInspected, degreeCount),
                  weightedPct: this.calcWeightedPct(totalInspected, degreeCount, multiplier),
                  count: degreeCount,
                };
                result.push(cardScore);
                // some cards also have special score categories e.g. decay
                if (card.scoreCategory) {
                  var extraCardScore = {
                    category: card.scoreCategory.title,
                    degree: degree,
                    cardTitle: card.title,
                    cardId: cardId,
                    weightedMultiplier: card.scoreCategory.multiplier,
                    rawPct: this.calcRawPct(totalInspected, degreeCount),
                    weightedPct: this.calcWeightedPct(totalInspected, degreeCount, multiplier),
                    count: degreeCount,
                  };
                  result.push(extraCardScore);
                }
              }
            }
          }
        
      }
    }
    var total = {category: "totaldefects", degree: "all", cardTitle: "", cardId: "", count: totalDefects};
    result.push(total);
    return result;
  }

sumCategoryDegrees(cardCatDegrees, totalInspected){
  var result = cardCatDegrees.reduce((acc, val) => {
    var o = acc
      .filter((obj) => {
        return obj.category === val.category && obj.degree === val.degree;
      })
      .pop() || { category: val.category, degree: val.degree, value: 0, weightedMultiplier: val.weightedMultiplier, rawPct: 0, weightedPct: 0 };

    o.value += val.count;
    if (!acc.includes(o)) {
      acc.push(o);
    }
    return acc;
  }, []);

  // now calculate raw and weighted Pcts
  for (var i=0; i<result.length; i++){
      result[i].rawPct = this.calcRawPct(totalInspected, result[i].value);
      //result[i].rawPct = (result[i].value===0) ? 0 : (result[i].value / totalInspected) * 100;
      if (result[i].weightedMultiplier){
        result[i].weightedPct = this.calcWeightedPct(totalInspected, result[i].value, result[i].weightedMultiplier);
        //result[i].weightedPct = (result[i].value===0) ? 0 : ((result[i].value / totalInspected) * 100) * result[i].weightedMultiplier;
      }
      else {
          result[i].weightedPct = 0;
      }
  }
  return result;
}

  calcRawPct(totalInspected, totalDefects){
    var result = (totalDefects===0) ? 0 : (totalDefects / totalInspected) * 100;
    return result;
  }

  calcWeightedPct(totalInspected, totalDefects, weightedMultiplier){
    var result = (totalDefects ===0) ? 0 : ((totalDefects / totalInspected) * 100) * weightedMultiplier;
    return result;
  }


  totalRawDefectsByRule(rule, catDegrees){
    let acc = 0;
    for (var i=0; i<catDegrees.length; i++){
      acc += (rule.catDegreesToInclude.includes(catDegrees[i].category + catDegrees[i].degree)) ?  catDegrees[i].rawPct : 0;
    }
    return acc;
  }

  totalWeightedDefectsByRule(rule, catDegrees){
    let acc = 0;
    for (var i=0; i<catDegrees.length; i++){
      acc += (rule.catDegreesToInclude.includes(catDegrees[i].category + catDegrees[i].degree)) ?  catDegrees[i].weightedPct : 0;
    }
    return acc; 
  }


  testRule(rule, totalInspected, catDegrees){
    var totalRawDefects = this.totalRawDefectsByRule(rule, catDegrees);

    var totalWeightedDefects = this.totalWeightedDefectsByRule(rule, catDegrees);
    var rawScore = 100-totalRawDefects;
    var weightedScore = 100-totalWeightedDefects;
    //var rulePct = (totalDefects===0) ? 0 : (totalDefects / totalInspected)*100;
    var rulePassed = (weightedScore > rule.weightedCutoff);
    var ruleResult = {title: rule.title, description: rule.description, totalInspected: totalInspected, totalDefects: totalRawDefects, 
        cutoff: rule.weightedCutoff, rawScore: rawScore, weightedScore: weightedScore, passed: rulePassed};
    return ruleResult;
  }


  

  score(schema, values) {
    var result = this.initScoreResult(schema);
    console.log("result: ");
    console.log(result);

    // first deal with pre-inspect rejects
    if (this.isPreInspectReject(schema, values)) {
      result.Score.IsPreInspectRejected = true;
      result.Score.IsRejected = true;
      result.Score.Grade.Weighted = "REJECT";
      result.Score.Grade.WeightedScore = 0;
      result.Score.Grade.Raw = "REJECT";
      result.Score.Grade.RawScore = 0;
      return result;
    }

    var totalInspected = values[schema.scoring.totalQtyPropId];
    result.Score.TotalInspected = totalInspected;

    // test if we need to recalculate cardCatDegrees based on exclusion or collapse rules. 
    // If so, then we have to calculate and show those rules AND the cardCatDegrees on EACH grade evaluation
    // If NOT, then we'll just calculate and show it once.
    var schemaHasGradeSpecificRules = this.schemaHasGradeSpecificRules(schema);
    // isolate all card/category/degree counts globally, in case the specific grade does NOT have exclusions/collapses
    var globalCardCatDegrees = this.cardCategoryDegrees(schema, values, totalInspected);
    // "reduce" to sums of JUST category/degrees
    var globalCatDegrees = this.sumCategoryDegrees(globalCardCatDegrees, totalInspected);
    result.Score.GlobalDefects.ByDefect = globalCardCatDegrees;
    result.Score.GlobalDefects.ByCategory = globalCatDegrees;
    result.Score.GlobalDefects.GradeSpecificRules = schemaHasGradeSpecificRules;
    if (schemaHasGradeSpecificRules){
      result.Score.GlobalDefects.Notes = "This schema has grade-specific exclusion/collapse rules! See individual grades for details";
    }
  
    // keep track of last raw and weighted score in case we drop below grades
    var lastRawScore = 0;
    var lastWeightedScore = 0;
    // FOREACH GRADE
    for(var i=0; i<schema.scoring.gradeGradient.length; i++){
      // iterate through each RULE in the gradient
      var grade = schema.scoring.gradeGradient[i];
      var gradeSpecificRules = this.gradeHasSpecificRules(grade);
      // init the grade result
      var gradeResult = {grade: grade.title, description: grade.description, exclusions: grade.excludedDefects, collapses: grade.variantCollapseDefects, rules: []};
      var cardCatDegrees = (gradeSpecificRules) ? this.cardCategoryDegrees(schema, values, totalInspected, grade.excludedDefects, grade.variantCollapsedDefects) : globalCardCatDegrees;
      var catDegrees = (gradeSpecificRules) ? this.sumCategoryDegrees(cardCatDegrees, totalInspected) : globalCatDegrees;
      if (gradeSpecificRules){
        // show byDefect and byCategory again for each grade because they may be different
        gradeResult.Defects = {};
        gradeResult.Defects.ByDefect = cardCatDegrees;
        gradeResult.Defects.ByCategory = globalCatDegrees;
      }
      for(var j=0; j<grade.rules.length; j++){
         var ruleResult = this.testRule(grade.rules[j], totalInspected, catDegrees);
         gradeResult.rules.push(ruleResult);
      }
      // at this point we should now if the inspection passed this grade or not. We can't assume it's all ANDs but I'll TBD.
      var gradePassed = gradeResult.rules.every((rule) => rule.passed);
      gradeResult.passed = gradePassed;
      result.Score.Gradient.push(gradeResult);
      // This needs explanation. Because the actual counts of defects (raw and weighted) could change by rule, let alone
      // by grade (e.g. if you excluded some defects in some grades) there isn't really a single raw/weighted score. BUT,
      // because Cal Giant is using this we KNOW that they do NOT modify rules by gradients so we can be sure that the
      // raw/weighted scores within specific rules will ALWAYS be the same. So we'll just pull it from the FIRST rule we find. 
      lastRawScore = gradeResult.rules[0].rawScore;
      lastWeightedScore = gradeResult.rules[0].weightedScore;
      if (gradeResult.passed){
        // we're done! 
        result.Score.Grade.Raw = gradeResult.grade;
        result.Score.Grade.RawScore = lastRawScore;
        result.Score.Grade.Weighted = gradeResult.grade;
        result.Score.Grade.WeightedScore = lastWeightedScore
        return result;
      }

    }
    // if we didn't pass we keep going until we reach the LAST grade in the gradient and our grade is done. 
    var cutoffGradeResult = {grade: schema.scoring.cutoffGrade.title, description: schema.scoring.cutoffGrade.description};
    result.Score.Gradient.push(cutoffGradeResult);
    result.Score.Grade.Weighted = cutoffGradeResult.grade;
    result.Score.Grade.WeightedScore = lastWeightedScore;
    result.Score.Grade.Raw = cutoffGradeResult.grade;
    result.Score.Grade.RawScore = lastRawScore;
    if (schema.scoring.cutoffGrade.reject){
      result.Score.IsRejected = true;
    }
    return result;

  }



  schemaHasGradeSpecificRules(schema){
    for(var i=0; i<schema.scoring.gradeGradient.length; i++){
      var grade = schema.scoring.gradeGradient[i];
      if (this.hasDefectExclusions(grade.excludedDefects)){
        return true;
      }
    }
    return false;
  }
  
  gradeHasSpecificRules(grade){
    return this.hasDefectExclusions(grade.excludedDefects);
  }

  hasDefectExclusions(excludedDefects){
    return ((excludedDefects) && (excludedDefects.length>0));
  }

  exclusionsToCardIdFormat(exclusions){
    // assumes just an array of Card Ids
    return exclusions.map((excl) => {
      return toCardId(excl);
    })
  }

}
