import React, { Component } from "react";
import {Form,InputGroup,} from "react-bootstrap";
import "../../assets/css/attrib-cards.css";
import {toCardId, toCardTitle, removeArrayValue} from "./AttribCardUtils.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage, faCamera} from "@fortawesome/free-solid-svg-icons";






export default class AttribChecklistBoxCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardState: "untouched",
      cardValue: null,
      enabled: false,
      valid: true,
      descript: ""
    };

    this.Id = toCardId(this.props.attribTitle);

    this.renderState = this.renderState.bind(this);
    this.renderUntouchedState = this.renderUntouchedState.bind(this);
    this.renderActiveState = this.renderActiveState.bind(this);
    this.onCardClick = this.onCardClick.bind(this);
    this.onLoseFocus = this.onLoseFocus.bind(this);
    this.onToggle = this.onToggle.bind(this);
    this.renderCriteriaIcon = this.renderCriteriaIcon.bind(this);
    this.onCriteriaClick = this.onCriteriaClick.bind(this);

  }

  componentDidUpdate(prevProps, prevState){
    if (typeof(this.props.valueBag[this.Id])==='undefined') return;
    let oldVal = prevState.cardValue;
    //console.log("valueBag: ");
    //console.log(this.props.valueBag);
    //console.log(typeof(this.props.valueBag[this.Id]));
    let newVal = this.props.valueBag[this.Id];
    //console.log("Id: " + this.Id + " Old: " + oldVal + " New: " + newVal);

    if (oldVal !== newVal){
      this.setState({
        cardValue: newVal,
        enabled: newVal
      });
      if (this.state.cardState !== "active"){
        this.setState({cardState: "touched"})
      }
    }
  }


  // toggle the entire pre-inspect reject
  onToggle(ev) {
    console.log(ev.target.checked);
    let isOn = ev.target.checked;
    let currValue = this.state.cardValue;
    
    if(currValue){this.setState({
      currValue: false
    })} else {
      this.setState({
        currValue: true
      })
    }

    this.setState({ 
      enabled: isOn,
      cardValue: currValue
     });
    this.props.onValueChange(this.Id, ev.target.checked);
  }


  onLoseFocus() {
    var newCardState =  "touched";
    this.setState({ cardState: newCardState });
  }

  onCardClick(ev) {
    var newState = "untouched";

    if (this.state.cardState === "active"){

      console.log("cardValue: " + this.state.cardValue);
      newState = (this.state.cardValue !== null) ? "touched" : "untouched";
    } else {
      newState = "active";
    }
    this.setState({ cardState: newState });
    this.props.onToggleCard(this);
  }

  renderCameraIcon =() =>{
    return <FontAwesomeIcon icon={faCamera} color="yellow" size="2x" style={{float: "left", paddingLeft: "5px"}} onClick={(ev) => this.onCameraClick(ev)}/> 
  }

  onCameraClick(ev){
    ev.preventDefault();
    ev.stopPropagation();
    this.props.onRequestCameraDialogOpen(this.Id);
  }

   renderCriteriaIcon =()=>{
    if (this.props.criteriaSlides){
      return <FontAwesomeIcon icon={faImage} color="yellow" size="2x" style={{float: "right", paddingRight: "5px"}} onClick={(ev) => this.onCriteriaClick(ev)}/> 
    }
    else {
      return <span/>
    }
  }

  onCriteriaClick(ev){
    ev.preventDefault();
    ev.stopPropagation();  // stop bubbling up to the card itself
    this.props.onRequestCriteriaSlidesOpen(this.props.criteriaSlides);
  }

  renderUntouchedState() {
    return (
      <div className="nonactivecard card-template shadow6 " onClick={this.onCardClick}>
        {this.props.attribTitle}
        {this.renderCriteriaIcon()}
      </div>
    );
  }



  renderActiveState() {
    const caption = (this.props.caption) ? this.props.caption: "";
    

    return (
      <div
        className="activecard shadow6 card-template"
      >
        <div onClick={this.onCardClick}>
        {this.renderCameraIcon()}
          {this.props.attribTitle}
          {this.renderCriteriaIcon()}
        </div>
        <div>
          <InputGroup className="checkboxlist-input">
            <Form.Check
                style={{zoom:"3"}}
              type="checkbox"
              checked={this.state.enabled}
              name={this.props.attribTitle}
              onChange={(ev) => {
                this.onToggle(ev);
              }}
            />
            <span>{caption}</span>
          </InputGroup>
        </div>
    </div>
    );
  }

  renderTouchedState() {
    const bkgColor = (this.state.enabled) ? "lightgreen" : "red";
    const fntColor = (this.state.enabled) ? "black" : "yellow"; 
    return (
      <div
        className="nonactivecard"
        onClick={this.onCardClick}
        style={{ backgroundColor: bkgColor }}
      >
        <div style={{ fontSize: "20px", color: fntColor}}>
          {this.props.attribTitle.toUpperCase()}
          {this.renderCriteriaIcon()}
        </div>
      </div>
    );
  }

  renderState() {
    switch (this.state.cardState) {
      case "untouched":
        return this.renderUntouchedState();
      case "touched":
        return this.renderTouchedState();
      default:
        return this.renderActiveState();
    }
  }

  render() {
    return this.renderState();
  }
}
