import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';
import 'react-toastify/dist/ReactToastify.min.css';
import queryString from "query-string";

import AuthService from "../components/AuthService/AuthService.js";
import MainNavbar from "../components/local/panels/MainNavbar.jsx";
import HomePage from "../pages/HomePage.jsx";
import AuthLayout from "./AuthLayout.jsx";
import InspectionCardLayout from "../layouts/InspectionCardLayout.jsx";
import SurveyLayout from "../layouts/SurveyLayout.jsx";

import ModalAboutDialog from "../components/Modals/ModalAboutDialog.jsx";
import ModalSlideDialog from "../components/Modals/ModalSlideDialog.jsx";

import OfflineChef from "../components/DataService/OfflineChef.js";

function tagsToString(tags){
  if (!tags) return "";
  var result = "";
  for (var i=0; i<tags.length; i++){
    if (tags[i]){
      result += tags[i].label + ", ";
    }
  }
  return result;
}

export default class HomeLayout extends Component {
  constructor(props) {
    super(props);

    this.auth = new AuthService();
    this.gcid = this.auth.getGcid();

    this.state = {
      isOnline: false, // meaning the user has network access
      isAirplaneMode: false, // the user WANTS to be disconnected, if possible
      isConnected: false, // are we accessing network resources(because we are onLine AND NOT in Airplane Mode)
      navbar: false,
      showAbout: false,
      editableSchemaId: null,
      loadedObsToast: false,
      syncing: false,
      syncText: "",
      showSlides: false,
      slideInfo: { title: "", slides: [{ url: "", caption: "" }] }
    };

    this.auth = new AuthService();

    this.onRequestAboutDialogOpen = this.onRequestAboutDialogOpen.bind(this);
    this.onRequestAboutDialogClose = this.onRequestAboutDialogClose.bind(this);
    this.onRequestLogout = this.onRequestLogout.bind(this);
    this.onRequestNewObservation = this.onRequestNewObservation.bind(this);
    this.onRequestUpdateObservation = this.onRequestUpdateObservation.bind(this);
    this.onRequestRecallSuspendedObservation = this.onRequestRecallSuspendedObservation.bind(this);
    this.handleConnectionChange = this.handleConnectionChange.bind(this);

    this.onRouteHomeRequest = this.onRouteHomeRequest.bind(this);

    this.onPrepOfflineRequest = this.onPrepOfflineRequest.bind(this);
    this.onPrepOfflineSuccess = this.onPrepOfflineSuccess.bind(this);

    this.onToggleAirplaneModeRequest = this.onToggleAirplaneModeRequest.bind(this);

    this.onViewUserImagesRequest = this.onViewUserImagesRequest.bind(this);
    this.onUserImagesClose = this.onUserImagesClose.bind(this);

    this.onSystemLogoutRequest = this.onSystemLogoutRequest.bind(this);

    this.sendToast = this.sendToast.bind(this);

  }

  // should be called whenever the system determines that user is not logged in.
  onSystemLogoutRequest(){
    if (this.state.isConnected){
      // TBD: user may NOT be connected, because s/he is in airplaneMode but still has a connection! 
      // this would allow users to continue using the system while online (but without ability to post)
      // is this okay? If not, then check that we have a strong connection to core service (via ping)
      // and force the logout anyway...
      console.group("Logging out!");
      console.log("logging out");
      console.groupEnd();
      this.props.history.replace("/auth");
    }
    else {
      // user is not logged in. Let user continue offline.
      // do nothing!  
    }
  }


  onPrepOfflineSuccess(){
    console.log("Sync Success!")
    this.setState({
      syncing: false,
      syncText: ""
    });
  }

  async componentDidMount() {
    this.handleConnectionChange();
    window.addEventListener('online', this.handleConnectionChange);
    window.addEventListener('offline', this.handleConnectionChange);
    if (!this.auth.loggedIn()) {
      console.group("Logged in?")
      console.log("Not logged in");
      console.groupEnd();
      this.onSystemLogoutRequest();
    }
    // toast alerts
    const queryVals = queryString.parse(this.props.location.search);
    /*
    if (queryVals.prep){
      await new OfflineChef().prepOffline();
      toast.info("Schemas loaded...");
      this.setState({loadedObsToast: true});
    }
    */
    if (queryVals.i){
      if (!this.state.loadedObsToast){
        toast.info("Observation " + queryVals.i);
        this.setState({loadedObsToast: true});
      }
    }
  }

  componentWillUnmount(){
    window.removeEventListener('online', this.handleConnectionChange);
    window.removeEventListener('offline', this.handleConnectionChange);
  }

  sendToast(msg, messageType)
  {
    var msgType = messageType.toLowerCase() || "info";
    if (msgType === "info"){ toast.info(msg);}
    else if (msgType === "success"){ toast.success(msg);}
    else if (msgType === "warning"){ toast.warning(msg);}
    else if (msgType === "error"){ toast.error(msg);}
    else {toast.default(msg);}
  }



  handleConnectionChange = () => {

    const condition = navigator.onLine ? 'online' : 'offline';
    /*
    if (condition === 'online') {
      const webPing = setInterval(
        () => {
          // TBD - move to pinging our core
          fetch('//google.com', {
            mode: 'no-cors',
            })
          .then(() => {
            this.setState({ isConnected: true }, () => {
              return clearInterval(webPing)
            });
          }).catch(() => this.setState({ isConnected: false }) )
        }, 120000);
      return;
    }
    */
   let newIsOnline = (condition === 'online');
   var newIsAirplaneMode = this.auth.getAirplaneMode();
   this.setState({isAirplaneMode: newIsAirplaneMode});
   console.log('condition: ' + condition);
   console.log("Airplane Mode: " + newIsAirplaneMode);
   let newIsConnected = ((newIsAirplaneMode!==true) && (newIsOnline));
   console.log("Is Connected: " + newIsConnected);
   this.setState({
     isOnline: newIsOnline,
     isConnected: newIsConnected
   });
  }

  onToggleAirplaneModeRequest(){
    console.log("toggling airplane mode");
    var newIsAirplaneMode = !this.state.isAirplaneMode;
    this.auth.setAirplaneMode(newIsAirplaneMode);
    let newIsConnected = ((newIsAirplaneMode!==true) && (this.state.isOnline));
    this.setState({
      isAirplaneMode: newIsAirplaneMode,
      isConnected: newIsConnected
    });
  }

  onViewUserImagesRequest(userImages){
    if (!userImages) return;
    //{ title: "", slides: [{ url: "", caption: "" }] }
    var slides = userImages.map((img) => {
      console.log(img);
      var ext = (img.ext) ? img.ext : "png";
      return {imgFileName: img.id + "." + ext, title: img.descript, caption: img.descript + (img.tags) ? tagsToString(img.tags): ""}
    });
    this.setState({
      showSlides: true,
      slideInfo: {title: "User Images", slides: slides}
    })
  }

  onUserImagesClose(){
    this.setState({showSlides: false});
  }


  async onPrepOfflineRequest(){
    await this.prepOffline();
  }

  async prepOffline(){
    // TBD - check that we are ONLINE! Can't do this if you are NOT online
    // TBD - spinner showing that we are prepping for going offline
    // Fetch EACH schema for this gcid
    this.setState({
      syncing: true,
      syncText: "Preparing Observation schemas..."
    });
    await new OfflineChef().prepOffline(this.gcid, this.onPrepOfflineSuccess);
  }


  onRouteHomeRequest(){
    this.props.history.replace("/home/home-page");
  }


  onRequestAboutDialogOpen() {
    this.setState({ showAbout: true });
  }

  onRequestAboutDialogClose() {
    this.setState({ showAbout: false });
  }


  onRequestLogout() {
    this.props.history.replace("/auth");
  }



  onRequestNewObservation(schemaId) {
    this.setState({ editableSchemaId: schemaId });
    this.auth.removeAllLocalSuspendedObservations(this.gcid, this.auth.getUser());
    if (schemaId === 'COVID-WELLNESS-CHECK'){
      this.props.history.replace("/surveynew?schemaid=" + schemaId);
    }
    else {
      this.props.history.replace("/inspectionnew?schemaid=" + schemaId);
    }
  }

  onRequestRecallSuspendedObservation(schemaId) {
    this.setState({ editableSchemaId: schemaId });
    if (schemaId === 'COVID-WELLNESS-CHECK'){
      this.props.history.replace("/surveynew?schemaid=" + schemaId);
    }
    else {
      this.props.history.replace("/inspectionnew?schemaid=" + schemaId + "&suspended=true");
    }
  }

  onRequestUpdateObservation(observationId){
    this.auth.removeAllLocalSuspendedObservations(this.gcid);
    this.props.history.replace("/inspectionnew?id=" + observationId);
  }

  render() {
    return (
      <div className="wrapper">
        <div className="main-panel" ref="mainPanel">
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />

        <ModalSlideDialog
          gcid={this.gcid}
          slideType = {"user"}
          showSlides={this.state.showSlides}
          slideInfo={this.state.slideInfo}
          onRequestCriteriaSlidesClose={this.onUserImagesClose}
        />

          <ModalAboutDialog
            show={this.state.showAbout}
            isConnected={this.state.isConnected}
            onRequestAboutDialogClose={this.onRequestAboutDialogClose}
          />
          <MainNavbar
            isAirplaneMode={this.state.isAirplaneMode}
            isOnline={this.state.isOnline}
            isConnected={this.state.isConnected}
            onRequestAboutDialogOpen={this.onRequestAboutDialogOpen}
            onRequestLogout={this.onRequestLogout}
            onLoginExpire={this.onRequestLogout}
            onRouteHomeRequest={this.onRouteHomeRequest}
            onPrepOfflineRequest={this.onPrepOfflineRequest}
            syncing={this.state.syncing}
            syncText={this.state.syncText}
            onToggleAirplaneModeRequest={this.onToggleAirplaneModeRequest}


          />
          <Switch>
            <Route
              path="/home/home-page"
              render={(props) => (
                <HomePage
                  {...props}
                  isConnected={this.state.isConnected}
                  onSystemLogoutRequest={this.onSystemLogoutRequest}
                  onRequestNewObservation={this.onRequestNewObservation}
                  onRequestUpdateObservation={this.onRequestUpdateObservation}
                  onRequestRecallSuspendedObservation={this.onRequestRecallSuspendedObservation}
                  onViewUserImagesRequest = {this.onViewUserImagesRequest}
                  onToastRequest = {this.sendToast}
                />
              )}
            />
            <Route
              path="/inspectionnew"
              render={(props) => (
                <InspectionCardLayout
                  {...props}
                  isConnected={this.state.isConnected}
                  onSystemLogoutRequest={this.onSystemLogoutRequest}
                  editType={this.state.editType}
                  schemaId={this.state.editableSchemaId}
                  id={this.observationId}
                />
              )}
            />

            <Route
              path="/surveynew"
              render={(props) => (
                <SurveyLayout
                  {...props}
                  isConnected={this.state.isConnected}
                  onSystemLogoutRequest={this.onSystemLogoutRequest}
                  editType={this.state.editType}
                  schemaId={this.state.editableSchemaId}
                  id={this.observationId}
                />
              )}
            />


            <Route path="/auth" component={AuthLayout} />
            <Redirect from="/" to="/home/home-page" />
            <Redirect from="/home" to="/home/home-page" />
          </Switch>
        </div>
      </div>
    );
  }
}
