import React, { Component } from "react";
import { Card, InputGroup, Button, Container, Row, Col } from "react-bootstrap";
import queryString from "query-string";
import "bootstrap/dist/css/bootstrap.min.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
//import "ag-grid-community/dist/styles/ag-theme-material.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faSyncAlt,faEdit,faBan,faSave,faBookmark,faTrashRestore,faFileExport, faFilePdf, faImages, faExternalLinkAlt} from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import IsoUtcDateTimeToLocalRenderer from "../../AgGridRenderers/IsoUtcDateTimeToLocalRenderer.jsx";
import DateTimeCellRenderer from "../../AgGridRenderers/DateTimeCellRenderer.jsx";
import OrgConfiguration from "../../../models/OrgConfiguration.js";
import GridLayout from "../../../models/GridLayout.js";


import ModalAlertDialog from "../../Modals/ModalAlertDialog.jsx";

import moment from "moment";

import GlasschainHttpClient from "../../ApiService/GlasschainHttpClient.js";
import AuthService from "../../AuthService/AuthService.js";

const GRIDCOLSTATE = "gridcolstate";
const GRIDSORTMODE = "gridsortmode";
const GRIDFILTERMODE = "gridfiltermode";



export default class ObservationsGridCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gridSchema: [],
      schema: null,
      gridData: [],
      isLoading: true,
      selectedRow: null,
      showAlert : false,
      alertTitle : "",
      alertMessage: "",
      alertType: "info"  // error/warning/info
    };

    this.auth = new AuthService();
    this.httpClient = new GlasschainHttpClient();
    this.gcid = this.auth.getGcid();

    this.refreshObservationGridSchema = this.refreshObservationGridSchema.bind(
      this
    );
    this.refreshGridData = this.refreshGridData.bind(this);
    this.onBtnExportDataAsCsv = this.onBtnExportDataAsCsv.bind(this);
    this.onBtnSaveLayout = this.onBtnSaveLayout.bind(this);
    this.onBtnResetLayout = this.onBtnResetLayout.bind(this);
    this.onBtnRestoreLayout = this.onBtnRestoreLayout.bind(this);
    this.onUpdateRequest = this.onUpdateRequest.bind(this);
    this.onVoidRequest = this.onVoidRequest.bind(this);
    this.onPdfRequest = this.onPdfRequest.bind(this);
    this.onViewUserImagesRequest = this.onViewUserImagesRequest.bind(this);

    this.showAlertDialog = this.showAlertDialog.bind(this);
    this.onCloseAlert = this.onCloseAlert.bind(this);
  }

  async componentDidMount() {
    if (this.props.isConnected){
      if (!this.auth.loggedIn()){
        console.log("ObservationsGridCard componentDidMount - not logged in");
        this.props.onSystemLogoutRequest();        
      }
    }
    await this.refreshObservationGridSchema();
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.props.isConnected){
      if (!this.auth.loggedIn()){
        console.log("ObservationsGridCard componentDidUpdate - not logged in");
        this.props.onSystemLogoutRequest();        
      }
    }
    if (this.props.gridQueryString !== prevProps.gridQueryString) {
      await this.refreshGridData(this.props.gridQueryString);
    }
  }


  showAlertDialog(title, message, type){  //error warning info
    this.setState({alertTitle: title, alertMessage: message, alertType: type, showAlert: true});
  }

  onCloseAlert(){
    this.setState({showAlert: false});
  }


onViewUserImagesRequest(){
  if (!this.state.selectedRow) {
    this.showAlertDialog("Select an Inspection", "Please select an observation first!", "error");
    return;
  }
  if (!this.state.selectedRow.data.Raw.userimages){
    this.showAlertDialog("The selected observation doesn't have any user images to display!", "error");
    return; 
  }
  this.props.onViewUserImagesRequest(this.state.selectedRow.data.Raw.userimages);  
}
  
onPdfRequest(){
  if (!this.state.selectedRow) {
    this.showAlertDialog("Select an Inspection", "Please select an observation first!", "error");
    return;
  }
  this.props.onObservationPdfRequest(this.state.selectedRow.identifiers.id);
  
}

  async onUpdateRequest() {
    if (!this.state.selectedRow) {
      this.showAlertDialog("Select an Inspection", "Please select an observation to update first!", "error");
      return;
    }
    var rule = OrgConfiguration.RuleBySchemaId(this.props.orgConfig, this.props.schemaId);
    if (!rule.allowUpdates) {
      this.showAlertDialog("Updates not Allowed", "Your organization does not allow Updates", "error");
      return;
    }
    if (rule.hasUpdateExpiration) {
      var observationDateTime = moment(this.state.selectedRow.data.UnderInspection.InspectionDate);
      var boundaryDateTime = moment(observationDateTime).add(rule.allowUpdatesAfterXHours,"hours");
      if (moment() > moment(boundaryDateTime)) {
        this.showAlertDialog("Organization Update Rule","Sorry. This inspection cannot be updated more than " + rule.allowUpdatesAfterXHours + " hours after it was created." , "error");
        return;
      }
      this.props.onRequestUpdateObservation(this.state.selectedRow.identifiers.id);
    }
  }

  async onVoidRequest() {
    if (!this.state.selectedRow) {
      this.showAlertDialog("Select an Inspection", "Please select an observation to void first!", "error");
      return;
    }
    var rule = OrgConfiguration.RuleBySchemaId(this.props.orgConfig, this.props.schemaId);
    if (!rule.allowVoids) {
      this.showAlertDialog("Voids not Allowed", "Your organization does not allow Voids", "error");
      return;
    }
    if (rule.hasVoidExpiration) {
      var observationDateTime = moment(this.state.selectedRow.data.UnderInspection.InspectionDate);
      var boundaryDateTime = moment(observationDateTime).add(rule.allowVoidsAfterXHours,"hours");
      if (moment() > moment(boundaryDateTime)) {
        this.showAlertDialog("Organization Void Rule","Sorry. This inspection cannot be voided more than " + rule.allowVoidsAfterXHours + " hours after it was created." , "error");
        return;
      }
      this.props.onVoidRequest(this.state.selectedRow.identifiers.id);
    }   
  }


  async refreshObservationGridSchema() {
    //var newGridSchema = await this.httpClient.fetchObservationGridSchema(this.props.schemaId);
    //var newGridSchema = this.auth.getLocalGridSchema(this.props.schemaId);
    var schemaData = this.auth.getLocalSchema(this.props.schemaId);
    var fullSchema = JSON.parse(schemaData.fullSchema);
    // now we have to BUILD the grid layout. 
    var newCleanGridSchema = GridLayout.toAgGridLayout(fullSchema.gridLayout);
    this.setState({gridSchema: newCleanGridSchema,});
  }

  async refreshGridData() {
    this.setState({ isLoading: true });
    var gridDataResponse = await this.httpClient.fetchObservationList(this.props.gridQueryString);
    var newGridData = gridDataResponse.data;

    const queryVals = queryString.parse(this.props.gridQueryString);
    this.props.onDetailedGridRowChange(null);
    this.setState({
      gridData: newGridData,
      isLoading: false,
    });
  }

  onEditGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    var savedColState = JSON.parse(this.auth.getUserProp(GRIDCOLSTATE));
    if (savedColState) {
      this.gridColumnApi.setColumnState(savedColState);
    }
  };

  onSelectionChanged() {
    var selectedNodes = this.gridApi.getSelectedNodes();
    var rowData = selectedNodes[0].data;
    this.setState({selectedRow: rowData});
    this.props.onDetailedGridRowChange(rowData);
  }

  // Controls
  onBtnExportDataAsCsv() {
    const saveParams = {
      allColumns: true,
      onlySelected: false,
      onlySelectedAllPages: false,
      skipHeader: false,
    };
    this.gridApi.exportDataAsCsv(saveParams);
  }

  onBtnSaveLayout() {
    var newGridColState = this.gridColumnApi.getColumnState();
    this.auth.setUserProp(
      this.state.schemaId + "::" + GRIDCOLSTATE,
      JSON.stringify(newGridColState)
    );
  }

  onBtnResetLayout() {
    this.gridColumnApi.resetColumnState();
    this.gridColumnApi.resetColumnGroupState();
    this.gridApi.setSortModel(null);
    this.gridApi.setFilterModel(null);
  }

  onBtnRestoreLayout() {
    var savedColState = JSON.parse(
      this.auth.getUserProp(this.state.schemaId + "::" + GRIDCOLSTATE)
    );
    if (savedColState) {
      this.gridColumnApi.setColumnState(savedColState);
    }
  }

  render() {
    const loadingIcon = this.state.isLoading ? (
      <FontAwesomeIcon icon={faSyncAlt} color="green" spin />
    ) : (
      ""
    );

    const divStyle = {
      width: "100%",
      height: "calc(45vh)",

      margin: "5px",
      //border: '5px solid black'
    };

    const columnDefs = this.state.gridSchema ? this.state.gridSchema : [];

    const gridData = this.state.gridData ? this.state.gridData : [{}];

    return (

      <span>
        <ModalAlertDialog
          show={this.state.showAlert}
          title={this.state.alertTitle}
          iconType={this.state.alertType} // error|warning|info
          message={this.state.alertMessage}
          onClose={this.onCloseAlert}

        />
      <Card id="historyPanelCard">
        <Card.Header>
          <span style={{ float: "left" }}>Observation History</span>
          <span style={{ float: "right" }}>{loadingIcon}</span>
        </Card.Header>
        <ReactTooltip />
        <Card.Body>
          <div style={{ width: "100%", height: "100%" }}>
            <Container fluid>
                  <InputGroup size="sm">
                    <p data-tip="EXPORT grid data to csv">
                      <Button
                        size="sm"
                        variant="outline-info"
                        onClick={this.onBtnExportDataAsCsv}
                      >
                        <FontAwesomeIcon icon={faFileExport} />
                        &nbsp; export
                      </Button>
                    </p>
                    <p data-tip="UPDATE non-scoring inspection information (if within allowable timeframe)">
                      <Button
                        size="sm"
                        variant="outline-info"
                        onClick={this.onUpdateRequest}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                        &nbsp;update
                      </Button>
                    </p>
                    <p data-tip="VOID the currently selected observation (if within allowable timeframe)">
                      <Button
                        size="sm"
                        variant="outline-info"
                        onClick={this.onVoidRequest}
                      >
                        <FontAwesomeIcon icon={faBan} />
                        &nbsp;void
                      </Button>
                    </p>
                    <p data-tip="Generate a PDF Sheet of the selected observation">
                      <Button
                        size="sm"
                        variant="outline-info"
                        onClick={this.onPdfRequest}
                      >
                        <FontAwesomeIcon icon={faFilePdf} />
                        &nbsp;pdf
                      </Button>
                    </p>

                    <p data-tip="Send a message about this observation to others">
                      <Button
                        size="sm"
                        variant="outline-info"
                        onClick={this.props.onSendMsgRequest}
                      >
                        <FontAwesomeIcon icon={faExternalLinkAlt} />
                        &nbsp;share
                      </Button>
                    </p>
                    <p data-tip="view observation images">
                      <Button
                        size="sm"
                        variant="outline-info"
                        onClick={this.onViewUserImagesRequest}
                      >
                        <FontAwesomeIcon icon={faImages} />
                        &nbsp;images
                      </Button>
                    </p>
                  </InputGroup>
            </Container>

            <div className="ag-theme-balham" style={divStyle}>
              <AgGridReact
                rowSelection="single"
                enableRangeSelection={true}
                pagination={true}
                columnDefs={columnDefs}
                rowData={gridData}
                animateRows={true}
                onGridReady={this.onGridReady}
                onSelectionChanged={this.onSelectionChanged.bind(this)}
              />
            </div>

                  <InputGroup size="sm">
                    <p data-tip="save current grid layout">
                      <Button
                        size="sm"                      
                        variant="outline-info"
                        onClick={this.onBtnSaveLayout}
                      >
                        <FontAwesomeIcon icon={faSave} />
                        &nbsp;save
                      </Button>
                    </p>
                    <p data-tip="restore your saved grid layout">
                      <Button
                        size="sm"                      
                        variant="outline-info"
                        onClick={this.onBtnRestoreLayout}
                      >
                        <FontAwesomeIcon icon={faTrashRestore} />
                        &nbsp;restore
                      </Button>
                    </p>
                    <p data-tip="restore default grid layout">
                      <Button
                        size="sm"                      
                        variant="outline-info"
                        onClick={this.onBtnResetLayout}
                      >
                        <FontAwesomeIcon icon={faBookmark} />
                        &nbsp;default
                      </Button>
                    </p>

                  </InputGroup>
          </div>
        </Card.Body>
      </Card>
      </span>
    );
  }
}
