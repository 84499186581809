import {toCardId} from "../../schemaCards/AttribCardUtils.js";


export default class PassFailGenericScorer {


  info() {
    return {
      id: "passfail-generic",
      version: "1.01.01",
      lastUpdated: "2020-07-17T21:00:00-07:00",
      updatedBy: "cs",
      description: "Base Scoring Algorithm for Simple Pass/Fail Checklist Inspections",
    };
  }

  validate(schema, values, errors) {
    return true;
  }

  initScoreResult(schema){
    return {
      Score: {
        Scorer: this.info(),
        Schema: schema.schemaInfo,
        ScoreReason: "INSPECTION",
        PassedInspection: true,
        FailedConditions: [],
        Grade: {}
      }
    }
  }


  score(schema, values) {
    var result = this.initScoreResult(schema);
    console.log("result: ");
    console.log(result);

    for (var i = 0; i < schema.categories.length; i++) {
        if (schema.categories[i].id !== "inspection") continue;
        for (var j = 0; j < schema.categories[i].groups.length; j++) {
          var group = schema.categories[i].groups[j];
          for (var k=0; k<group.cards.length; k++){
              var card = group.cards[k];
              if (card.fieldType !== "checklistbox") continue;
                if (!values[toCardId(card.title)]){
                    result.Score.FailedConditions.push(card.title);
                }
            }
        }
    }
    result.Score.PassedInspection = (result.Score.FailedConditions.length<1);
    result.Score.Grade.Raw = (result.Score.PassedInspection) ? "PASS" : "FAIL";
    result.Score.Grade.Weighted = (result.Score.PassedInspection) ? "PASS" : "FAIL";
    return result;
  }




}
