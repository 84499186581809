import moment from "moment";
import AppConfig from "../AppConfig/AppConfig.js";
import PdfObservation from "../lib/Pdf/PdfObservation.js"


function getProp(obj, prop) {
    if (typeof obj !== 'object') throw 'getProp: obj is not an object'
    if (typeof prop !== 'string') throw 'getProp: prop is not a string'

    // Replace [] notation with dot notation
    prop = prop.replace(/\[["'`](.*)["'`]\]/g,".$1")

    return prop.split('.').reduce(function(prev, curr) {
        return prev ? prev[curr] : undefined
    }, obj || this.self)
}

export default class NotificationManager{
        constructor(){
            this.appConfig = new AppConfig();
        }


        getDirectNotificationResult(fromUser, toList, directMessage, observation){
            console.log("Observation for Notifications:");
            console.log(observation);
            var result = {
                    "id": "", //observation.data.identifiers.id, // TBD should be GUID unique to notification event
                    "gcid": observation.data.Raw.gcid,
                    "sourceService": "glasschain-qa-app",
                    "sourceId": observation.identifiers.id,
                    "sourceDescript": "QA Observations",
                    "sourceMessage": "",
                    "requestedBy": fromUser, //observation.data.UnderInspection.Inspector,
                    "requestDate":  moment().format(),
                    "toList": toList
            };  

            // template data. If it changes, then here is what we would change, perhaps using props replacements
            //var eventAttachment = this.getAttachmentsLinks(observation, rule.message.attachments, "eventLink");
            //var imageAttachmentElements = this.getAttachmentsLinks(observation, rule.message.attachments, "images");
           // var imageAttachments = imageAttachmentElements.map(imgAttach => {
           //     return {tags: imgAttach.tags, description: imgAttach.description, image_url: imgAttach.link};
           // })

           var pdfObs = new PdfObservation(observation);
            var eventAttachment = {description: "Observation Sheet", link: pdfObs.getLinkFromObservation()};
            //var eventAttachment = {description: "Observation Sheet", link: this.appConfig.getObsImageHostAddr(observation.data.Raw.gcid, "user") + observation.identifiers.id + ".pdf"};

            var imageAttachments = [];

            if (observation.data.Raw.userimages){
                for (let j=0; j<observation.data.Raw.userimages.length; j++){
                    var img = observation.data.Raw.userimages[j];
                    var imgDescript = (img.descript) ? img.descript : "User-Generated Image";
                    var imgTags = "";
                    if (img.tags){
                        var imgTagArray = img.tags.map((tag) =>{
                            if (tag){
                                return tag.value;
                            }
                        });
                        imgTags = imgTagArray.join(' '); 
                    }
                    var descript = imgDescript;
                    var link =  this.appConfig.getObsImageHostAddr(observation.data.Raw.gcid, "user") + 
                      img.id + ".png";
                    imageAttachments.push({tags: imgTags, description: descript, image_url: link}); 
                }
              }
              if (imageAttachments.length<1){
                  imageAttachments.push({tags: "", description: "No Associated Images.", image_url: ""});
              }
            
            console.log("about to build templateData from observation object:");
            console.log(observation);
            var templateData = {
                "inspector": observation.data.UnderInspection.Inspector,
                "sharedBy": fromUser,
                "gcid_logo_url": this.appConfig.getLogoImageBase() + "/gcid/" + observation.data.Raw.gcid + "/logos/" +
                observation.data.Raw.gcid + "-logo.png",
                //"gcid_logo_url": "https://gcblobstoredurable1.blob.core.windows.net/gcid/cg/logos/cg-logo.png",
                "directComment": (directMessage) ? directMessage: "No Message",
                "comment": (observation.data.Environment.Comments) ? observation.data.Environment.Comments: "No Observer Comment.",
                "eventLink": (eventAttachment) ? eventAttachment.link : "No Event Link.",
                "images": (imageAttachments) ? imageAttachments : [{tags: "", description: "No Associated Images.", image_url: ""}]
            };

            result.Message = {
                "templateId": "d-2e4553f161e0408fafecab1d6d5bb750",
                "fromPrefix": "observations",
                "templateData": JSON.stringify(templateData)
            };
    
            console.groupEnd();
            return result;
    
        }
    






    getNotificationResult(schema, observation, observationId){
        console.log("Observation for Notifications:");
        console.log(observation);

        if (!schema.notifications) return null;
        if (!schema.notifications.rules) return null;
        // do any of the rules apply? 
        var result = {
                "id": "", //observation.data.identifiers.id, // TBD should be GUID unique to notification event
                "gcid": observation.data.Raw.gcid,
                "sourceService": "glasschain-qa-app",
                "sourceId": "", observationId,
                "sourceDescript": "QA Observations",
                "sourceMessage": "",
                "requestedBy": observation.data.UnderInspection.Inspector,
                "requestDate":  moment().format(),
                "rules": []
            /*
            "templateInfo": {
                "templateId": schema.notifications.templateId,
                "fromPrefix": "observations",
                "toAddresses": [], // built by the rules/notifications info
                "templateData": ""
            */
        };  

        console.group("Rules: ");
        console.log(schema.notifications.rules);
        schema.notifications.rules.forEach( (rule,index)=>{
            var ruleTriggered = true; 
            var ruleMessages = [];
            var ruleIdentifiers = [];
            rule.triggerConditions.forEach( (condition, condIndex) =>{
                //console.log(condition);
                var observationValue = getProp(observation, condition.attribute);
                //console.log("Observation Value: " + observationValue);
                var conditionPassed = (observationValue === condition.value);
                var ruleMessage = condition.attribute + " = " + condition.value + " (" + conditionPassed + ").";
                //console.log(ruleMessage);
                ruleMessages.push(ruleMessage);
                if (!conditionPassed){
                    ruleTriggered = false;
                }
            });
            rule.notificationIdentifiers.forEach( (identifier, idIndex) => {
                //console.log("Attribute: " + identifier.attribute);
                var idGroupValue = getProp(observation, identifier.attribute); //observation[identifier.attribute];
                //console.log("idGroupValue: " + idGroupValue);
                if (!idGroupValue) { // whoops. The observation does not contain a value for the identifier. So looking it up for notifications would be useless. 
                    ruleTriggered = false;
                }
                else {
                    ruleIdentifiers.push({"groupId": identifier.groupId, "groupKey": identifier.groupId, "groupValue": idGroupValue});
                }
            });
            console.log("rule message: ");
            console.log(rule.message);

            
            
        //observation, rule.message.attachments
        // template data. If it changes, then here is what we would change, perhaps using props replacements
        var eventAttachment = this.getAttachmentsLinks(observation, rule.message.attachments, "eventLink");
        console.log("eventAttachment: ");
        console.log(eventAttachment);
        var imageAttachmentElements = this.getAttachmentsLinks(observation, rule.message.attachments, "images");
        console.log("imageAttachmentElements: ");
        console.log(imageAttachmentElements);
        if (imageAttachmentElements && imageAttachmentElements.length > 0){
            var imageAttachments = imageAttachmentElements.map(imgAttach => {
                return {tags: imgAttach.tags, description: imgAttach.description, image_url: imgAttach.link};
            })
        }
        
        var templateData = {
            "inspector": observation.data.UnderInspection.Inspector,
            "gcid_logo_url": this.appConfig.getLogoImageBase() + "/gcid/" + observation.data.Raw.gcid + "/logos/" +
                observation.data.Raw.gcid + "-logo.png",
			//"gcid_logo_url": "https://gcblobstoredurable1.blob.core.windows.net/gcid/cg/logos/cg-logo.png",
			"triggerType": "Reject",
            "comment": (observation.data.Environment.Comments) ? observation.data.Environment.Comments: "No Observer Comment.",
            "eventLink": (eventAttachment) ? eventAttachment.link : "No Event Link.",
            "images": (imageAttachments) ? imageAttachments : [{tags: "", description: "No Associated Images.", image_url: ""}]
        };

            var ruleResult = {
                    "ruleId": rule.id, 
                    "descript": rule.description,
                    "conditionResults": ruleMessages,
                    "triggered": ruleTriggered,
                    "message": {
                        "templateId": "d-1503c1d9fbc8474db097d81fd9d8d2d6",
                        "fromPrefix": "observations",
                        "templateData": JSON.stringify(templateData)
                    },
                    "identifiers": ruleIdentifiers
            };
            result.rules.push(ruleResult);
        });

        console.groupEnd();
        return result;

    }




    // returns an array of object {tag:"", description: "", link: ""}
    getAttachmentsLinks(observation, attachments, key){
        if (!attachments){
            console.log("no attachments!");
            return [];
        } 
        var attachSchema = attachments.find(attachment => attachment.key===key);
        if (!attachSchema){
            console.log("Couldnt' find attachment schema with key " + key);
            return [];
        }
        if (attachSchema.isArray){
            var arrAttrib = getProp(observation, attachSchema.attribute);
            console.log("observation prop: ");
            console.log(attachSchema.attribute);
            console.log(arrAttrib);
            if (!arrAttrib) return [];
            var result = [];
            arrAttrib.forEach(r => {
                //var id = r.id;
                console.log("r:");
                console.log(r);
                console.log("descript: " + r.descript);
                var myDescription = (r.descript) ? r.descript : "";
                var myTags = "";
                if (r.tags){
                    var rTagArray = r.tags.map((tag) =>{
                    if (tag){
                        return tag.value;
                    }
                  });
                  myTags = rTagArray.join(' ');
                  console.log("myTags: " + myTags);
                } 
                var myLink =  this.appConfig.getObsImageHostAddr(observation.data.Raw.gcid, "user") + 
                    r.id + attachSchema.fileSuffix; 
                console.log("image Link: " + myLink); 
                result.push({tags: myTags, description: myDescription, link: myLink});    
            });
            console.log("result length: " + result.length);
            if (result.length < 1){
                result.push({tags: "", description: "No Associated Images.", link: ""});
            }
            return result;
        }
        else {
            return {
                tags: "",
                description: "",
                link: this.appConfig.getObsImageHostAddr(observation.data.Raw.gcid, "user") + 
                            getProp(observation, attachSchema.attribute) + attachSchema.fileSuffix

            }
        }
    }






    addAttachmentsLinks(observation, attachments){
        console.log("ruleMessageNode Attachments from Schema: ");
        console.log(attachments);
        if (!attachments){
            console.log("no attachments!");
            return "";
        } 
        var result = "";
        attachments.forEach(attachment => {
            if (attachment.isArray){
                console.log("IsArray? " + attachment.isArray);
                var arrAttrib = getProp(observation, attachment.attribute);
                if (arrAttrib){
                    result = result + "\r\n\r\n";
                    var header = (attachment.title) ? attachment.title + ":\r\n": "";
                    arrAttrib.forEach(r => {
                        var id = r.id;
                        var description = (r.descript) ? r.descript : "";
                        if (r.tags){
                          var rTagArray = r.tags.map((tag) =>{
                            if (tag){
                                return tag.value;
                            }
                          });
                          description = description + "(" + rTagArray.join(' ') + ")";
                        }
                        result = result + this.appConfig.getObsImageHostAddr(observation.data.Raw.gcid, "user") + 
                            r.id + attachment.fileSuffix + "\r\n" + description + "\r\n\r\n";         
                    });
                }
            }
            else {
                var aheader = (attachment.title) ? attachment.title + "\r\n": "";
                var link = this.appConfig.getObsImageHostAddr(observation.data.Raw.gcid, "user") + 
                    getProp(observation, attachment.attribute) + attachment.fileSuffix;
                result = result + aheader + link + "\r\n";
            }
        });
        console.log("result: " + result);
        return result;
    }

    replaceTemplatesWithValues(schema, observation, srcString){
        //var str = 'I [have] strings that [are] like this.'

        //console.group("Template Replacement: ");
        //console.log(srcString);
        var re  = /\[([^\]]*)]/g, matches = [];
        let m = null;
        while(null != (m=re.exec(srcString))){
            matches.push(m[0])
        }
        //console.log(matches);

        var templateValues = matches.map(m =>{
            return {template: m, replacement: getProp(observation, m.replace('[','').replace(']',''))}
        });
        //console.log(templateValues);
        var result = srcString;
        templateValues.forEach(t => {
            result = result.replace(t.template, t.replacement);
        });
        //console.log(result);
        //console.groupEnd();

        return result;
    }
}


