import React, { Component } from "react";
import { FormControl, InputGroup, Card, Row, Col } from "react-bootstrap";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";

import AuthService from "../../AuthService/AuthService.js";
import ObsSearchParams from "../../../models/ObsSearchParams.js";

export default class ObservationsSearchCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
      searchParams: null,
      windowWidth: 0
    }

    this.auth = new AuthService();

    this.updateSearchParams = this.updateSearchParams.bind(this);
    this.onDatesChanged = this.onDatesChanged.bind(this);
    this.windowSize = this.windowSize.bind(this);
  }

  componentDidMount() {
    var newSearchParams = new ObsSearchParams();
    newSearchParams.gcid = this.auth.getGcid();
    newSearchParams.maxCount = 1000;
    var savedSearch = this.auth.getMainFilter();
    newSearchParams.schemaId = (savedSearch) ? savedSearch.schemaId : this.props.schemaId;
    newSearchParams.location = (savedSearch) ? savedSearch.location : this.auth.getLocation();
    newSearchParams.inspector = (savedSearch) ? savedSearch.inspector : this.auth.getUser();
    newSearchParams.ranchNbr = (savedSearch) ? savedSearch.ranchNbr : "";
    newSearchParams.startDate = (savedSearch) ? moment(savedSearch.startDate) : moment().subtract(3, "days").format();
    newSearchParams.endDate = (savedSearch) ? moment(savedSearch.endDate) : moment().add(1, "days").format();
    newSearchParams.obsDateStart = newSearchParams.startDate;
    newSearchParams.obsDateEnd = newSearchParams.endDate;
    var newGridQueryString = newSearchParams.asQueryString();
    this.props.onGridQueryStringChange(newGridQueryString);
    this.setState({
      searchParams: newSearchParams
    });
    this.auth.setMainFilter(newSearchParams);
  }


  componentDidUpdate(prevProps, prevState) {
    if (this.props.schemaId !== prevProps.schemaId) {

      var newSearchParams = this.state.searchParams;
      newSearchParams.schemaId = this.props.schemaId;
      var newGridQueryString = newSearchParams.asQueryString();

      this.props.onGridQueryStringChange(newGridQueryString);
      this.setState({ searchParams: newSearchParams })
    }
  }

  windowSize(){
    this.setState({windowWidth: window.innerWidth})
  }

  updateSearchParams(ev) {
    console.log("changing " + ev.target.name + " to " + ev.target.value);
    var newSearchParams = this.state.searchParams;
    newSearchParams[ev.target.name] = ev.target.value;
    var newGridQueryString = newSearchParams.asQueryString();
    this.props.onGridQueryStringChange(newGridQueryString);
    this.auth.setMainFilter(newSearchParams);
    this.setState({ searchParams: newSearchParams })
  }

  onDatesChanged(startDate, endDate) {
    var newSearchParams = this.state.searchParams;
    if ((startDate == null) || (startDate === "")) {
      return;
    }
    if ((endDate == null) || (endDate === "")) {
      return;
    }
    // it's also possible the the user has tried to enter it manually
    if (!moment(startDate, 'DD-MM-YY').isValid()) {
      return;
    }
    if (!moment(endDate, 'DD-MM-YY').isValid()) {
      return;
    }
    newSearchParams.startDate = startDate.format();
    newSearchParams.endDate = endDate.format();
    var newGridQueryString = newSearchParams.asQueryString();
    this.auth.setMainFilter(newSearchParams);
    this.props.onGridQueryStringChange(newGridQueryString);
    this.setState({ searchParams: newSearchParams });
  }



  render() {
    let searchParams = this.state.searchParams;
    if (!searchParams) {
      searchParams = new ObsSearchParams();
      searchParams.startDate = moment().subtract(3, "days").format();
      searchParams.endDate = moment().add(1, "days").format();
    }
    

    // note that this is here in case we want to put ranch search back in.
    /*
    const ranchSearch = (
      <Col xs={12} sm={6} md={3}>
      <InputGroup size="sm"  style={{padding: "2px"}}>
        <InputGroup.Prepend>
          <InputGroup.Text id="basic-addon1">Ranch</InputGroup.Text>
        </InputGroup.Prepend>
        <FormControl
          placeholder="ranch number"
          //value={searchParams.ranch}
          name="ranch"
          onBlur={ev=> this.updateSearchParams(ev)}
        />
      </InputGroup>
      </Col>
    );
    */


    return (
      <Card>
        <Card.Header>Filter</Card.Header>
        <Card.Body id="searchFilterCard">
          <Row style={{ margin: "0" }}>
            <Col xs={12} sm={6} md={3}>
              <InputGroup size="sm" style={{ padding: "2px" }}>
                <InputGroup.Prepend>
                  <InputGroup.Text id="basic-addon1">Location</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  as="select"
                  placeholder="location"
                  type="dropdown"
                  autoComplete="on"
                  value={searchParams.location}
                  name="location"
                  onChange={ev => this.updateSearchParams(ev)}
                >
                  {this.props.orgConfig.locations.map((loc) => (
                    <option>{loc.id}</option>
                  ))}
                </FormControl>
              </InputGroup>
            </Col>

            <Col xs={12} sm={6} md={3}>
              <InputGroup size="sm" style={{ padding: "2px" }}>
                <InputGroup.Prepend>
                  <InputGroup.Text id="basic-addon1">Inspector</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  as="select"
                  placeholder="inspector"
                  type="dropdown"
                  autoComplete="on"
                  value={searchParams.inspector}
                  name="inspector"
                  onChange={ev => this.updateSearchParams(ev)}
                >
                  {this.props.orgConfig.observers.map((inspector) => (
                    <option>{inspector.id}</option>
                  ))}
                </FormControl>
              </InputGroup>
            </Col>



            <Col >
              <InputGroup style={{ padding: "0" }}>
                <DateRangePicker
                  small
                  showClearDates={true}
                  isOutsideRange={() => false}
                  minimumNights={0}
                  minDate={moment().subtract(30, "days")}
                  startDate={moment(searchParams.startDate)} // momentPropTypes.momentObj or null,
                  startDateId="startDateUniqueId" // PropTypes.string.isRequired,
                  endDate={moment(searchParams.endDate)} // momentPropTypes.momentObj or null,
                  endDateId="endDateUniqueId" // PropTypes.string.isRequired,
                  onDatesChange={({ startDate, endDate }) => this.onDatesChanged(startDate, endDate)
                    //this.setState({ startDate, endDate })
                  } // PropTypes.func.isRequired,
                  focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                  onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                />
              </InputGroup>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }
}
