import React, { Component } from "react";
import { Button, Modal, Row,Col,InputGroup, FormControl, Form} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import CreatableSelect from "react-select/creatable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt, faSync} from "@fortawesome/free-solid-svg-icons";
import IDbDocService from "../DataService/IDbDocService.js";
import GlasschainHttpClient from "../ApiService/GlasschainHttpClient.js";
import Dictaphone from "../../components/lib/Dictaphone/GcDictaphone.jsx";
import AuthService from "../AuthService/AuthService.js";
import AddressBook from "../../models/AddressBook.js";
import NotificationManager from "../notifications/NotificationManager.js";
import PdfObservation from "../../components/lib/Pdf/PdfObservation.js";


//const components = {
  //DropdownIndicator: null,
//};





const createOption = (label) => ({
  label,
  value: label,
});


export default class ModalSendMsgDialog extends Component {
  constructor(props) {
    super(props);

    this.auth = new AuthService();
    this.gcid = this.auth.getGcid();
    this.userName = this.auth.getUser();
    this.localDbDocService = new IDbDocService();
    this.apiClient = new GlasschainHttpClient();

    this.state = {
        dataLoaded: false,
        listening: false,
        sending: false,
        sendToInputValue: "",
        sendToValue: [],
        msg: "",
        includePdfLink: true,
        addressBook: new AddressBook(this.gcid, this.userName),  // {id: xxxxx, email: xxx@xxx.xxx, phone: +1xxx-xxx-xxxx}
        errMsg: ""
    };

    this.onMsgChange = this.onMsgChange.bind(this);
    this.onTranscriptionChange = this.onTranscriptionChange.bind(this);
    this.onIncludePdfLinkChange = this.onIncludePdfLinkChange.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.cancelMessage = this.cancelMessage.bind(this);

  }

  componentDidMount(){
    if (this.auth.hasLocalAddressBook(this.gcid, this.userName)){
      var newAddressBook = this.auth.getLocalAddressBook(this.gcid, this.userName);
      this.setState({addressBook: newAddressBook});
    }    
  }

componentDidUpdate(prevProps, prevState){
  if (prevProps.currentRow != this.props.currentRow){
    this.setState({dataLoaded: true});
  }
}

  onMsgChange(ev){
    this.setState({msg: ev.target.value});
  }

  onTranscriptionChange(newTranscription){
    var newValue = (this.state.msg) ? this.state.msg + " " + newTranscription : newTranscription;
    this.setState({msg: newValue});
  }

  onAddObsCommentRequest(){
    var comment = (this.props.currentRow.data.Environment.Comments) ? this.props.currentRow.data.Environment.Comments : "";
    var newValue = (this.state.msg) ? this.state.msg + " " + comment : comment;
    this.setState({msg: newValue});
  }

  onIncludePdfLinkChange(){
    this.setState({includePdfLink: (!this.state.includePdfLink)});
  }

  addressBookIdExists(addressBook, testId){
    if (!addressBook.addresses) return false;
    for (let i=0; i<addressBook.addresses.length; i++){
      let addrBookId = addressBook.addresses[i].id;
      if (addrBookId.toLowerCase() === testId.value.toLowerCase()) return true;
    }
    return false;
  }

  cancelMessage()
  {
    this.setState(
    {
      sending: false,
      msg: "",
      errMsg: "",
      sendToInputValue: "",
      sendToValue: [], // clear   
      sendMsg: ""
    });
    this.props.onCloseRequest();
  }




  async sendMessage(){

    try
    {
      this.setState({
        sending: true,
        sendMsg: "Beginning Send..."
      }); // show that we are sending...
      var newAddressBook = this.state.addressBook;
      //console.group("New Address Book");
      //console.log(newAddressBook);
      //console.groupEnd();
      for (let i=0; i<this.state.sendToValue.length; i++){
        var testId = this.state.sendToValue[i];
        if (!this.addressBookIdExists(newAddressBook, testId)){
          newAddressBook.addresses.push({id: testId.value, email: testId.value, phone:""});
        }
      }
      var recipients = this.state.sendToValue.map((recipient) =>{
        return recipient.value
      });
      this.setState({sendMsg: "Saving Address Book..."});
      this.auth.saveLocalAddressBook(this.gcid, this.userName, newAddressBook);
      this.setState({
          addressBook: newAddressBook
      });
      this.setState({sendMsg: "Building PDF..."});
      var schemaId = this.props.currentRow.data.schema.id;
      var newSchemaData = this.auth.getLocalSchema(schemaId);
      var newFullSchema = JSON.parse(newSchemaData.fullSchema);
      var pdfObs = new PdfObservation(this.props.currentRow, newFullSchema);
      this.setState({sendMsg: "Writing PDF to Glasschain..."});
      await pdfObs.writeToCloudStorage();
      this.setState({sendMsg: "Sending Notifications..."});
      var notificationsResult = new NotificationManager().getDirectNotificationResult(this.userName, recipients, this.state.msg, this.props.currentRow);
      await this.apiClient.postDirectNotificationRequest(notificationsResult);
      console.log("posted email");
      this.setState({
        sending: false,
        msg: "",
        errMsg: "",
        sendToInputValue: "",
        sendToValue: [],
        sendMsg: "" // clear         
      })
      this.props.onToastRequest("Email sent successfully!", "success");
      this.props.onCloseRequest(); 
    }
    catch(e)
    {
      var errMsg = "There was a problem sending your message. (" + e.message + ")";
      this.setState({
        sending: false,
        errMsg: errMsg,
        sendMsg: ""
      });
      this.props.onToastRequest("Error sending email (" + e.message + ")", "error");
    }   
  }

  handleSendToChange = (value, actionMeta) => {
    //if (actionMeta.action==="remove-value"){
    //  let tagValue = actionMeta.removedValue.value;
    //}
    var newValue = (value) ? value: [];
    this.setState({ sendToValue: newValue });
  };

  handleSendToInputChange = (sendToInputValue) => {
    var newValue = (sendToInputValue) ? sendToInputValue: "";
    this.setState({ sendToInputValue: newValue });
  };

  handleSendToKeyDown = (event) => {
    const { sendToInputValue, sendToValue } = this.state;
    if (!sendToValue) return;
    if (!sendToInputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        //console.group("Value Added");
        //console.log(sendToValue);
        //console.groupEnd();
        this.setState({
          sendToInputValue: "",
          sendToValue: [...sendToValue, createOption(sendToInputValue)],
        });
        event.preventDefault();
    }
  };


  render() {

    const { sendToInputValue, sendToValue } = this.state;
    //console.group("Address book: ");
    //console.log(this.state.addressBook);
    const addressBookOptions = this.state.addressBook.addresses.map((addr) =>{return createOption(addr.email);});
    //console.log(addressBookOptions);
    //console.groupEnd();

    const sendingDiv = (this.state.sending) ?
      <div><FontAwesomeIcon icon={faSyncAlt} spin={true}/>&nbsp; {this.state.sendMsg}...</div>
      : "";


    return (
      <Modal show={this.props.show} onHide={this.props.onCloseRequest}>
        <Modal.Header closeButton>
          <Modal.Title>Share Observation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row><Col>Send To</Col></Row>
          <Row>
            <Col>
            <CreatableSelect
                  //components={components}
                  inputValue={sendToInputValue}
                  isClearable
                  isMulti
                  isDisabled={false}
                  options={addressBookOptions}
                  onChange={this.handleSendToChange}
                  onInputChange={this.handleSendToInputChange}
                  onKeyDown={this.handleSendToKeyDown}
                  placeholder="Enter the recipient's e-mail address here"
                  value={sendToValue}
                />
            </Col>
          </Row>
          <Row><Col>Message</Col></Row>
          <Row>
            <Col>
            <InputGroup size="sm" className="textcard-input">
            <textarea
              rows={4}
              cols={50}
              autoFocus
              //textAlign="center"
              placeholder="Enter your message here..."
              aria-describedby="basic-addon2"
              name="msgComment"
              value={this.state.msg}
              onChange={this.onMsgChange}
            />

            <Dictaphone
              onTranscriptionChange={this.onTranscriptionChange}
            />

            </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col>
            <Button variant="light" size='sm' onClick={this.onAddObsCommentRequest.bind(this)}>Add Observation Comment</Button>  
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Check
                type="checkbox"
                label="Include Inspection Sheet PDF link"
                checked={this.state.includePdfLink}
                onChange={this.onIncludePdfLinkChange}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" disabled={!this.state.dataLoaded} onClick={this.sendMessage}>
            Send
          </Button>
          <Button variant="secondary" onClick={this.cancelMessage}>
            Cancel
          </Button>
          {(this.state.errMsg.trim() === "") ? <span/> : <span style={{color: "red", fontSize: "8pt"}}>&nbsp;&nbsp;{this.state.errMsg}</span>}
          <div>
            {sendingDiv}
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}
