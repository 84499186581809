import React, { Component } from "react";
import {Row, Col } from "react-bootstrap";


import GlasschainHttpClient from "../components/ApiService/GlasschainHttpClient.js";
import AuthService from "../components/AuthService/AuthService.js";

import "../assets/css/V2-history-layout.css";

import HistoryPanel from "../components/local/panels/HistoryPanel.jsx";
import SchemaPanel from "../components/local/panels/SchemaPanel.jsx";

const ColoredLine = ({ color }) => (
  <hr
    style={{
      color: color,
      backgroundColor: color,
      height: 1
    }}
  />
);

export default class HomePage extends Component {
  constructor(props) {
    super(props);

    this.auth = new AuthService();
    this.httpClient = new GlasschainHttpClient();
    this.gcid = this.auth.getGcid();

    this.state = {
      renderChildren: false,
      orgConfig: null,
      schemaId: null
    }
    this.onSchemaChange = this.onSchemaChange.bind(this);
    this.onRequestNewObservation = this.onRequestNewObservation.bind(this);

  }


  async componentDidMount(){
    // if user is connected, but not logged in then 
    if (this.props.isConnected){
      if (!this.auth.loggedIn()){
        this.props.onSystemLogoutRequest();
      }
    }
    var newOrgConfig = this.auth.getLocalOrgConfig(this.gcid);
    newOrgConfig.data.locations.unshift({ id: "ALL" });
    newOrgConfig.data.observers.unshift({ id: "ALL" });
    newOrgConfig.data.voidReasons.unshift("Choose a Reason for Void")
    var savedSearch = this.auth.getMainFilter();
    console.group("Assigning initial SchemaId");
    console.log("OrgConfig:");
    console.log(newOrgConfig);
    console.log("Schema List:");
    console.log(newOrgConfig.data.schemaList);
    var newSchemaId = (savedSearch) ? 
      (savedSearch.schemaId) ? savedSearch.schemaId : newOrgConfig.data.schemaList[0]
      : newOrgConfig.data.schemaList[0];
    
    this.setState({
      orgConfig: newOrgConfig.data,
      schemaId: newSchemaId
    });
  }

  componentDidUpdate(prevProps, prevState) {
    // this will NOT render child components until we are sure our orgConfig has been loaded into state. See render, below.
    if (this.state.orgConfig !== prevState.orgConfig) {
      this.setState({ renderChildren: true });
    }
  }

  onSchemaChange(newSchemaId){
    let newMainFilter = this.auth.getMainFilter();

    if (newMainFilter) {
      newMainFilter.schemaId = newSchemaId;
      this.auth.setMainFilter(newMainFilter);
    }
    this.setState({schemaId: newSchemaId});
  }

  onRequestNewObservation(){
    this.props.onRequestNewObservation(this.state.schemaId);
  }




  render() {
    return this.state.renderChildren ? (
      <div  style={{ padding: "5px" }}>



          <Row>
            <SchemaPanel
              schemaId={this.state.schemaId}
              orgConfig={this.state.orgConfig}
              onSchemaChange={this.onSchemaChange}
              onRequestNewObservation={this.onRequestNewObservation}
              onRequestRecallSuspendedObservation={this.props.onRequestRecallSuspendedObservation}
            />
          </Row>
          <Row>
          <Col xs={12}>
            <ColoredLine color="blue" />
            <HistoryPanel 
              isConnected={this.props.isConnected}
              onSystemLogoutRequest={this.props.onSystemLogoutRequest}
              orgConfig={this.state.orgConfig}
              schemaId={this.state.schemaId}
              onRequestUpdateObservation={this.props.onRequestUpdateObservation}
              onViewUserImagesRequest = {this.props.onViewUserImagesRequest}
              onToastRequest = {this.props.onToastRequest}
            />
          </Col>
        </Row>
      </div>
    ) : (
      <div>"Loading Schema and History Panel..."</div>
    );
  }
}
