import React, { Component } from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../assets/css/attrib-cards.css";
import { faRandom, faCamera } from "@fortawesome/free-solid-svg-icons";
import PtiParser from "../Barcodes/PtiParser.js";
import { toCardId } from "./AttribCardUtils.js";

export default class AttribTextParseCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardState: "untouched",
      cardValue: null,
    };
    this.Id = toCardId(this.props.attribTitle);

    this.renderState = this.renderState.bind(this);
    this.renderUntouchedState = this.renderUntouchedState.bind(this);
    this.renderActiveState = this.renderActiveState.bind(this);
    this.onCardClick = this.onCardClick.bind(this);
    this.onLoseFocus = this.onLoseFocus.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.mapParsedFields = this.mapParsedFields.bind(this);
    this.mapFixedFields = this.mapFixedFields.bind(this);

    this.parseType = this.props.parseType;
    this.fieldMap = this.props.fieldMap;
  }

  componentDidUpdate(prevProps, prevState){
    let oldVal = prevState.cardValue;
    let newVal = this.props.valueBag[this.Id];
    //console.log("Id: " + this.Id + " Old: " + oldVal + " New: " + newVal);
    if (oldVal !== newVal){
      this.setState({
        cardValue: newVal
      });
      if (this.state.cardState !== "active"){
        this.setState({cardState: (newVal) ? "touched" : "untouched"})
      }
    }
  }

  renderCameraIcon =() =>{
    return <FontAwesomeIcon icon={faCamera} color="yellow" size="2x" style={{float: "left", paddingLeft: "5px"}} onClick={(ev) => this.onCameraClick(ev)}/> 
  }

  onCameraClick(ev){
    ev.preventDefault();
    ev.stopPropagation();
    this.props.onRequestCameraDialogOpen(this.Id);
  }

  isRepressedOverwrite(key, valueBag, repressedOverwrites)
  {
    if (!repressedOverwrites) return false;
    var hasKey = repressedOverwrites.includes(key);
    if (!hasKey) return false;
    var keyIsInValueBag = (key in valueBag);
    if (!keyIsInValueBag) return false;
    if ((valueBag[key].trim() === "") || (valueBag[key] == null)) return false;
    return true;
  }

  mapParsedFields(parsedObject) {
    for (var key in parsedObject) {
      var val = parsedObject[key];
      if (val && val !== "") {
        if (this.props.fieldMap[key]) {
          var mappedField = this.props.fieldMap[key];
          if (!this.isRepressedOverwrite(mappedField, this.props.valueBag, this.props.repressedOverwrites))
          {
            this.props.onValueChange(mappedField, val);
          }
        }
      }
    }
  }

  mapFixedFields(lotValue, lotParser) {
    for (var i = 0; i < lotParser.length; i++) {
      var val = "";
      console.log("lot parser direction " + lotParser[i].direction);
      if (lotParser[i].direction === "reverse") {
        var stIdx = -1 * lotParser[i].startIndex;
        var edIdx = -1 * lotParser[i].endIndex;
        console.log("lotValue: " + lotValue + "(" + stIdx + "/" + edIdx + ")");
        val = (lotParser[i].startIndex === 0) ? 
          lotValue.slice(edIdx) :
          lotValue.slice(stIdx, edIdx);
      } else {
        if (lotParser[i].endIndex) {
          val = lotValue.slice(lotParser[i].startIndex, lotParser[i].endIndex);
        } else {
          val = lotValue.slice(lotParser[i].startIndex);
        }
      }
      this.props.onValueChange(lotParser[i].mapTo, val);
    }
  }

  onValueChange(ev) {
    //console.log(ev);
    //var newValue = ev.target.value;
    var parsedObject = {};
    if (this.props.parserType === "pti") {
      parsedObject = PtiParser.parseV2(ev.target.value);
      // are any fields populated yet?
      if (
        parsedObject.gtin !== "" ||
        parsedObject.harvestDate !== "" ||
        parsedObject.lot
      ) {
        this.mapParsedFields(parsedObject);
        // we may have to further parse the "lot" element
        if (this.props.lotParser && parsedObject.lot) {
          this.mapFixedFields(parsedObject.lot, this.props.lotParser);
        }
      } else {
        // tbd - custom parser
      }
    }
    this.setState({ cardValue: ev.target.value });
    this.props.onValueChange(this.Id, ev.target.value);
  }

  onLoseFocus() {
    var newCardState = this.state.cardValue ? "touched" : "untouched";
    this.setState({ cardState: newCardState });
  }

  onCardClick(ev) {
    var newState = "untouched";
    if (this.state.cardState === "active") {
      newState = this.state.cardValue ? "touched" : "untouched";
    } else {
      newState = "active";
    }
    this.setState({ cardState: newState });
    this.props.onToggleCard(this);
  }

  renderUntouchedState() {
    return (
      <div
        className="nonactivecard card-template shadow6"
        onClick={this.onCardClick}
      >
        {this.props.attribTitle}
      </div>
    );
  }

  renderActiveState() {
    return (
      <div className="activecard shadow6 card-template textcard-input">
        <div onClick={this.onCardClick}>
          {this.props.attribTitle.toLowerCase()}
          {this.renderCameraIcon()}
        </div>
        <InputGroup size="sm" style={{ padding: "2px" }}>
          <FormControl
            autoFocus
            plainText
            textAlign="center"
            placeholder={this.props.attribTitle}
            aria-describedby="basic-addon2"
            name={this.Id}
            value={this.state.cardValue}
            onChange={this.onValueChange}
            style={{ marginLeft: "20px" }}
          />
        </InputGroup>
      </div>
    );
  }

  renderTouchedState() {
    return (
      <div
        className="nonactivecard card-template touchedcard shadow7"
        onClick={this.onCardClick}
        style={{ backgroundColor: "lightgreen" }}
      >
        <div style={{ fontSize: "20px" }}>
          {this.props.attribTitle.toUpperCase()}
        </div>
        <div
          style={{
            textAlign: "center",
            fontSize: "20px",
            fontColor: "purple",
          }}
        >
          {this.state.cardValue}
        </div>
      </div>
    );
  }

  renderState() {
    switch (this.state.cardState) {
      case "untouched":
        return this.renderUntouchedState();
      case "touched":
        return this.renderTouchedState();
      default:
        return this.renderActiveState();
    }
  }

  render() {
    return this.renderState();
  }
}
